import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, Prompt } from 'react-router-dom';
import { Form, Input, Container, Select } from 'semantic-ui-react';
import useListUsers from '../../../../Hooks/Users/useListUsers';
import useCreateLocation from '../../../../Hooks/Locations/useCreateLocation';
import { AuthContext } from './../../../../Context/AuthContext';
import Forbidden from './../../../Pages/Forbidden';
import { SubmitButton, CancelButton } from '../../../Reusable/Buttons';
import { Loading, PageTitle } from '../../../Reusable/Elements';

const CreateLocation = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const { users, listUsers } = useListUsers();
    const { createLocation } = useCreateLocation();
    const { userProfile } = useContext(AuthContext);

    const [ name, setName ] = useState("");
    const [ description, setDescription ] = useState("");
    const [ personInCharge, setPersonInCharge ] = useState(0);

    const [ isLoading, setIsLoading ] = useState(true);
    const [ formIsFilledOutAndSaved, setFormIsFilledOutAndSaved ] = useState(false);

    const onSubmit = async () => {
        try {

            setIsLoading(true);

            const locations= {
                "name": name,
                "description": description,
                "locationUsers": [personInCharge] || []
            }

            const result = await createLocation(locations);

            if (result.status === 200 || result.status === 201) {
                setFormIsFilledOutAndSaved(true);
                return history.goBack()
            };

            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            return error;
        }
    }

    // Fetch users
    useEffect(() => {
        (async () => {
            await listUsers();
            setIsLoading(false);         
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Set values
    useEffect(() => {
        // Select first array item as default...
        if (users && users.length === 1) setPersonInCharge(users[0].id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users]);

    if (!userProfile || !userProfile.isAdminUser) return <Forbidden />;

    return (
        <Form className="locations" onSubmit={onSubmit} >
            
            <Prompt when={!formIsFilledOutAndSaved} message={t('warnings.leaveForm')} />

            <Loading active={isLoading} text={t('general.loading')} />

            {/* Title */}
            <PageTitle title={t('locations.new')} hideInfo/>

            {/* Name */}
            <Form.Field
                required
                maxLength="255"
                control={Input}
                label={t('general.name')}
                value={name}
                onChange={(ev, {value}) => setName(value) }
                type="Text"
            />

            {/* Description */}
            <Form.Field
                maxLength="255"
                control={Input}
                label={t('general.description')}
                value={description}
                onChange={(ev, {value}) => setDescription(value) }
                type="Text"
            />

            {/* Person In Charge */}
            <Form.Field
                required
                label={t('locations.personInCharge')}
                control={Select}
                value={personInCharge}
                options={users.map(item => {
                    return {
                        key: item.id,
                        text: item.firstName + " " + item.lastName,
                        value: item.id
                    }
                })}
                onChange={(ev, {value}) => setPersonInCharge(value) }
            />

            {/* Buttons */}
            <Container textAlign="right">
                <SubmitButton icon="save" color="green" title={t('general.save')} />
                <CancelButton />
            </Container>

        </Form>
    );

}

export default CreateLocation;
