import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from './../../Context/AuthContext';
import useRequestHandler from './../useRequestHandler';

const useChangeProfilePassword = () => {

    const { t } = useTranslation();
    const { acquireTokenSilently, apiClient } = useContext(AuthContext);
    const { errorHandler, successHandler } = useRequestHandler();
    const [ newPasswordMustBeDifferent, setNewPasswordMustBeDifferent ] = useState(false);

    /** Change user profile password 
     * @param {object} password - object discribing password
     * @example
     * {
     *     "currentPassword": "123",
     *     "newPassword": "456"
     * }
    */
    const changeProfilePassword = async (password) => {
        try {

            const accessToken = await acquireTokenSilently();

            if (accessToken === "login_required") throw Error("login_required");

            const result = await apiClient.create(accessToken, "profile/changepassword", password);

            return successHandler(result, "useChangeProfilePassword", "update", { message: t('success.passwordChangedSuccesfully') });

        } catch (error) {

            const status = error.status || null;
            const data = error.data || {};
            const errorCode = data.errorCode || 0;
            const options = {};

            if (status === 400 && (errorCode === -5 || errorCode === 5)) {
                options.message = t('errors.invalidCurrentPassword.passwordMustBeDifferentFromTheCurrentPassword');
                setNewPasswordMustBeDifferent(true);
            }

            if (status === 403) options.message = t('errors.invalidCurrentPassword');

            return errorHandler(error, "useChangeProfilePassword", "update", options);

        }
    }

    return { newPasswordMustBeDifferent, setNewPasswordMustBeDifferent, changeProfilePassword };

}

export default useChangeProfilePassword;
