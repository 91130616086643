import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../Context/AuthContext';
import useRequestHandler from './../useRequestHandler';

const useUpdateLocation = () => {

    const { t } = useTranslation();
    const { acquireTokenSilently, apiClient } = useContext(AuthContext);
    const { errorHandler, successHandler } = useRequestHandler();
    const [ locationDoesNotExist, setLocationDoesNotExist ] = useState(false);

    /** Update location
     * @param {object} location - object discribing the location
     * @example
     * {
     *    "id": 1,
     *    "name": "",
     *    "description": "",
     *    "personInChargeLocationId": 2
     * }
    */
    const updateLocation = async (location) => {
        try {

            const accessToken = await acquireTokenSilently();
            
            if (accessToken === "login_required") throw Error("login_required");
            
            const result = await apiClient.update(accessToken, "locations", location);

            return successHandler(result, "useUpdateLocation", "update", { message: t('success.locationUpdatedSuccesfully') });

        } catch (error) {

            const status = error.status || null;
            const data = error.data || {};
            const errorCode = data.errorCode || 0;

            if (status === 400 && errorCode === -1) setLocationDoesNotExist(true);

            const options = {
                "hide": "accessToken" && "accessToken" === "login_required" ? true: false,
                "customLog": { "accessToken": "accessToken" }
            }

            return errorHandler(error, "useUpdateLocation", "update", options);

        }
    }

    return { locationDoesNotExist, setLocationDoesNotExist, updateLocation };

}

export default useUpdateLocation;
