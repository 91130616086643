import axios from 'axios';
import config from '../Config/config';

/**  API client to make API calls which uses axios
 * @example
 *  import webApiClient from './../Utils/webApiClient';
 *  const apiClient = webApiClient(authentication.getToken); // Set token fetch function
 *  apiClient.list("accessToken", "/api/v1/users");
 *  apiClient.create("accessToken", "/api/v1/users", { "name": "John" });
 *  apiClient.read("accessToken", "/api/v1/users/", 1);
 *  apiClient.update("accessToken", "/api/v1/users", { "id": 1, "name": "John2" });
 *  apiClient.delete("accessToken", "/api/v1/users/", 1); */
 const webApiClient = () => {

    /** List items
     * @param {string} accessToken - Access token is added to Authentication header if defined
     * @param {string} resource - api endpoint name f.e. /api/v1/users
     * @returns {array} items - array of items
    */
    const listItem = async (accessToken, resource) => {
        try {

            if (!resource || typeof resource !== "string") throw Error("Resource is invalid: " + resource);

            const options = { "headers": { "Content-Type": "application/json" } }
            if (accessToken) options.headers.Authorization = "Bearer " + accessToken;

            const apiVersion = config.webApi.apiVersion;
            const root = config.webApi.rootPath;
            const url = new URL(apiVersion + resource, root);

            return  await axios.get(url, options);

        } catch (error) {
            // Throw error response so that errorHandler can show messages correclty
            if (error && error.response) throw error.response;
            // throw error if error is not from the api call
            throw error; 
        }
    }

    /** Create item
     * @param {string} accessToken - Access token is added to Authentication header if defined
     * @param {string} resource - api endpoint name f.e. /api/v1/users
     * @param {object} body - object which describes the item
    */
    const createItem = async (accessToken, resource, body) => {
        try {

            if (!resource || typeof resource !== "string") throw Error("Resource is invalid: " + resource);
            if (!body || typeof body !== "object") throw Error("Body is invalid: " + body);

            const options = { "headers": { "Content-Type": "application/json" } }
            if (accessToken) options.headers.Authorization = "Bearer " + accessToken;
            
            const apiVersion = config.webApi.apiVersion;
            const root = config.webApi.rootPath;
            const url = new URL(apiVersion + resource, root);

            return await axios.post(url, body, options);

        } catch (error) {
            // Throw error response so that errorHandler can show messages correclty
            if (error && error.response) throw error.response;
            // throw error if error is not from the api call
            throw error; 
        }
    }

    /** Read item
     * @param {string} accessToken - Access token is added to Authentication header if defined
     * @param {string} resource - api endpoint name f.e. /api/v1/users
     * @param {integer} id - item id
     * @returns {object} item - item object
    */
    const readItem = async (accessToken, resource, id) => {
        try {

            if (!resource || typeof resource !== "string") throw Error("Resource is invalid: " + resource);

            const options = { "headers": { "Content-Type": "application/json" } }
            if (accessToken) options.headers.Authorization = "Bearer " + accessToken;

            const apiVersion = config.webApi.apiVersion;
            const root = config.webApi.rootPath;
            const param = id ? "/" + id : "";
            const url = new URL(apiVersion + resource + param, root);
            
            return await axios.get(url, options);

        } catch (error) {
            // Throw error response so that errorHandler can show messages correclty
            if (error && error.response) throw error.response;
            // throw error if error is not from the api call
            throw error; 
        }
    }

    /** Update item
     * @param {string} accessToken - Access token is added to Authentication header if defined
     * @param {string} resource - api endpoint name f.e. /api/v1/users
     * @param {object} body - object which describes the item
    */
    const updateItem = async (accessToken, resource, body) => {
        try {

            if (!resource || typeof resource !== "string") throw Error("Resource is invalid: " + resource);
            if (!body || typeof body !== "object") throw Error("Body is invalid: " + body.toString());

            const options = { "headers": { "Content-Type": "application/json" } }
            if (accessToken) options.headers.Authorization = "Bearer " + accessToken;
            
            const apiVersion = config.webApi.apiVersion;
            const root = config.webApi.rootPath;
            const url = new URL(apiVersion + resource, root);

            return await axios.put(url, body, options);

        } catch (error) {
            // Throw error response so that errorHandler can show messages correclty
            if (error && error.response) throw error.response;
            // throw error if error is not from the api call
            throw error; 
        }
    }

    /** Delete item
     * @param {string} accessToken - Access token is added to Authentication header if defined
     * @param {string} resource - api endpoint name f.e. /api/v1/users
     * @param {integer} id - item id
    */
    const deleteItem = async (accessToken, resource, id) => {
        try {

            if (!resource || typeof resource !== "string") throw Error("Resource is invalid: " + resource);
            if (!id || typeof id !== "number") throw Error("Id is invalid: " + id);

            const options = { "headers": { "Content-Type": "application/json" } }
            if (accessToken) options.headers.Authorization = "Bearer " + accessToken;

            const apiVersion = config.webApi.apiVersion;
            const root = config.webApi.rootPath;
            const param = id ? "/" + id : "";
            const url = new URL(apiVersion + resource + param, root);

            return await axios.delete(url, options);

        } catch (error) {
            // Throw error response so that errorHandler can show messages correclty
            if (error && error.response) throw error.response;
            // throw error if error is not from the api call
            throw error; 
        }
    }

    // Return Public properties and methods
    return {
        list: listItem,
        create: createItem,
        read: readItem,
        update: updateItem,
        delete: deleteItem
    }

}
    
export default webApiClient;