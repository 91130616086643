import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../Context/AuthContext';
import useRequestHandler from './../useRequestHandler';

const useUpdateProfile = () => {

    const { t } = useTranslation();
    const { acquireTokenSilently, apiClient } = useContext(AuthContext);
    const { errorHandler, successHandler } = useRequestHandler();
    
    /** Update user profile
     * @param {object} profile - object discribing the profile
     * @example
     * {
     *     "firstName": "",
     *     "lastName": "",
     *     "emailAddress": "",
     *     "language": ""
     * }
    */
    const updateProfile = async (profile) => {
        try {

            const accessToken = await acquireTokenSilently();

            if (accessToken === "login_required") throw Error("login_required");

            const result = await apiClient.update(accessToken, "profile", profile);

            return successHandler(result, "useUpdateProfile", "update", { message: t('success.profileUpdatedSuccesfully') });

        } catch (error) {
            return errorHandler(error, "useUpdateProfile", "update");
        }
    }

    return { updateProfile };

}

export default useUpdateProfile;
