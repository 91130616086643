import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './Components/App/App';
import { Loading } from './Components/Layout/Layout';
//import reportWebVitals from './Components/App/reportWebVitals';
import './i18n'; // import i18n (needs to be bundled ;))
//import moment from 'moment';
import 'moment/locale/fi';

// <React.StrictMode></React.StrictMode>

ReactDOM.render(
  <Suspense fallback={(<Loading />)}>
      <App />
  </Suspense>, document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
