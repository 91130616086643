
function capitalizeFirstLetter (string) {
    if (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return "";
}

function sortByProperty (array, param, asc = true) {
    if (!Array.isArray(array)) return;
    return array?.slice().sort((a, b) => {
                                if(!a[param]) a[param] = ''
                                if(!b[param]) b[param] = '' 
                                return asc ? (a[param] > b[param]) ? 1 : ((b[param] > a[param]) ? -1 : 0) :
                                             (a[param] > b[param]) ? -1 : ((b[param] > a[param]) ? 1 : 0)
                                });
}

function sortByPropertyAsInt (array, param, asc = true) {
    if (!Array.isArray(array)) return;
    const numbers = array
                        .slice()
                        .filter(item => !isNaN(parseInt(item[param])))
                        .sort((a, b) => {
                            if(!a[param]) a[param] = ''
                            if(!b[param]) b[param] = '' 
                            return asc ? (parseInt(a[param]) > parseInt(b[param])) ? 1 : ((parseInt(b[param]) > parseInt(a[param])) ? -1 : 0) :
                                         (parseInt(a[param]) > parseInt(b[param])) ? -1 : (parseInt((b[param]) > parseInt(a[param])) ? 1 : 0)
                            });
                        
 
    const strings = sortByProperty(array.filter(item => isNaN(parseInt(item[param]))), param, asc)
 
    return asc ? numbers.concat(strings) : strings.concat(numbers);
}

function sortByDate (array, param, asc = true) {
    if (!Array.isArray(array)) return;
    return array?.slice().sort((a, b) => asc ? new Date(a[param]) - new Date(b[param]) : new Date(b[param]) - new Date(a[param]))
}

function groupArrayBy (array, property) {
    if (!Array.isArray(array)) return;
    const param = String(property);
    
    const grouped = array.reduce((memo, item) => {
      if (!memo[item[param]]) {
           memo[item[param]] = []; 
      }
      memo[item[param]].push(item);
      return memo;
    }, []);
    return grouped;
}

function groupArrayByObject (array, property) {
    if (!Array.isArray(array)) return;
    const param = String(property);
    
    const grouped = array.reduce((memo, item) => {
      if (!memo[item[param]]) {
           memo[item[param]] = []; 
      }
      memo[item[param]].push(item);
      return memo;
    }, {});
    return grouped;
}

function groupBy(array, property) {
    return array.reduce(function (r, a) {
        r[a[property]] = r[a[property]] || [];
        r[a[property]].push(a);
        return r;
    }, {})
  };

function convertImageToBase64 (img) {
    try {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        return canvas.toDataURL("image/png");
    } catch (error) {
        return error;
    }
}

/** Convert image buffer data to JPEG image
 * @see: https://gist.github.com/candycode/f18ae1767b2b0aba568e */
async function bufferDataToJpeg (buf) {
    try {
        // Obtain a blob: URL for the image data.
        const arrayBufferView = new Uint8Array( buf );
        const blob = new Blob( [ arrayBufferView ], { type: "image/jpeg" } );
        const urlCreator = window.URL || window.webkitURL;
        const imageUrl = urlCreator.createObjectURL( blob );
        return imageUrl;
    } catch (error) {
        return error;
    }

}

function parseZonelessTime (timestring) {
    if (!timestring) return '';
    const time = timestring.split('T')[1];
    const hoursAndMinutes = time.split(':');
    return `${hoursAndMinutes[0]}.${hoursAndMinutes[1]}`
}

function parseIntOrFloat (number) {
    if (!number) return number;
    if (number.indexOf(',') > -1) {
        const convertComma = number.replace(',', '.');
        const rounded = parseFloat(convertComma).toFixed(2);
        return String(rounded).replace('.', ',');
    }
    if (number.indexOf('.') > -1) {
        const rounded = parseFloat(number).toFixed(2);
        return String(rounded).replace('.', ',');
    }    
    return parseInt(number);
}

/** Verify password
 * @param {string} password - password
 * @param {object} options - options object foir validation
 * @description options:
 * {
 *  minLng: 8,          - password minimun length - default is 8
 *  uppercase: true,    - pw must contain uppercase letter
 *  lowercase: false    - pw must contain lowercase letter
 *  numbers: true       - pw must contain number
 * } */
const isValidPassword = (password, options) => {

    let minLng = options && options.minLng ? options.minLng : 8;
    let checkForUppercase = options && options.uppercase ? true : false;
    let checkForLowercase = options && options.lowercase ? true : false;
    let checkForNumbers = options && options.numbers ? true : false;

    // Minimum length must be atleast (8)
    if (password.length < minLng) return false;

    // Password must contain uppercase letter
    if (checkForUppercase && !password.match(/[A-Z]/)) return false;
    
    // Password must contain lowercase letter
    if (checkForLowercase && !password.match(/[a-z]/)) return false;
    
    // Password must contain number
    if (checkForNumbers && !password.match(/[0-9]/)) return false;
    
    return true;

}

/** Change date hour, minute and seconds to current time. 
 * Datepicker hours, minutes and seconds are 00:00:00 
 * When converted to iso string, the day is one less if time is 00.00.00
 * @param {Date} date*/
const setDateTimeToCurrentTime = (date) => {
    if (date) {
        let now = new Date();
        date.setHours(now.getHours() + 2);
        date.setMinutes(now.getMinutes());
        date.setSeconds(now.getSeconds());
    }
    return date;
}

const lib = {
    capitalizeFirstLetter,
    sortByProperty,
    sortByPropertyAsInt,
    sortByDate,
    groupArrayBy,
    groupArrayByObject,
    groupBy,
    convertImageToBase64,
    bufferDataToJpeg,
    parseZonelessTime,
    parseIntOrFloat,
    isValidPassword,
    setDateTimeToCurrentTime
};

export default lib;
