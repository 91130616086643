import { useContext, useState } from 'react';
import { AuthContext } from '../../Context/AuthContext';
import useRequestHandler from '../useRequestHandler';

const useReadLocationCsvData = () => {

    const { acquireTokenSilently, apiClient } = useContext(AuthContext);
    const { errorHandler, successHandler } = useRequestHandler();
    const [ locationCsvData, setLocationCsvData ] = useState([]);

    const readLocationCsvData = async (locationId) => {
        try {

            const accessToken = await acquireTokenSilently();

            if (accessToken === "login_required") throw Error("login_required");

            const result = await apiClient.read(accessToken, "export/locations/" + locationId + "/latest");

            const data = result.data || null;
            const reports = data.reports || [];

            if (locationCsvData) setLocationCsvData(reports);

            return successHandler(result, "useReadLocationCsvData", "read", { hide: true });

        } catch (error) {
            return errorHandler(error, "useReadLocationCsvData", "read");
        }
    }

    return { locationCsvData, readLocationCsvData };

}

export default useReadLocationCsvData;
