import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { Form, Input, Container, Message } from 'semantic-ui-react';
import { DataPrivacy, Loading, PageTitle } from './../../Reusable/Elements';
import { SubmitButton, OpenButton } from './../../Reusable/Buttons';
import { AuthContext } from './../../../Context/AuthContext';
import { MessageContext } from './../../../Context/MessageContext';
import Utils from './../../../Utils/utils';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Login = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    let query = useQuery();
    const { login } = useContext(AuthContext);
    const { addMessage } = useContext(MessageContext);

    const [ username, setUsername ] = useState("");
    const [ password, setPassword ] = useState("");
    const [ isLoading, setIsLoading ] = useState(false);
    const [ loginError, setLoginError ] = useState(false);
    const [ passwordNotValidError, setPasswordNotValidError ] = useState(false);

    const onSubmit = async () => {
        try {

            let isValidPassword = Utils.isValidPassword(password, { minLength: 8, uppercase: true, lowercase: true, number: false });
            if (!isValidPassword) return setPasswordNotValidError(true);
            if (!password) return;

            setIsLoading(true);

            // Get query params (query params found when user is logged in and he must authenticate again)
            const redirectUrl = query.get("redirectUrl");
            
            const result = await login(username, password);

            const url = redirectUrl ? "/" + redirectUrl : "/"; // Add redirect uri if user has to re authenticate

            if (result.status === 200) return history.push(url);

            setLoginError(true);
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
        }

    }

    // Check if session has ended (api returned 401 and request handler redirected to login apge)
    //  url has parameter "/login?session-ended=true"
    useEffect(() => {
        (async () => {
            const senssionEnded = query.get("session-ended");    
            if (senssionEnded) {
                //Show Session ended error and redirect user to authentication page
                const sessionEnded = { type:"warning", header:t('warnings.title'), body:t('warnings.sessionEnded') };
                await addMessage(sessionEnded);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <Form className="authenticate" onSubmit={() => onSubmit()} error={ passwordNotValidError || loginError}>

            <Loading active={isLoading} text={t('general.loading')} />

            <div className="description" >
                <b>{t('landing.tool')}</b>
                <p>{t('landing.toolDescription')}</p>
            </div>

            {/* Title */}
            <PageTitle title={t('authenticate.login')} href="/info" hideInfo />

            {/* Username */}
            <Form.Field
                required
                focus
                maxLength="255"
                control={Input}
                label={t('general.username')}
                value={username}
                onChange={(ev, {value}) => {
                    setLoginError(false);
                    setUsername(value);
                }}
                type="Text"
            />

            {/* Password */}
            <Form.Field
                required
                minLength="8"
                //maxLength="255"
                control={Input}
                label={t('general.password')}
                value={password}
                onChange={(ev, {value}) => {
                    setLoginError(false);
                    setPassword(value);
                    setPasswordNotValidError(false);
                }}
                type="Password"
                error={passwordNotValidError ? {
                    content: t('errors.passwordMustContainUppercaseAndLowerCaseLetters'),
                    pointing: "below"
                } : false}
            />

            {/* Username or password mismatch error message  */}
            {loginError && 
                <Message error header={t('general.error')} content={t('errors.usernameOrPasswordMismatch')} />
            }

            {/* Buttons */}
            <Container textAlign="right">
                <SubmitButton icon="key" color='green' title={t('general.login')} disabled={!username || !password}/>
                <OpenButton url="/register" title={t('general.register')} color='green' icon="add" />
                <p><small>{t('authenticate.passwordReset')} <Link className="green" to="/requestnewpassword">{t('authenticate.clickHere')}</Link></small></p>
            </Container>
            <hr/>

            {/* DataPrivacy */}
            <DataPrivacy className="green" />

        </Form>
    );

}

export default Login;
