import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Form, Input, Container } from 'semantic-ui-react';
import { DataPrivacy, Loading, PageTitle } from './../../Reusable/Elements';
import { SubmitButton, OpenButton } from './../../Reusable/Buttons';
import useRegister from './../../../Hooks/Register/useRegister';
//import { StateContext } from './../../../Context/StateContext';
import Utils from './../../../Utils/utils';

const Register = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const { companyAlreadyExistError, setCompanyAlreadyExistError, userAlreadyExistError, setUserAlreadyExistError, register } = useRegister();
    //const { globalState } = useContext(StateContext);

    const [ name, setName ] = useState("");
    //const [ lineOfBusiness, setLineOfBusiness ] = useState(1);
    const [ firstName, setFirstName ] = useState("");
    const [ lastName, setLastName ] = useState("");
    const [ emailAddress, setEmailAddress ] = useState("");
    const [ password, setPassword ] = useState("");
    const [ confirmPassword, setConfirmPassword ] = useState("");
    const [ isLoading, setIsLoading ] = useState(false);
    const [ passwordError, setPasswordError ] = useState(false);
    const [ passwordNotValidError, setPasswordNotValidError ] = useState(false);

    const onSubmit = async () => {
        try {

            let isValidPassword = Utils.isValidPassword(password, { minLength: 8, uppercase: true, lowercase: true, number: false });
            if (!isValidPassword) return setPasswordNotValidError(true);
            if (password !== confirmPassword) return setPasswordError(true);

            const registration = {
                "company": {
                    "name": name.trim(),
                    "lineOfBusiness": 0 // lineOfBusiness
                },
                "user": {
                    "firstName": firstName.trim(),
                    "lastName": lastName.trim(),
                    "emailAddress":emailAddress.trim(),
                    "language": "fi",
                    "password": password.trim()
                }
            }

            setIsLoading(true);

            const result = await register(registration);

            if (result.status === 200 || result.status === 201) return history.push("/login");

            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            return error;
        }

    }

    return (
        <Form className="register" onSubmit={(ev) => onSubmit(ev)} error={passwordError || companyAlreadyExistError ||  passwordNotValidError ||userAlreadyExistError} >
            
            <Loading active={isLoading} text={t('general.loading')} />

            {/* Title */}
            <PageTitle title={t('registration.title')} hideInfo/>

            {/* Company name */}
            <Form.Field
                required
                focus
                maxLength="255"
                control={Input}
                label={t('registration.company')}
                value={name}
                onChange={(ev, {value}) => {
                    setCompanyAlreadyExistError(false);
                    setName(value)
                }}
                type="Text"
                error={ companyAlreadyExistError ? { content: t('errors.companyExist'), pointing: 'below' } : null }
            />

            {/* Line of Business
            <Form.Select
                required
                label={t('registration.lineOfBusiness')}
                value={lineOfBusiness}
                options={globalState.lineOfBusinessOptions}
                onChange={(ev, {value}) => setLineOfBusiness(value) }
            /> */}

            {/* First name */}
            <Form.Field
                required
                minLength="1"
                maxLength="255"
                control={Input}
                label={t('general.firstName')}
                value={firstName}
                onChange={(ev, {value}) => setFirstName(value) }
                type="Text"
            />

            {/* Last name */}
            <Form.Field
                required
                minLength="1"
                maxLength="255"
                control={Input}
                label={t('general.lastName')}
                value={lastName}
                onChange={(ev, {value}) => setLastName(value) }
                type="Text"
            />

            {/* Email */}
            <Form.Field
                required
                maxLength="255"
                control={Input}
                label={t('registration.email')}
                value={emailAddress}
                onChange={(ev, {value}) => {
                    setUserAlreadyExistError(false);
                    setEmailAddress(value);
                }}
                type="Text"
                error={ userAlreadyExistError ? { content: t('errors.userExist'), pointing: 'below' } : null }
            />

            {/* Password */}
            <Form.Field
                required
                minLength="8"
                //maxLength="255"
                control={Input}
                label={t('general.password')}
                value={password}
                onChange={(ev, {value}) => {
                    setPasswordError(false);
                    setPassword(value);
                }}
                type="Password"
                error={ passwordError ? { content: t('errors.passwordMismatch'), pointing: 'below' } : null }
            />

            {/* Retype password */}
            <Form.Field
                required
                minLength="8"
                //maxLength="255"
                control={Input}
                label={t('registration.confirmPassword')}
                value={confirmPassword}
                onChange={(ev, {value}) => {
                    setPasswordError(false);
                    setConfirmPassword(value);
                }}
                type="Password"
                error={ passwordError ? { content: t('errors.passwordMismatch'), pointing: 'below' } : null }
            />

            {/* Buttons */}
            <Container textAlign="right">
                <SubmitButton color='green' icon="add" title={t('general.register')} disabled={!name
                    //|| !lineOfBusiness
                    || !firstName
                    || !lastName
                    || !emailAddress
                    || !password
                    || !confirmPassword
                } />
                <OpenButton url="/login" icon="key" title={t('general.login')} />
            </Container>
            <hr/>

            {/* DataPrivacy */}
            <DataPrivacy className="green" />

        </Form>
    );

}

export default Register;
