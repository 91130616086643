import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Form, Container, Input } from 'semantic-ui-react';
import { DataPrivacy, Loading, PageTitle } from './../../../Reusable/Elements';
import { SubmitButton } from './../../../Reusable/Buttons';
import useApproveInvitation from './../../../../Hooks/Users/useApproveInvitation';
import Utils from './../../../../Utils/utils';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const ApproveInvitation = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    let query = useQuery();
    const { approveInvitation } = useApproveInvitation();

    //const [ firstName, setFirstName ] = useState("");
    //const [ lastName, setLastName ] = useState("");
    const [ password, setPassword ] = useState("");
    const [ confirmPassword, setConfirmPassword ] = useState("");
    const [ isLoading, setIsLoading ] = useState(false);
    const [ passwordError, setPasswordError ] = useState(false);
    const [ passwordNotValidError, setPasswordNotValidError ] = useState(false);

    const onSubmit = async () => {
        try {

            let isValidPassword = Utils.isValidPassword(password, { minLength: 8, uppercase: true, lowercase: true, number: false });
            if (!isValidPassword) return setPasswordNotValidError(true);
            if (password !== confirmPassword) return setPasswordError(true);

            setIsLoading(true);

            const invitation = {
                guid: query.get("id"),
                //firstName: firstName.trim(),
                //lastName: lastName.trim(),
                password: password.trim()
            }

            const result = await approveInvitation(invitation);

            if (result.status === 200) return history.push("/login");

            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
        }
    }

    return (
        <div className="users">
            <Form onSubmit={onSubmit} autoComplete="off">

                <Loading active={isLoading} text={t('general.loading')} />

                <PageTitle title={t('users.approveInvitation')} hideInfo/>

                <p>{t('users.approveInvitationHelp')}</p>

                {/* First name 
                <Form.Field
                    required
                    minLength="1"
                    maxLength="255"
                    control={Input}
                    label={t('general.firstName')}
                    value={firstName}
                    onChange={(ev, {value}) => setFirstName(value) }
                    type="Text"
                />*/}

                {/* Last name 
                <Form.Field
                    required
                    minLength="1"
                    maxLength="255"
                    control={Input}
                    label={t('general.lastName')}
                    value={lastName}
                    onChange={(ev, {value}) => setLastName(value) }
                    type="Text"
                />*/}

                {/* Password */}
                <Form.Field
                    required
                    minLength="8"
                    //maxLength="255"
                    control={Input}
                    label={t('general.password')}
                    value={password}
                    onChange={(ev, {value}) => {
                        setPassword(value);
                    }}
                    type="Password"
                />

                {/* Retype password */}
                <Form.Field
                    required
                    minLength="8"
                    //maxLength="255"
                    control={Input}
                    label={t('registration.confirmPassword')}
                    value={confirmPassword}
                    onChange={(ev, {value}) => {
                        setConfirmPassword(value);
                        setPasswordError(false);
                        setPasswordNotValidError(false);
                    }}
                    type="Password"
                    error={ 
                        passwordError ?
                            { 
                                content: t('errors.passwordMismatch'),
                                pointing: 'below'
                            }
                        : 
                        passwordNotValidError ?
                            {
                                content: t('errors.passwordMustContainUppercaseAndLowerCaseLetters'),
                                pointing: "below"
                            }
                        : false
                       
                    }
                />

                <Container textAlign="right">
                    <SubmitButton icon="save" color="green" title={t('general.confirm')} />
                </Container>
                <hr/>
    
                {/* DataPrivacy */}
               <DataPrivacy className="green" />

            </Form>
        </div>
    );

}

export default ApproveInvitation;
