import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Form, Select, Container, Accordion, Grid, Icon } from 'semantic-ui-react';
import { Loading, PageTitle, Info } from './../../Reusable/Elements';
import useListLocations from './../../../Hooks/Locations/useListLocations';
import useReadLatestInspection from './../../../Hooks/Inspection/useReadLatestInspection';
import useListInspectionEvaluations from './../../../Hooks/Evaluation/useListInspectionEvaluations';
import useListRiskFactorCategories from './../../../Hooks/RiskFactorCategories/useListRiskFactorCategories';
import useListRiskFactors from './../../../Hooks/RiskFactors/useListRiskFactors';
import useReadLocationCsvData from './../../../Hooks/Export/useReadLocationCsvData';
import useReadLocationsCsvData from './../../../Hooks/Export/useReadLocationsCsvData';
import { OpenButton } from './../../Reusable/Buttons';
import { StateContext } from './../../../Context/StateContext';
import { GenerateCSVButton } from './../../Reusable/Exports';
import { createLocationOptions, createLocationsCsvReport, createEmptyCsvHeaders, modifyEvaluations } from './reportUtils';

const AccordionCategoryContent = ({ evaluation }) => {

    const { t } = useTranslation();
    const history = useHistory();
    const { globalState } = useContext(StateContext);

    return (
            <Accordion.Content>

                <p>{evaluation.description}</p>

                {evaluation.evaluationCompleted && 
                    <OpenButton
                        type="button"
                        title={t('evaluation.editRiskFactorEvaluation')}
                        color={"green"}
                        icon='arrow left'
                        onClick={() => {
                            globalState.setSelectedEvaluationId(evaluation.id);
                            history.push("/evaluations/" + evaluation.id);
                        }
                    } />
                }

                {!evaluation.evaluationCompleted && 
                    <OpenButton
                        type="button"
                        title={t('evaluation.evaluateriskfactor')}
                        color={globalState.locationId ? "green" : null}
                        icon='arrow left'
                        onClick={() => {
                            globalState.setSelectedEvaluationId(evaluation.id);
                            history.push("/evaluations/" + evaluation.id);
                        }
                    } />
                }

                <br/><br/>

            </Accordion.Content>
    );

}

const AccordionCategoryTitle = ({ riskFactorCategory, riskFactor, evaluation, index, activeIndex, setActiveIndex }) => {

    const { t } = useTranslation();

    return (
        <Accordion.Title
            active={activeIndex === index}
            index={index}
            onClick={(ev, data) => {
                if (activeIndex === index) setActiveIndex(null);
                if (activeIndex !== index) setActiveIndex(index);
            }}
        >
            <Grid>
                <Grid.Row columns={2}>
                    <Grid.Column verticalAlign={"middle"} style={{ width: "10px !important" }}>
                        <div style={{ marginLeft: "30px" }}>
                            {riskFactorCategory && t('riskFactorCategories.' + riskFactorCategory.referenceName)}
                        </div>
                        <div>
                            <p>

                                {/* Common category risk factors have reference name */}
                                {riskFactorCategory && riskFactor && evaluation.evaluationCompleted && riskFactorCategory.id !== 5 &&
                                    <>
                                        <Icon name="checkmark" color="green" />
                                        <span style={{ marginLeft: "5px" }}>{t('riskFactors.' + riskFactor.referenceName)}</span>
                                    </>                                
                                }
                                {riskFactorCategory && riskFactor && !evaluation.evaluationCompleted && riskFactorCategory.id !== 5 &&
                                    <span style={{ marginLeft: "30px" }}>{t('riskFactors.' + riskFactor.referenceName)}</span>
                                }

                                {/* Other category dont have reference name ! */}
                                {riskFactorCategory && riskFactor && evaluation.evaluationCompleted && riskFactorCategory.id === 5 &&
                                    <>
                                        <Icon name="checkmark" color="green" />
                                        <span style={{ marginLeft: "5px" }}>{riskFactor.name}</span>
                                    </>                                
                                }
                                {riskFactorCategory && riskFactor && !evaluation.evaluationCompleted && riskFactorCategory.id === 5 &&
                                    <span style={{ marginLeft: "30px" }}>{riskFactor.name}</span>
                                }
                            </p>
                        </div>
                        <div style={{ marginLeft: "30px" }}>
                            {t('evaluation.riskImpact') + " " + evaluation.riskImpact + "/25"}
                        </div>
                    </Grid.Column>
                    <Grid.Column textAlign="right">
                        <Icon name={activeIndex === index ? "angle right" : "angle down"}  />
                    </Grid.Column>
                </Grid.Row>
            </Grid>                     
        </Accordion.Title>
    );

}

const Report = (props) => {

    const { t } = useTranslation();
    const { locations, listLocations } = useListLocations([]);
    const { riskFactorCategories, listRiskFactorCategories } = useListRiskFactorCategories([]);
    const { locationsCsvData, readLocationsCsvData } = useReadLocationsCsvData([]);
    const { locationCsvData, readLocationCsvData } = useReadLocationCsvData([]);
    const { latestInspection, readLatestInspection } = useReadLatestInspection(null);
    const { riskFactors, listRiskFactors } = useListRiskFactors([]);
    const { inspectionEvaluations, setInspectionEvaluations, listInspectionEvaluations } = useListInspectionEvaluations();
    const { globalState } = useContext(StateContext);
    const [ activeIndex, setActiveIndex ] = useState(null);
    const [ selectedLocation, setSelectedLocation ] = useState(0);
    const [ isLoading, setIsLoading ] = useState(false);

    // 1.Initialize
    useEffect(() => {
        (async () => {
            setIsLoading(true);
            await listLocations();
            await listRiskFactorCategories();
            await readLocationsCsvData();

            // Set previous location if usercameback from the edit evaluation page
            if (globalState.locationId !== 0) setSelectedLocation(globalState.locationId);

            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // 2.On Location change
    useEffect(() => {
        if (selectedLocation) {

            setIsLoading(true);

            const location = locations.find(item => item.id === selectedLocation);

            globalState.setLocationId(selectedLocation);
            globalState.setLocationObj(location);
            
            //setLocation(location);

            (async () => {

                await listRiskFactors(selectedLocation);
                await readLatestInspection(selectedLocation);
                await readLocationCsvData(selectedLocation);

                setIsLoading(false);

            })();

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLocation]);

    // On latest inspection change
    useEffect(() => {
        (async () => {
            if (latestInspection && latestInspection.id) {
                setIsLoading(true);
                await listInspectionEvaluations(latestInspection.id);
                setIsLoading(false);
            } else {
                setInspectionEvaluations([]);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [latestInspection]);

    const filteredEvaluations = modifyEvaluations(inspectionEvaluations, riskFactors, riskFactorCategories);
    const csvHeaders = createEmptyCsvHeaders(t);
    const locationsCsvReport = createLocationsCsvReport(t, locations, locationsCsvData);
    const locationCsvReport = createLocationsCsvReport(t, locations, locationCsvData);

    return (
        <div className="report">
            <Form>

                <Loading active={ isLoading } text={t('general.loading')} />

                <PageTitle title={t('report.report')} hideInfo/>

                <Info text={t('report.help')} />
 
                <Form.Field
                    label={t('inspection.location')}
                    control={Select}
                    value={selectedLocation}
                    options={createLocationOptions(t, locations)}
                    onChange={(ev, {value}) => setSelectedLocation(value)}
                />

                {/* Location is selected => show content */}
                { !isLoading && filteredEvaluations.length > 0 && 
                    <Accordion defaultActiveIndex={0} panels={
                        /* Create category titles / headers */
                        filteredEvaluations.map((evaluation, index) => {
                            return {
                                key: 'category-panel-' + index,
                                title: <AccordionCategoryTitle 
                                            riskFactorCategory={evaluation.riskFactorCategory}
                                            riskFactor={evaluation.riskFactor}
                                            evaluation={evaluation}
                                            index={index}
                                            activeIndex={activeIndex}
                                            setActiveIndex={setActiveIndex}
                                        />,
                                content: {
                                    content: <AccordionCategoryContent evaluation={evaluation} />,
                                    active: activeIndex === index
                                }
                            }
                        })
                    } />
                }       

                { !isLoading && selectedLocation !== 0 && filteredEvaluations.length === 0 &&
                    <p>{t('errors.evaluationsNotFound')}</p>
                }

                {/* Buttons */}
                <Container textAlign="right">
                    <GenerateCSVButton title={t('report.downloadCsv')} headers={csvHeaders} data={locationCsvReport} filename="location_export.csv" />
                    <GenerateCSVButton title={t('report.downloadCsvFromAllObjects')} headers={csvHeaders} data={locationsCsvReport} filename="locations_export.csv"/>
                    <OpenButton icon="arrow left" floated={"right"} title={t('general.frontpage')} url="/" />
                </Container>

            </Form>
        </div>
    );

}

export default Report;
