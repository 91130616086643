import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../Context/AuthContext';
import useRequestHandler from './../useRequestHandler';

const useCreateRiskFactor = () => {

    const { t } = useTranslation();
    const { acquireTokenSilently, apiClient } = useContext(AuthContext);
    const { errorHandler, successHandler } = useRequestHandler();
    
    /** Create riskFactor
     * @param {object} riskFactor - object discribing the riskFactor
     * @example
     * {
     *     "name": "",
     *     "description": ""
     * }
    */
    const createRiskFactor = async (riskFactor) => {
        try {

            const accessToken = await acquireTokenSilently();

            if (accessToken === "login_required") throw Error("login_required");

            const result = await apiClient.create(accessToken, "riskfactors", riskFactor);

            return successHandler(result, "useCreateRiskFactor", "create", { message: t('success.riskFactorCreatedSuccesfully') });

        } catch (error) {
            return errorHandler(error, "useCreateRiskFactor", "create");
        }
    }

    return { createRiskFactor };

}

export default useCreateRiskFactor;
