import { useContext, useState } from 'react';
import { AuthContext } from '../../Context/AuthContext';
import useRequestHandler from './../useRequestHandler';

const useListRiskFactors = () => {

    const { acquireTokenSilently, apiClient } = useContext(AuthContext);
    const [ riskFactors, setRiskFactors ] = useState([]);
    const { errorHandler, successHandler } = useRequestHandler();
    
    /** List risk factors by location id 
     * List all risk factors which are added to the location and default risk factors
     * @param {integer} id - location id */
    const listRiskFactors = async (id) => {
        try {

            setRiskFactors([]);

            const accessToken = await acquireTokenSilently();
            
            if (accessToken === "login_required") throw Error("login_required");

            const result = await apiClient.list(accessToken, "locations/" + id + "/riskfactors");

            const data = result.data || {};
            const riskFactors = data.riskFactors || null;

            if (riskFactors) setRiskFactors(riskFactors);

            return successHandler(result, "useListRiskFactors", "list", { hide: true });

        } catch (error) {
            return errorHandler(error, "useListRiskFactors", "list");
        }
    }

    return { riskFactors, listRiskFactors };

}

export default useListRiskFactors;
