import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../Context/AuthContext';
import useRequestHandler from './../useRequestHandler';

const useApproveInvitation = () => {

    const { t } = useTranslation();
    const { apiClient } = useContext(AuthContext);
    const { errorHandler, successHandler } = useRequestHandler();

    /** approve invitation
     * @param {object} user - object discribing the user
     * @example
     * {
     *     "guid": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
     *     "firstName":"string",
     *     "lastName":"string",
     *     "password":"string"
     * }
    */
    const approveInvitation = async (invitation) => {
        try {

            //const accessToken = await acquireTokenSilently();

            //if (accessToken === "login_required") throw Error("login_required");

            const result = await apiClient.create(null, "users/approveinvitation", invitation);

            return successHandler(result, "useApproveInvitation", "approve", { message: t('success.userApprovedInvitationSuccesfully') });

        } catch (error) {
            return errorHandler(error, "useApproveInvitation", "approve");
        }
    }

    return { approveInvitation };

}

export default useApproveInvitation;
