import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../Context/AuthContext';
import useRequestHandler from './../useRequestHandler';

const useUpdateEvaluation = () => {

    const { t } = useTranslation();
    const { acquireTokenSilently, apiClient } = useContext(AuthContext);
    const { errorHandler, successHandler } = useRequestHandler();
    
    /** Update evaluation
     * @param {object} evaluation - object discribing the evaluation
     * @example
     * {
     *     "id": 1,
     *     "inspectionId": 1,
     *     "riskFactorsId": 1,
     *     "description": "",
     *     "recognized": "",
     *     "riskPropability": 1,
     *     "riskConcequences": 1,
     *     "riskFactor": 1,
     *     "proceeding": "",
     *     "riskPropabilityAfterProceeding": 1,
     *     "riskConcequencesAfterProceeding": 1,
     *     "riskFactorAfterProceeding": 1,
     *     "responsiblePerson": "",
     *     "schedule": "2011-10-05T14:48:00.000Z",
     *     "evalutationCompleted": false
     * }
    */
    const updateEvaluation = async (evaluation) => {
        try {

            const accessToken = await acquireTokenSilently();
            
            if (accessToken === "login_required") throw Error("login_required");
            
            const result = await apiClient.update(accessToken, "evaluations", evaluation);

            return successHandler(result, "useUpdateEvaluation", "update", { message: t('success.evaluationUpdatedSuccesfully') });

        } catch (error) {
            return errorHandler(error, "useUpdateEvaluation", "update");
        }
    }

    return { updateEvaluation };

}

export default useUpdateEvaluation;
