import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MessageContext } from './../Context/MessageContext';
import Config from '../Config/config';

const useRequestHandler = () => {

    const { t } = useTranslation();
    const history = useHistory();
    const { addMessage } = useContext(MessageContext);

    const DEBUG_ERRORS = true;
    const DEBUG_SUCCESS = false;

    /** Handle errors and error messages
     * @param {object} error - http response error object
     * @param {string} caller - name of the calling hook
     * @param {string} operation - type of the operation ["register" || "login" || "logout" || "refresh" || "list" || "create" || "read" || "update" || "delete" || "confirm" || "approve" || "invitation" || "request" || null]
     * @param {boolean} options.hide - if true, message is not added, default is false
     * @param {string} options.message - Optional custom message (overwrites the default message)
     * @param {object} options.customLog - This is logged to the console in debug mode */
    const errorHandler = async (error, caller, operation, options) => {
        try {

            // Default values for http errors
            const response = error && error.response ? error.response : error;
            const status = response.status || null;
            const data = response.data || {};
            const errorCode = data && data.errorCode ? data.errorCode : null;

            let hide = options && options.hide ? options.hide : false;
            const message = options && options.message ? options.message : null;
            const customLog = options && options.customLog ? options.customLog : null;

            // Hide errors if login is required errors!
            const loginIsRequired = error.message === "login_required" ? true : false;

            if (loginIsRequired) hide = true;

            // Log in debug mode
            if (Config.environment === 'development' && DEBUG_ERRORS) {
                console.log("################ Error Handler ################");
                console.log("Caller: " + caller);
                console.log("Operation: " + operation);
                console.log("Status: " + status);
                console.log("Error Code: " + errorCode);
                console.log("Hide: " + hide);
                console.log("Custom message: " + message);
                console.log("Error response");
                console.log(response);
                console.log("Custom Log");
                console.log(customLog);
                console.log("###############################################");
            }

            // All possible error messages
            const messages = {

                // HTTP Error messages [400, 401, 403, 404, 500]
                badRequest400: { type:"error", header:t('general.error'), body:t('errors.badRequest') },
                unauthorized401: { type:"error", header:t('general.error'), body:t('errors.unauthorized') },
                forbidden403: { type:"error", header:t('general.error'), body:t('errors.forbidden') },
                notFound404: { type:"error", header:t('general.error'), body:t('errors.notFound') },
                internalServerError500: { type:"error", header:t('general.error'), body:t('errors.internalServerError') },

                // Error code messages [-1, -2]
                objectDoesNotExist: { type:"error", header:t('general.error'), body:t('errors.objectDoesNotExist') },
                objectAlreadyExist: { type:"error", header:t('general.error'), body:t('errors.objectAlreadyExist') },

                // General request errors
                registerFailed: { type:"error", header:t('general.error'), body:t('errors.registerFailed') },
                loginFailed: { type:"error", header:t('general.error'), body:t('errors.loginFailed') },
                logoutFailed: { type:"error", header:t('general.error'), body:t('errors.logoutFailed') },
                refreshFailed: { type:"error", header:t('general.error'), body:t('errors.refreshFailed') },
                listFailed: { type:"error", header:t('general.error'), body:t('errors.listFailed') },
                createFailed: { type:"error", header:t('general.error'), body:t('errors.createFailed') },
                readFailed: { type:"error", header:t('general.error'), body:t('errors.readFailed') },
                updateFailed: { type:"error", header:t('general.error'), body:t('errors.updateFailed') },
                deleteFailed: { type:"error", header:t('general.error'), body:t('errors.deleteFailed') },
                confirmFailed: { type:"error", header:t('general.error'), body:t('errors.confirmFailed') },
                approveFailed: { type:"error", header:t('general.error'), body:t('errors.approveFailed') },
                invitationFailed: { type:"error", header:t('general.error'), body:t('errors.invitationFailed') },
                requestFailed: { type:"error", header:t('general.error'), body:t('errors.requestFailed') }

            }

            // Set showed message
            let selectedMessage = messages.internalServerError500;

            // Handle 400 Bad request errors AND 404 Not found errors
            if (status === 400 || status === 404) {

                // Basic cases
                if (operation === 'register') selectedMessage = messages.registerFailed;
                if (operation === 'login') selectedMessage = messages.loginFailed;
                if (operation === 'logout') selectedMessage = messages.logoutFailed;
                if (operation === 'refresh') selectedMessage = messages.refreshFailed;
                if (operation === 'list') selectedMessage = messages.listFailed;
                if (operation === 'create') selectedMessage = messages.createFailed;
                if (operation === 'read') selectedMessage = messages.readFailed;
                if (operation === 'update') selectedMessage = messages.updateFailed;
                if (operation === 'delete') selectedMessage = messages.deleteFailed;
                if (operation === 'confirm') selectedMessage = messages.confirmFailed;
                if (operation === 'approve') selectedMessage = messages.approveFailed;
                if (operation === 'invitation') selectedMessage = messages.invitationFailed;
                if (operation === 'request') selectedMessage = messages.requestFailed;

                // Special cases which overwrite message
                if (errorCode === -1) selectedMessage = messages.objectDoesNotExist;
                if (errorCode === -2) selectedMessage = messages.objectAlreadyExist;

            }

            // Handle 400 Bad request errors AND 404 Not found errors if operation is missing
            if (status === 400 && !operation) selectedMessage = messages.badRequest400;
            if (status === 404 && !operation) selectedMessage = messages.notFound404;

            // Handle 403 Forbidden errors
            if (status === 401) selectedMessage = messages.unauthorized401;
            if (status === 403) selectedMessage = messages.forbidden403;

            // Handle 500 internal server errors
            if (status === 500) selectedMessage = messages.internalServerError500;
 
            // Use Custom message
            if (message && message.length && typeof message === 'string') {
                selectedMessage =  { type:"error", header: t('general.error'), body: message }
            }

            // Add message
            if (hide !== true) await addMessage(selectedMessage);

            // If api return 401, user is not authenticated, token is expired or other error happened. User must reauthenticate
            if (status === 401) {
                history.push("/login?session-ended=true");
            }

            return response;

        } catch (error) {
            return error;
        }
    }

    /** Handle success and success messages
     * @param {object} res - http response object
     * @param {string} caller - name of the calling hook
     * @param {string} operation - type of the operation ["register" || "login" || "logout" || "refresh" || list" || "create" || "read" || "update" || "delete" || "confirm" || "approve" || "invitation" || "request" || null]
     * @param {boolean} options.hide - if true, message is not added, default is false
     * @param {string} options.header - Optional custom message header (overwrites the default message header)
     * @param {string} options.message - Optional custom message content (overwrites the default message content)
     * @param {object} options.customLog - This is logged to the console in debug mode */
     const successHandler = async (res, caller, operation, options) => {
        try {

            const response = res && res.response ? res.response : res;
            const status = response.status || 200;
            //const data = response.data || {};
            const hide = options && options.hide ? options.hide : false;
            const header = options && options.header ? options.header : null;
            const message = options && options.message ? options.message : null;
            const customLog = options && options.customLog ? options.customLog : null;

            if (Config.environment === 'development' && DEBUG_SUCCESS) {
                console.log("################ Success Handler ################");
                console.log("Caller: " + caller);
                console.log("Operation: " + operation);
                console.log("Status: " + status);
                console.log("Hide: " + hide);
                console.log("Custom header: " + header);
                console.log("Custom message: " + message);
                console.log("Success response");
                console.log(response);
                console.log("Custom Log");
                console.log(customLog);     
                console.log("###############################################");
            }

            const messages = {

                // HTTP Error messages [200, 201, 204]
                ok200: { type:"success", header:t('success.ok'), body:t('success.ok') },
                created201: { type:"success", header:t('success.created'), body:t('success.createSuccess') },
                noContent204: { type:"success", header:t('success.saved'), body:t('success.noContent') },

                // General request messages
                registerSuccess: { type:"success", header:t('success.register'), body:t('success.registrationEmailSent') },
                loginSuccess: { type:"success", header:t('success.ok'), body:t('success.login') },
                logoutSuccess: { type:"success", header:t('success.ok'), body:t('success.logout') },
                refreshSuccess: { type:"success", header:t('success.ok'), body:t('success.refresh') },

                listSuccess: { type:"success", header:t('success.list'), body:t('success.listSuccess') },
                createSuccess: { type:"success", header:t('success.create'), body:t('success.createSuccess') },
                readSuccess: { type:"success", header:t('success.reade'), body:t('success.readSuccess') },
                updateSuccess: { type:"success", header:t('success.update'), body:t('success.updateSuccess') },
                deleteSuccess: { type:"success", header:t('success.delete'), body:t('success.deleteSuccess') },
                confirmSuccess: { type:"success", header:t('success.confirm'), body:t('success.confirmSuccess') },
                approveSuccess: { type:"success", header:t('success.approve'), body:t('success.approveSuccess') },
                invitationSuccess: { type:"success", header:t('success.invited'), body:t('success.invitationSuccess') },
                requestSuccess: { type:"success", header:t('success.requested'), body:t('success.requestSuccess') }

            }

            // Set showed default message
            let selectedMessage = messages.ok200;

            // Handle 200 OK, 201 Created AND 204 No content
            if (status === 200 || status === 201 || status === 204) {

                // Basic cases
                if (operation === 'register') selectedMessage = messages.registerSuccess;
                if (operation === 'login') selectedMessage = messages.loginSuccess;
                if (operation === 'logout') selectedMessage = messages.logoutSuccess;
                if (operation === 'refresh') selectedMessage = messages.refreshSuccess;
                if (operation === 'list') selectedMessage = messages.listSuccess;
                if (operation === 'create') selectedMessage = messages.createSuccess;
                if (operation === 'read') selectedMessage = messages.readSuccess;
                if (operation === 'update') selectedMessage = messages.updateSuccess;
                if (operation === 'delete') selectedMessage = messages.deleteSuccess;
                if (operation === 'confirm') selectedMessage = messages.confirmSuccess;
                if (operation === 'approve') selectedMessage = messages.approveSuccess;
                if (operation === 'invitation') selectedMessage = messages.invitationSuccess;
                if (operation === 'request') selectedMessage = messages.requestSuccess;

            }
 
            // Set defaults if operation is null
            if (status === 200 && !operation) selectedMessage = messages.ok200
            if (status === 201 && !operation) selectedMessage = messages.created201
            if (status === 204 && !operation) selectedMessage = messages.noContent204

            // Use Custom message
            if (message && message.length && typeof message === 'string') {
                selectedMessage =  { type:"success", header: header || t('general.saved'), body: message }
            }

            // Add message
            if (hide !== true) await addMessage(selectedMessage);

            return response;

        } catch (error) {
            return error;
        }
    }

    return { errorHandler, successHandler };

}

export default useRequestHandler;
