import React, { createContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {Loading} from '../Components/Layout/Layout';
import useRequestHandler from './../Hooks/useRequestHandler';
import config from './../Config/config';
import authentication from './../Utils/authentication';
import webApiClient from './../Utils/webApiClient';

const DEBUG = false;
const log = console.log;
const msg = {
	refresh: {
		m1: "### M1: AuthContext refresh - Start refresh process ",
		m2: "### M2: AuthContext refresh - Verify current path: ",
		m3: "### M3: AuthContext refresh - Current path is not public: ",
		m4: "### M4: AuthContext refresh - User is authenticated: ",
		m5: "### M5: AuthContext refresh - Current path is public (do nothing, stop loader) "
	},
	public: {
		m1: "### M1: AuthContext public path - Get current path and compare to public paths "
	},
	login: {
		m1: "### M1: AuthContext login - Start login process ",
		m2: "### M2: AuthContext login - Try to login ",
		m3: "### M3: AuthContext login - Login success (call success handler) ",
		m4: "### M4: AuthContext login - Login error (log error to console) "
	},
	logout: {
		m1: "### M1: AuthContext logout - Start logout process ",
		m2: "### M2: AuthContext logout - Try to logout ",
		m3: "### M3: AuthContext logout - Logout success (call success handler) ",
		m4: "### M4: AuthContext logout - Logout error (log error to console)"
	},
	acquire: {
		m1: "### M1: AuthContext acquire - Start acquire token silent process ",
		m2: "### M2: AuthContext acquire - Verify user is authenticated:  ",
		m3: "### M3: AuthContext acquire - user is authenticated (return token):  ",
		m4: "### M4: AuthContext acquire - user is not authenticated (delete session and redirect user to login) ",
		m5: "### M5: AuthContext acquire - Logout acquire token silent error (log error to console)"
	},
	verify: {
		m1: "### M1: AuthContext verify - Start verify login process ",
		m2: "### M2: AuthContext verify - User is authenticated (return true):  ",
		m3: "### M3: AuthContext verify - User is not authenticated (Try to re authenticate using refresh token)  ",
		m4: "### M4: AuthContext verify - Authentication succeeded (return true):  ",
		m5: "### M5: AuthContext verify - Reauthentication failed (return false) ",
		m6: "### M6: AuthContext verify - Verify login error (log error to console)"
	}
}

export const AuthContext = createContext();

/** App authentication provider 
 * @description
 *  - Token is saved to the state (for security reason not to cookie) - if user refresh page, token is lost and refresh token must be used
 *  - RefreshToken is saved to cookie and can be used to fech new token after page refresh
 *  - User profile is saved to the state */
const AuthContextProvider = ({children}) => {

    const history = useHistory();
    const { successHandler, errorHandler } = useRequestHandler();
    const [ isLoading, setIsLoading ] = useState(true);
    const [ loginError, setLoginError ] = useState(false);
    const apiClient = webApiClient(authentication.getToken);
    const userProfile = authentication.getUserProfile();

    /**  Verify that user is logged in.
     * @description Try to re authenticate if token is missing but refreshtoken is found.
     * @returns {boolean} isAuthenticated - true or false */
    const verifyLogin = async () => {
        try {

            if (DEBUG) log(msg.verify.m1);

            // Check if user is authenticated?
            const userIsAuthenticated = await authentication.verifyUserIsAuthenticated();

            if (DEBUG && userIsAuthenticated) log(msg.verify.m2 + userIsAuthenticated);

            // User is already authenticated
            if (userIsAuthenticated) return true;

            if (DEBUG) log(msg.verify.m3);

            // Token is missing - try to reauthenticate with refresh token
            const authenticationSucceeded = await authentication.reauthenticateWithRefreshToken();
            
            if (DEBUG) log(msg.verify.m4 + authenticationSucceeded);

            // Reauthentication succeeded
            if (authenticationSucceeded) return true;

            if (DEBUG) log(msg.verify.m5 + authenticationSucceeded);

            // Reauthentication failed
            return false;

        } catch (error) {

            if (DEBUG) log(msg.verify.m6);
            if (DEBUG) log(error);

            return false;
        }
    }

    /** Try to get token */
    const acquireTokenSilently = async () => {
        try {

            if (DEBUG) log(msg.acquire.m1);

            // Verify that user is authenticated
            const userIsAuthenticated = await verifyLogin();
            if (DEBUG) log(msg.acquire.m2 + userIsAuthenticated);

            // Return token if user is authenticated and token is found
            if (userIsAuthenticated) {
                const token = await authentication.getToken();
                if (DEBUG) log(msg.acquire.m3 + token);
                if (token) return token;
            }

            if (DEBUG) log(msg.acquire.m4);

            // Delete session if user is not authenticated and redirect to login page
            authentication.logout()
            history.push("/login");
            return "login_required";

        } catch (error) {
            if (DEBUG) log(msg.acquire.m5);
            if (DEBUG) log(error);
            return "login_required";
        }
    }

    const login = async (username, password) => {
        try {

            if (DEBUG) log(msg.login.m1);
            if (DEBUG) log(msg.login.m2);

            const result = await authentication.login(username, password);

            if (DEBUG) log(msg.login.m3);

            return successHandler(result, "AuthContext", "login", { hide: true });

        } catch (error) {

            if (DEBUG) log(msg.login.m4);
            if (DEBUG) log(error);

            const result = error && error.response ? error.response : error;
            const status = result.status || null;
            const options = {}

            if (status === 401) options.hide = true;

            if (status === 400) setLoginError(true);

            return errorHandler(error, "AuthContext", "login", options);

        }
    }

    /** Log user out */
    const logout = async () => {
        try {

            if (DEBUG) log(msg.logout.m1);
            const result = await authentication.logout();
            history.push("/login");

            if (DEBUG) log(msg.logout.m2);
            return successHandler(result, "AuthContext", "logout", { hide: true });

        } catch (error) {
            
            if (DEBUG) log(msg.logout.m3);
            history.push("/login");
            return errorHandler(error, "AuthContext", "logout", { hide: true });

        }
    }

    /** Check if current path is public.
     * @returns {boolean} true or false */
    const isPublicPath = () => {
        if (DEBUG) log(msg.public.m1);
        const currentPath = history.location.pathname;
        const currentPathIsPublicPath = config.app.publicRoutes.find(path => path === currentPath);
        if (currentPathIsPublicPath) return true;
        return false;
    }

    // When page load or user refresh, check the current path.
    // If path is not public, user must be authenticated, else redirect to login
    useEffect(() => {
        (async () => {

            if (DEBUG) log(msg.refresh.m1);

            // Show loading
            setIsLoading(true);

            // Get users current path
            const currentPathIsPublicPath = isPublicPath();

            //console.log("Check if current path is public path");
            //console.log("location: " + history.location.pathname);
            //console.log("currentPathIsPublicPath: " + currentPathIsPublicPath);

            if (DEBUG) log(msg.refresh.m2 + currentPathIsPublicPath);

            // If path is not public, user has to be logged in
            if (!currentPathIsPublicPath) {
                
                if (DEBUG) log(msg.refresh.m3 + currentPathIsPublicPath);

                // Verify that user is authenticated
                const userIsAuthenticated = await verifyLogin();
                
                if (DEBUG) log(msg.refresh.m4 + userIsAuthenticated);

                // If user is not authenticated, redirect to login
                if (!userIsAuthenticated) history.push("/login");
 
            }

            if (DEBUG) log(msg.refresh.m5 + currentPathIsPublicPath);

            // If path is public, Stop loader
            setIsLoading(false);

        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) return <Loading />

    return (
        <AuthContext.Provider value={{ userProfile, acquireTokenSilently, loginError, login, logout, apiClient } }>
            {children}
        </AuthContext.Provider>
    );

}

export default AuthContextProvider;
