import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Container, Grid } from 'semantic-ui-react';
import { SubmitButton, DeleteButton, CancelButton, EditButton } from '../../../Reusable/Buttons';
import useReadUser from '../../../../Hooks/Users/useReadUser';
import useListUsers from '../../../../Hooks/Users/useListUsers';
import useDeleteUser from '../../../../Hooks/Users/useDeleteUser';
import useSendInvitation from '../../../../Hooks/Users/useSendInvitation';
import { AuthContext } from '../../../../Context/AuthContext';
import { Loading, PageTitle } from '../../../Reusable/Elements';
import { YesNoModal } from '../../../Reusable/Modals';
import Forbidden from './../../../Pages/Forbidden';

const ReadUser = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const { id } = useParams();

    const { userProfile } = useContext(AuthContext);
    const { user, readUser } = useReadUser();
    const { users, listUsers } = useListUsers();

    const { deleteUser } = useDeleteUser();
    const { sendInvitation } = useSendInvitation();

    const [ deleteModalOpen, setDeleteModalOpen ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(true);
    const [ isLoadingUsers, setIsLoadingUsers ] = useState(true);

    // Fetch user
    useEffect(() => {
        (async () => {
            if (id) {
                await readUser(id);
                setIsLoading(false);
            }            
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    // Fetch Users
    useEffect(() => {
        (async () => {
                await listUsers();
                setIsLoadingUsers(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onDeleteUser = async () => {
        try {
            
            setIsLoading(true);

            const result = await deleteUser(parseInt(id));

            if (result.status === 200 || result.status === 204) return history.push("/management");

            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
        }
    }

    const onInviteUser = async () => {
        try {

            setIsLoading(true);

            const result = await sendInvitation(user.emailAddress);

            if (result.status === 204) return history.goBack();

            setIsLoading(false);
            return result;

        } catch (error) {
            setIsLoading(false);
            return error;
        }
    }

    let userIsViewingOwnProfile = userProfile && user && userProfile.id && user.id && userProfile.id === user.id ? true : false;

    const creator = users.length && user.creator ? users.find(item => item.id === user.creator): {};
    const modifier = users.length && user.modifier ? users.find(item => item.id === user.modifier) : {};
    const creatorName = creator && creator.firstName ? creator.firstName + " " + creator.lastName : "";
    const modifierName = modifier && modifier.firstName ? modifier.firstName + " " + modifier.lastName : "";

    if (!userProfile || !userProfile.isAdminUser) return <Forbidden />;

    return (
        <Form className="users" >

            <YesNoModal type="delete"
                header={t('general.deleteUser')}
                text={t('users.areYouSureYouWantToDeleteUser')}
                method={onDeleteUser}
                item={id}
                open={deleteModalOpen}
                setOpen={setDeleteModalOpen}
            />

            <Loading active={isLoading || isLoadingUsers} text={t('general.loading')} />

            {/* Title */}
            <PageTitle title={t('users.user')} hideInfo />

            <Grid stackable>

                {/* First name */}
                <Grid.Row>
                    <Grid.Column width={3}><b>{t('general.firstName')}</b></Grid.Column>
                    <Grid.Column width={13}>{user.firstName}</Grid.Column>
                </Grid.Row>

                {/* Last name */}
                <Grid.Row>
                    <Grid.Column width={3}><b>{t('general.lastName')}</b></Grid.Column>
                    <Grid.Column width={13}>{user.lastName}</Grid.Column>
                </Grid.Row>

                {/* Email */}
                <Grid.Row>
                    <Grid.Column width={3}><b>{t('general.email')}</b></Grid.Column>
                    <Grid.Column width={13}>{user.emailAddress}</Grid.Column>
                </Grid.Row>

                {/* Previous login */}
                <Grid.Row>
                    <Grid.Column width={3}><b>{t('general.previousLogin')}</b></Grid.Column>
                    <Grid.Column width={13}>{
                        user.previousLogin && user.previousLogin !== "1.1.1"
                            ? new Date(user.previousLogin).toLocaleDateString()
                            : ""
                        }
                    </Grid.Column>
                </Grid.Row>

                {/* Is Admin user */}
                <Grid.Row>
                    <Grid.Column width={3}><b>{t('general.admin')}</b></Grid.Column>
                    <Grid.Column width={13}>{user.isAdminUser ? t('general.yes') : t('general.no')}</Grid.Column>
                </Grid.Row>

                {/* Created */}
                <Grid.Row>
                    <Grid.Column width={3}><b>{t('general.created')}</b></Grid.Column>
                    <Grid.Column width={13}>{user.created ? new Date(user.created).toLocaleDateString() : ""}</Grid.Column>
                </Grid.Row>
            
                {/* Created By */}
                <Grid.Row>
                    <Grid.Column width={3}><b>{t('general.createdBy')}</b></Grid.Column>
                    <Grid.Column width={13}>{creatorName}</Grid.Column>
                </Grid.Row>

                {/* Modified */}
                <Grid.Row>
                    <Grid.Column width={3}><b>{t('general.modified')}</b></Grid.Column>
                    <Grid.Column width={13}>
                        {user.modified ? new Date(user.modified).toLocaleDateString() : ""}
                    </Grid.Column>
                </Grid.Row>

                {/* Modified By */}
                <Grid.Row>
                    <Grid.Column width={3}><b>{t('general.modifiedBy')}</b></Grid.Column>
                    <Grid.Column width={13}>{modifierName}</Grid.Column>
                </Grid.Row>

            </Grid>

            <Container textAlign="right">
                <SubmitButton icon="send" hidden={userIsViewingOwnProfile} color="green" title={t('users.invitation')} onClick={() => onInviteUser() }/>
                <EditButton icon="edit" color="green" title={t('general.edit')} url={"/users/edit/" + id} />                   
                <DeleteButton icon="delete" hidden={userIsViewingOwnProfile} onClick={() => setDeleteModalOpen(true)} color="red" title={t('general.delete')} />
                <CancelButton />
            </Container>

        </Form>
    );

}

export default ReadUser;
