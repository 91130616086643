import React, { useState, useEffect, Fragment } from 'react';
import { useHistory, Link } from "react-router-dom";
import { Table, Pagination, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { OpenButton, EditButton, DeleteButton } from './../Buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinusCircle } from '@fortawesome/pro-solid-svg-icons';
import Utils from '../../../Utils/utils';
import './PagedTable.css'

/**
 * Combination of Semantic UI Table and Pagination
 * @param {array} header - Translated strings of header cells
 * @param {array} content - Table content as array of objects 
 * @param {array} contentKeys - Array of objects containing keys, actions and type of content fields rendered to table
 * @param {string} tableprops - Semantic UI table properties passed to component
 * @example
 * <PagedTable unstackable celled striped compact='very' pageSize={20}
 *   header={[
 *       t('general.name'),
 *       t('general.firstName'),
 *       t('general.admin'),
 *       t('general.previousLogin')
 *   ]}
 *   content={users}
 *   contentKeys={[
 *       {key: 'firstName', key2: 'lastName', target: '/users/', type: 'linkWithTwoKeys', targetKey: 'id'},
 *       {key: 'firstName' },
 *       {key: 'isAdminUser', type: 'boolean' },
 *       {key: 'previousLogin', type: 'date'}
 *   ]}
 * />
 */
function PagedTable({header, content, contentKeys, pageSize = 10, resetPage, collapsable = false, showPagingOnTop, ...tableprops}) {

    const { t } = useTranslation();

    const [ activePage, setActivePage ] = useState(0);
    const [ sortBy, setSortBy ] = useState({key: null, asc: true, type: 'string'});

    const [ sortedContent, setSortedContent ] = useState(content);

    const start = activePage * pageSize;
    const end = start + pageSize;
    const pagedContent = sortedContent.slice(start, end);

    function sortTableData(contentKey) {
        if (contentKey?.type !== 'linkButton' && content.length > 1) {
            setSortBy({
                key: contentKey?.key, 
                asc: sortBy.key === contentKey?.key ? !sortBy.asc : true,
                type: contentKey?.sortType || 
                    (contentKey?.type === 'date' && 'date') || 
                    'string'
            })
        }
    }

    useEffect(() => {
        const sortContent = (content) => {
            switch(sortBy.type) {
                case 'int':
                    return Utils.sortByPropertyAsInt(content, sortBy.key, sortBy.asc)
                case 'date':
                    return Utils.sortByDate(content, sortBy.key, sortBy.asc)
                default:
                    return Utils.sortByProperty(content, sortBy.key, sortBy.asc)
            }    
        }  
        setSortedContent(sortBy.key ? sortContent(content) : content);
    }, [sortBy, content])

    useEffect(() => {
        setActivePage(0)
    }, [resetPage])

    return (
        <>
            {content.length > pageSize && showPagingOnTop &&
                <div className='pagination-container top'>
                    <Pagination 
                        defaultActivePage={activePage + 1} 
                        totalPages={Math.ceil(content.length / pageSize)} 
                        firstItem={null}
                        lastItem={null}
                        size='small'
                        ellipsisItem={window.innerWidth > 767 ? undefined : null}
                        onPageChange={(e, {activePage}) => setActivePage(activePage - 1)}
                    /> 
                </div>
            }
            <div className='table-wrapper'>
                <Table {...tableprops}>
                    <Table.Header>
                        <Table.Row>
                            {collapsable &&
                                <Table.HeaderCell />
                            }
                            { header && header.map((item, i) =>
                                <Fragment key={i}>
                                    {!contentKeys[i]?.hideCollapsed &&
                                        <Table.HeaderCell 
                                            className='sortable-table'
                                            width={contentKeys[i].cellWidth}
                                            onClick={() => sortTableData(contentKeys[i])}
                                        >
                                            {item}
                                            {sortBy.key === contentKeys[i].key && sortBy.asc && 
                                                <Icon name='caret up' />
                                            }
                                            {sortBy.key === contentKeys[i].key && !sortBy.asc && 
                                                <Icon name='caret down' />
                                            }
                                        </Table.HeaderCell>
                                    }
                                </Fragment>
                            )} 
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {pagedContent.length === 0 && 
                            <tr>
                                <td>
                                    <p>{t('general.itemsNotFound')}</p>
                                </td>
                            </tr>
                        }
                        {pagedContent.map((row, i) => 
                            <PagedTableRow 
                                key={i}
                                header={header} 
                                row={row} 
                                contentKeys={contentKeys} 
                                collapsable={collapsable} 
                            />
                        )}
                    </Table.Body>
                </Table>
            </div>
            {content.length > pageSize && 
                <div className='pagination-container'>
                    <Pagination 
                        defaultActivePage={activePage + 1} 
                        totalPages={Math.ceil(content.length / pageSize)} 
                        firstItem={null}
                        lastItem={null}
                        size='small'
                        ellipsisItem={window.innerWidth > 767 ? undefined : null}
                        onPageChange={(e, {activePage}) => setActivePage(activePage - 1)}
                    /> 
                </div>
            }
        </>
    )
}

/** CellKeys:
 *      cellWidth:          Column width
 *
 *      key:                Displayed object key (Name, Id, Phone...)
 *      type:               Type of the cell (link, date, linkButton...)
 *      target:             Target url ("/contact/view/")
 *      targetKey:          Target url parameter key (Id, FileName...) this is added end of the url/target ("/contact/view/{Id}")
 *      action:             Function
 *      cellAlign:          Cell text align (center)
 *      hideCollapsed:      Hide cell if collapsed? (true / false)
 *
 */

function PagedTableRow({header, row, contentKeys, collapsable}) {

    const history = useHistory();
    const { t } = useTranslation();

    const [ collapsed, setCollapsed ] = useState(collapsable ? true : false)

    const cellContent = (row, cellKey) => {
        switch(cellKey.type) {
            case 'link':
                return <Link to={`${cellKey.target}${row[cellKey.targetKey]}`}>{row[cellKey.key]}</Link>;
            case 'proceedingLink':
                return <Link to={`${row[cellKey.target]}${row[cellKey.targetKey]}`}>{row[cellKey.key]}</Link>;
            case 'externalLink':
                return <a href={`${cellKey.target}${row[cellKey.targetKey]}`} target='_blank' rel="noopener noreferrer">{row[cellKey.key]}</a>;                
            case 'linkButton':
                return <OpenButton onClick={() => history.push(`${cellKey.target}${row[cellKey.targetKey]}`)} />;
            case 'date':
                    return row[cellKey.key]
                    ? new Date(row[cellKey.key]).toLocaleDateString('fi-FI')
                    :  ""
            case 'boolean':
                return row[cellKey.key] ? t('general.yes') : t('general.no');
            case 'action':
                return <Link to='#' onClick={ (ev) => { ev.preventDefault(); cellKey.action(ev, row, cellKey); } } >{row[cellKey.key]}</Link>
            case 'twokeys':
                return `${row[cellKey.key]} ${row[cellKey.key2] ? row[cellKey.key2] : ''}`
            case 'linkWithTwoKeys':
                return <Link to={`${cellKey.target}${row[cellKey.targetKey]}`}>{row[cellKey.key]} {row[cellKey.key2]}</Link>;
            case 'management':
                return  <div className="management">
                            {row.showOpenButton === true && <OpenButton labelPosition={'left'} title={t('general.show')} size={'small'} onClick={() => history.push(`${row['viewTarget']}${row['Id']}`)} />}
                            {row.showEditButton === true && <EditButton onClick={() => history.push(`${row['editTarget']}${row['Id']}`)} /> }
                            {row.showDeleteButton === true && <DeleteButton onClick={ (ev) => { ev.preventDefault(); row['deleteAction'](row['Id']) } } />}
                        </div>
            default: 
                return row[cellKey.key];
        }
    }

    return (
        <Fragment>
            {!collapsable || collapsed ?
                <Table.Row 
                    className={collapsable && collapsed ? 'collapsable collapsed' : ''}
                >
                    {collapsable &&
                        <Table.Cell onClick={() => setCollapsed(!collapsed)}>
                            <FontAwesomeIcon icon={faPlusCircle} />
                        </Table.Cell>
                    }
                    {contentKeys.map((cellKey, n) =>
                        <Fragment key={n}> 
                            {!cellKey.hideCollapsed &&
                                <Table.Cell textAlign={cellKey.cellAlign}>
                                    {cellContent(row, cellKey)}
                                </Table.Cell>
                            }
                        </Fragment>     
                    )}  
                </Table.Row>
                :
                <Table.Row 
                    className={collapsable && !collapsed ? 'collapsable open' : ''}
                >
                     {collapsable &&
                        <Table.Cell onClick={() => setCollapsed(!collapsed)}>
                            <FontAwesomeIcon icon={faMinusCircle} />
                        </Table.Cell>
                    }
                    <Table.Cell colSpan={contentKeys.length}>
                    {contentKeys.map((cellKey, i) =>
                        <Fragment key={i}>
                            <div>
                                <span className='header'>
                                    {`${header[i]}: `}
                                </span>
                                {cellContent(row, cellKey)}
                            </div>
                        </Fragment>     
                    )}
                    </Table.Cell>  
                </Table.Row>
            }
        </Fragment>
    )
}

export default PagedTable;
