import React, { createContext, useEffect, useState } from 'react'; 
import { Message, Icon } from 'semantic-ui-react'
import { v4 as uuidv4 } from 'uuid';
import { useLocation } from "react-router-dom";

const InfoMessage = (props) => {
    const onDismiss = () => {
        if (props && props.onDismiss) props.onDismiss();
    }
    return (
        <Message size="tiny" info onDismiss={() => onDismiss()} style={{ marginTop: '5px', marginBottom: '5px' }}>
            <Message.Content>
                <Message.Header>
                    <Icon name='circle notched' loading />
                    {props.header}
                </Message.Header>
                {props.content}
            </Message.Content>
        </Message>
    )
}

const PositiveMessage = (props) => {
    const onDismiss = () => {
        if (props && props.onDismiss) props.onDismiss();
    }
    return (
        <Message size="tiny" positive onDismiss={() => onDismiss()} style={{ marginTop: '5px', marginBottom: '5px' }}>
            <Message.Content>
                <Message.Header>
                    <Icon name='circle notched' />
                    {props.header}</Message.Header>
                {props.content}
                <br/>
                {props.optional}
            </Message.Content>
        </Message>
    )
}

const WarningMessage = (props) => {
    const onDismiss = () => {
        if (props && props.onDismiss) props.onDismiss();
    }
    return (
        <Message size="tiny" warning onDismiss={() => onDismiss()} style={{ marginTop: '5px', marginBottom: '5px' }}>
            <Message.Content>
                <Message.Header>
                    <Icon name='exclamation' />    
                    {props.header}
                </Message.Header>
                {props.content}
                <br/>
                {props.optional}
            </Message.Content>
        </Message>
    )
}

const ErrorMessage = (props) => {
    const onDismiss = () => {
        if (props && props.onDismiss) props.onDismiss();
    }
    return (
        <Message size="tiny" negative onDismiss={() => onDismiss()} style={{ marginTop: '5px', marginBottom: '5px' }}>
            <Message.Content>
                <Message.Header>
                    <Icon name='exclamation triangle'/>
                    {props.header}
                </Message.Header>
                {props.content}
                <br/>
                {props.optional}
            </Message.Content>
        </Message>
    )
}

const FetchingMessage = (props) => {
    const onDismiss = () => {
        if (props && props.onDismiss) props.onDismiss();
    }
    return (
        <Message size="tiny" positive onDismiss={() => onDismiss()} style={{ marginTop: '5px', marginBottom: '5px' }}>
            <Message.Content>
                <Message.Header>
                    <Icon name='circle notched' loading />
                    {props.header}
                </Message.Header>
                {props.content}
            </Message.Content>
        </Message>
    )
}

const SavingMessage = (props) => {
    const onDismiss = () => {
        if (props && props.onDismiss) props.onDismiss();
    }
    return (
        <Message size="tiny" info icon onDismiss={() => onDismiss()} style={{ marginTop: '5px', marginBottom: '5px' }}>
            <Message.Content>
                <Message.Header>
                    <Icon name='check' />
                    {props.header}
                </Message.Header>
                {props.content}
            </Message.Content>
        </Message>
    )
}

export const MessageContext = createContext();

/** Message provider for the application
 *  Add all message properties and methods here. */
const MessageContextProvider = ({children}) => {

    let location = useLocation();

    // { header: "test", body: "body test", id: "erererererw4yb4", type: "error"}
    const [ messages, setMessages ] = useState([]);

    // Remove message from the messages array (dont remove errors automatically!)
    const removeMessageAfterTimeout = (id) => {
        return setTimeout(() => {
            setMessages(messages => {
                return messages.filter(msg => {
                    if (msg.type === 'error') return true;
                    if (msg.id === id) return false;
                    return true;
                });
            });
        }, 5000);
    }

    /** Function adds message to the container
     * @description type, header and body are all mandatory!!!
     * @param {object || array} msg - Message object or array of objects
     * @example
     *    Array:  [{type:"", header:"", body:""},{type:"", header:"", body:""}]
     *    Object: {type:"", header:"", body:""} */
    const addMessage = (msg) => {

        const header = msg && msg.header ? msg.header : " ";
        const body = msg && msg.body ? msg.body : " ";
        
        // Message is array of objects 
        if (Array.isArray(msg)) {

            return setMessages(messages => {
                const clone = [...messages]; //cloneArray(messages);
                msg.forEach(item => {
                    item.id = uuidv4();
                    clone.push(item);
                    removeMessageAfterTimeout(item.id);
                });
                return clone;
            });

        // Message is object 
        } else if (msg && msg.type && header && body) {

                msg.id = uuidv4();
                setMessages(messages => {
                    const clone = [...messages]; //cloneArray(messages);
                    clone.push(msg);
                    removeMessageAfterTimeout(msg.id);
                    return clone;
                });
                return msg.id;
        
        // Error
        } else {
            throw new Error("Message is not and object or array!");
        }
    }

    /* Function removes message from the container */
    const removeMessage = (id) => {
        setMessages(messages => {
            return messages.filter(item => {
                return item.id !== id; 
             });
        });
    }

    const getMessages = () => {


        const msg = messages ? messages.map(msg => {
    
            let selectedType = null;
    
            switch (msg.type) {
                case "success":
                    selectedType = <PositiveMessage key={msg.id} header={msg.header} content={msg.body} option={msg.option} onDismiss={() => removeMessage(msg.id)} />
                    break;
                case "warning":
                    selectedType = <WarningMessage key={msg.id} header={msg.header} content={msg.body} option={msg.option} onDismiss={() => removeMessage(msg.id)} />
                    break;
                case "error":
                    selectedType = <ErrorMessage key={msg.id} header={msg.header} content={msg.body} option={msg.option} onDismiss={() => removeMessage(msg.id)} />
                    break;
                case "saving":
                    selectedType = <SavingMessage key={msg.id} header={msg.header} content={msg.body} option={msg.option} onDismiss={() => removeMessage(msg.id)} />
                    break;
                case "loading":
                    selectedType = <FetchingMessage key={msg.id} header={msg.header} content={msg.body} option={msg.option} onDismiss={() => removeMessage(msg.id)} />
                    break;
                default:
                    selectedType = <InfoMessage key={msg.id} header={msg.header} content={msg.body} option={msg.option} onDismiss={() => removeMessage(msg.id)} />
                    break;
            }
            
            return selectedType;
    
        }) : [];
    
        return msg;

    }

    // Remove all errors automatically when location change
    useEffect(() => {
        setMessages(messages => {
            return messages.filter(item => {
                return item.type !== 'error'; 
            });
        });
    }, [location]);

    /* Add messages for developing purposes
    useEffect(() => {
        addMessage({ header: "test", body: "body test", id: "erererererw4yb4", type: "warning"});
        addMessage({ header: "test", body: "body test", id: "erererererw4yb4", type: "error"});
        addMessage({ header: "test", body: "body test", id: "erererererw4yb4", type: "error"});
        addMessage({ header: "test", body: "body test", id: "erererererw4yb4", type: "info"});
        addMessage({ header: "test", body: "body test", id: "erererererw4yb4", type: "success"});
    }, []); */

    return (
        <MessageContext.Provider value={{ messages, addMessage, removeMessage, getMessages }}>
            {children}
        </MessageContext.Provider>
    )
}

export default MessageContextProvider;