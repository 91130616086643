import { useContext, useState } from 'react';
import { AuthContext } from '../../Context/AuthContext';
import useRequestHandler from './../useRequestHandler';

const useListLocations = () => {

    const { acquireTokenSilently, apiClient } = useContext(AuthContext);
    const [ locations, setLocations ] = useState([]);
    const { errorHandler, successHandler } = useRequestHandler();
    
    const listLocations = async () => {
        try {

            const accessToken = await acquireTokenSilently("listLocations");

            if (accessToken === "login_required") throw Error("login_required");

            const result = await apiClient.list(accessToken, "company/locations");
            
            const data = result.data || {};
            const locations = data.locations || null;

            if (locations) setLocations(locations);

            return successHandler(result, "useListLocations", "list", { hide: true });

        } catch (error) {

            const options = {
                "hide": "accessToken" && "accessToken" === "login_required" ? true: false
            }

            return errorHandler(error, "useListLocations", "list", options);

        }
    }

    return { locations, listLocations };

}

export default useListLocations;
