import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Container, Checkbox, Message } from 'semantic-ui-react';
import { OpenButton } from '../../../Reusable/Buttons';
import useReadCompanyManagementSystems from './../../../../Hooks/Company/useReadCompanyManagementSystems';
import { Loading, PageTitle } from './../../../Reusable/Elements';
//import { StateContext } from './../../../../Context/StateContext';
import { AuthContext } from './../../../../Context/AuthContext';

const ReadManagementSystems = (props) => {

    const { t } = useTranslation();

    const { managementSystems, readCompanyManagementSystems } = useReadCompanyManagementSystems();
    //const { globalState } = useContext(StateContext);
    const [ isLoading, setIsLoading ] = useState(true);
    const { userProfile } = useContext(AuthContext);

    // Fetch company management systems
    useEffect(() => {
        (async () => {
            setIsLoading(true);
            await readCompanyManagementSystems();
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const formIsFilled = managementSystems && managementSystems.id ? true : false;

    return (
        <Form className="managementsystems">
            
            <Loading active={ isLoading  } text={t('general.loading')} />

            <PageTitle title={t('managementsystems.title')} hideInfo/>

            {/* Buttons */}
            <Container textAlign="right">
                <OpenButton hidden={!userProfile || !userProfile.isAdminUser} icon="edit" color="green" title={t('general.edit')} url="/managementsystems/edit" />
                <OpenButton icon="arrow left" title={t('general.frontpage')} url="/" />
            </Container>
            
            <h2>{t('managementsystems.labourProtectionCollaboration')}</h2>

            {!isLoading && !formIsFilled &&
                <Message negative header={t('managementsystems.formIsNotFilledTitle')} content={t('managementsystems.formIsNotFilledContent')} />
            }

            <Form.Field label={t('managementsystems.labourProtectionOfficer')}/>
            <p>{formIsFilled ? managementSystems.labourProtectionOfficer : ""}</p><br/>

            <Form.Field label={t('managementsystems.labourProtectionRepresentative')} />
            <p>{formIsFilled ? managementSystems.labourProtectionRepresentative : ""}</p><br/>

            <h2>{t('managementsystems.occupationalSafetyAndHealthPolicyDiscussed')}</h2>
            <p>{t('managementsystems.discussedAtCollaborationMeeting')}</p>
            <p>{
                formIsFilled && managementSystems && managementSystems.occupationalSafetyAndHealthPolicyDiscussed
                ? new Date(managementSystems.occupationalSafetyAndHealthPolicyDiscussed).toLocaleDateString()
                : "-"}
            </p><br/>
            
            <h2>{t('managementsystems.occupationalHealthServicesDiscussed')}</h2>
            <p>{t('managementsystems.discussedAtCollaborationMeeting')}</p>
            <p>{
                formIsFilled && managementSystems && managementSystems.occupationalHealthServicesDiscussed
                ? new Date(managementSystems.occupationalHealthServicesDiscussed).toLocaleDateString()
                : "-"
            }</p><br/>
            
            <h2>{t('managementsystems.occupationalHealthServicesContractDiscussed')}</h2>
            <p>{t('managementsystems.discussedAtCollaborationMeeting')}</p>
            <p>{
                formIsFilled && managementSystems && managementSystems.occupationalHealthServicesContractDiscussed
                ? new Date(managementSystems.occupationalHealthServicesContractDiscussed).toLocaleDateString()
                : "-"
            }</p><br/>
            
            <h2>{t('managementsystems.occupationalHealthServicesWorkplaceSurveyDiscussed')}</h2>
            <p>{t('managementsystems.discussedAtCollaborationMeeting')}</p>
            <p>{
                formIsFilled && managementSystems && managementSystems.occupationalHealthServicesWorkplaceSurveyDiscussed
                ? new Date(managementSystems.occupationalHealthServicesWorkplaceSurveyDiscussed).toLocaleDateString()
                : "-"
            }</p><br/>
            
            <h2>{t('managementsystems.drugAndAlcoholAbusePreventionPlanDiscussed')}</h2>
            <p>{t('managementsystems.discussedAtCollaborationMeeting')}</p>
            <p>{
                formIsFilled && managementSystems && managementSystems.drugAndAlcoholAbusePreventionPlanDiscussed
                ? new Date(managementSystems.drugAndAlcoholAbusePreventionPlanDiscussed).toLocaleDateString()
                : "-"
            }</p><br/>
            
            <h2>{t('managementsystems.earlyInterventionDiscussed')}</h2>
            <p>{t('managementsystems.discussedAtCollaborationMeeting')}</p>
            <p>{
                formIsFilled && managementSystems && managementSystems.earlyInterventionDiscussed
                ? new Date(managementSystems.earlyInterventionDiscussed).toLocaleDateString()
                : "-"
            }</p><br/>
            
            <h2>{t('managementsystems.familiarisedAndEmergencyPlanHaveBeenMade')}</h2>
            <p>{t('managementsystems.latestUpdate')}</p>
            <p>{
                formIsFilled && managementSystems && managementSystems.familiarisedAndEmergencyPlanHaveBeenMade
                ? new Date(managementSystems.familiarisedAndEmergencyPlanHaveBeenMade).toLocaleDateString()
                : "-"
            }</p><br/>
            
            <h2>{t('managementsystems.informationToIdentifyDangerousChemicals')}</h2>
            <div className="chemicals-container">
                <Form.Field
                    disabled
                    control={Checkbox}
                    label={t('managementsystems.listOfDangerousChemicalsExists')}
                    //placeholder={'Checkbox'}
                    checked={managementSystems.listOfDangerousChemicalsExists || false}
                    //onChange={(ev, {checked}) => setCheckBox(checked) }
                /><br/>

                <Form.Field
                    disabled
                    control={Checkbox}
                    label={t('managementsystems.dangerousChemicalPackagesAreMarkedOnReceive')}
                    //placeholder={'Checkbox'}
                    checked={managementSystems.dangerousChemicalPackagesAreMarkedOnReceive || false}
                    //onChange={(ev, {checked}) => setCheckBox(checked) }
                /><br/>

                <Form.Field
                    disabled
                    control={Checkbox}
                    label={t('managementsystems.materialSafetyDataSheetsAreUpToDate')}
                    //placeholder={'Checkbox'}
                    checked={managementSystems.materialSafetyDataSheetsAreUpToDate || false}
                    //onChange={(ev, {checked}) => setCheckBox(checked) }
                />
            </div><br/>

            <h2>{t('managementsystems.workWhichRequiresCertificateOfCompetency')}</h2>
            <p>{formIsFilled ? managementSystems.workWhichRequiresCertificateOfCompetency : ""}</p><br/>

            {/* Buttons */}
            <Container textAlign="right">
                <OpenButton hidden={!userProfile || !userProfile.isAdminUser} icon="edit" color="green" title={t('general.edit')} url="/managementsystems/edit" />
                <OpenButton icon="arrow left" title={t('general.frontpage')} url="/" />
            </Container>

        </Form>
    );

}

export default ReadManagementSystems;
