import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../Context/AuthContext';
import useRequestHandler from './../useRequestHandler';

const useSendInvitation = () => {

    const { t } = useTranslation();
    const { acquireTokenSilently, apiClient } = useContext(AuthContext);
    const { errorHandler, successHandler } = useRequestHandler();
    const [ userDoesNotExistError, setUserDoesNotExistError ] = useState(false);

    /** Send invitation to user
     * @param {string} emailAddress - user email address */
    const sendInvitation = async (emailAddress) => {
        try {

            const accessToken = await acquireTokenSilently();

            if (accessToken === "login_required") throw Error("login_required");

            const result = await apiClient.create(accessToken, "users/invitation", { "emailAddress": emailAddress });

            return successHandler(result, "useSendInvitation", "invitation", { message: t('success.userInvitedSuccesfully') });

        } catch (error) {

            const status = error.status || null;
            const data = error.data || {};
            const errorCode = data.errorCode || 0;

            if (status === 400 && errorCode === -1) setUserDoesNotExistError(true);

            return errorHandler(error, "useSendInvitation", "invitation");
        }
    }

    return { userDoesNotExistError, setUserDoesNotExistError, sendInvitation };

}

export default useSendInvitation;
