import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from './../../Reusable/Elements';
import { OpenButton } from './../../Reusable/Buttons';
import { Container, List } from 'semantic-ui-react';
import useListLocations from '../../../Hooks/Locations/useListLocations';
import useReadUserProfile from '../../../Hooks/Profile/useReadUserProfile';
import { WarningMessage } from '../../Reusable/Messages';

const Landing = (props) => {

    const { t } = useTranslation();
    const { locations, listLocations } = useListLocations();
    const { userProfile, readUserProfile } = useReadUserProfile();

    // Fetch locations
    useEffect(() => {
        (async () => {
            await listLocations();
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Read user profile
    useEffect(() => {
        (async() => {
            await readUserProfile();
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="landing">

            <PageTitle title={t('navigation.landing')} href="/info" />

            {locations.length===0 && userProfile.isAdminUser===true &&
                <WarningMessage header={t('landing.startAddingLocations')} content={t('landing.addLocationsFromManagement')}/>
            }

            <div className="description" >
                <b>{t('landing.tool')}</b>
                <p>{t('landing.toolDescription')}</p>
            </div>

            <div className="description" >
                <List className='landing-list'>

                    {/*<Icon className='blue' name='info circle' />*/}
                    <List.Item>
                        <List.Content>Tutustu <a href='/info' target='_blank'>ohjeisiin</a></List.Content>
                    </List.Item>

                    {userProfile.isAdminUser===true &&
                        <>
                        {/*<Icon className='blue' name='info circle' />*/}
                        <List.Item>
                            <List.Content>Lisää arvioinnin kohteet ja käyttäjät <a href='/management'>Hallinta-sivulla</a></List.Content>
                        </List.Item>
                        {/*<Icon className='blue' name='info circle' />*/}
                        <List.Item>
                            <List.Content>Päivitä <a href='/managementsystems/view'>työsuojelun tilannekuva</a></List.Content>
                        </List.Item>
                        </>
                    }

                    <List.Item>
                        <List.Content>Sovellus päivitetty <a href='/releases'>7.3.2023</a></List.Content>
                    </List.Item>

                    {/*<Icon className='blue' name='info circle' />*/}
                    <List.Item>
                        <List.Content>Aloita kohteen arviointi</List.Content>
                    </List.Item>

                </List>
            </div>

            <Container textAlign="center">
                {locations && locations.length>0 ?
                    <OpenButton url="/inspection" title={t('evaluation.new')} align="center" size="large" color='green' icon="add" />
                :
                    <>
                        {userProfile.isAdminUser===false ?
                            <p>{t('landing.noLocationToInspect')}</p>
                        :
                            <p>{t('landing.startAddingLocations')}</p>
                        }
                        <OpenButton url="/inspection" title={t('evaluation.new')} align="center" size="large" color='green' icon="add" disabled={true} />
                    </>
                }
            </Container>


        </div>
    );

}

export default Landing;
