import React from 'react';
import { Header, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { YesButton, NoButton, ApproveButton } from './Buttons';

/** Open modal and ask Yes/No question
 * @param {string} type - type of the modal [delete, save, calcel, warning, ready]
 * @param {string} header - Header text
 * @param {string} text - Body text
 * @param {*} method - method which is called whern user clicks "YES".
 *                     (if call is needed also on "NO" click, set noClick to true)
 *                     Called method must accept two params method(answer <boolean>, item <any>);
 * @param {*} item - OPTIONAL item which is passed as method parameter
 * @param {any} open - React useState "open" -value
 * @param {function} setOpen - React useState "setOpen" -function
 * @param {boolean} noClick - Set to true if method should be called when user clicks "NO"
*/
const YesNoModal = ({ type, header, text, method, item, open, setOpen, noClick }) => {

    const { t } = useTranslation();

    return (
        <Modal
            closeIcon
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
        >
            {type==='delete' &&
                <Header icon='delete' content={header || t('general.delete')} />
            }
            {type==='save' &&
                <Header icon='save' content={header || t('general.save')} />
            }
            {type==='cancel' &&
                <Header icon='cancel' content={header || t('general.cancel')} />
            }
            {type==='warning' &&
                <Header icon='warning' content={header || t('general.warning')} />
            }
            {type==='ready' &&
                <Header icon='question' content={header || t('general.ready')} />
            }

            <Modal.Content>
                {text || t('general.areYouSure')}
            </Modal.Content>

            <Modal.Actions>
                <NoButton onClick={() => {
                    setOpen(false);
                    if (method && noClick) item ? method(false, item) : method(false);
                    
                }}/>
                <YesButton onClick={() => {
                    setOpen(false);
                    if (method) item ? method(true, item) : method(true);
                }}/>
            </Modal.Actions>

        </Modal>
    );
}

const ApproveModal = ({ type, header, text, open, setOpen }) => {

    const { t } = useTranslation();

    return (
        <Modal
            closeIcon
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
        >
            {type==='info' &&
                <Header icon='info' content={header || t('general.info')} />
            }
            {type==='warning' &&
                <Header icon='warning' content={header || t('general.warning')} />
            }

            <Modal.Content>
                <p>{text || t('general.areYouSure')}</p>
            </Modal.Content>
            
            <Modal.Actions>
                <ApproveButton onClick={() => {
                    setOpen(false);
                }}/>
            </Modal.Actions>

        </Modal>
    );
}

export { YesNoModal, ApproveModal };