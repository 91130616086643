import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Container, Grid } from 'semantic-ui-react';
import { OpenButton } from '../../Reusable/Buttons';
import useReadUserProfile from '../../../Hooks/Profile/useReadUserProfile';
import { Loading, PageTitle } from '../../Reusable/Elements';

const ReadUserProfile = (props) => {

    const { t } = useTranslation();
    const { userProfile, readUserProfile } = useReadUserProfile();
    const [ isLoading, setIsLoading ] = useState(true);

    // Read user profile
    useEffect(() => {
        (async() => {
            await readUserProfile();
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Form className="profile" >
            
            <Loading active={isLoading} text={t('general.loading')} />

            {/* Title */}
            <PageTitle title={t('profile.title')} hideInfo/>

            <div className="section">

                <Grid stackable>

                    {/* First Name */}
                    <Grid.Row>
                        <Grid.Column width={3}><b>{t('general.firstName')}</b></Grid.Column>
                        <Grid.Column width={13}>{userProfile.firstName}</Grid.Column>
                    </Grid.Row>

                    {/* Last Name */}
                    <Grid.Row>
                        <Grid.Column width={3}><b>{t('general.lastName')}</b></Grid.Column>
                        <Grid.Column width={13}>{userProfile.lastName}</Grid.Column>
                    </Grid.Row>

                    {/* Username / email */}
                    <Grid.Row>
                        <Grid.Column width={3}><b>{t('general.username')}</b></Grid.Column>
                        <Grid.Column width={13}>{userProfile.emailAddress}</Grid.Column>
                    </Grid.Row>

                    {/* User is admin */}
                    <Grid.Row>
                        <Grid.Column width={3}><b>{t('general.admin')}</b></Grid.Column>
                        <Grid.Column width={13}>{userProfile.isAdminUser ? t('general.yes') : t('general.no')}</Grid.Column>
                    </Grid.Row>

                </Grid>

            </div>

            {/* Buttons */}
            <Container textAlign="right">
                <OpenButton icon="pencil" color="green" title={t('general.edit')} url="/profile/edit" />
                <OpenButton icon="lock" color="green" title={t('general.changePassword')} url="/profile/changepassword" />
                <OpenButton icon="arrow left" floated={"right"} title={t('general.frontpage')} url="/" />

            </Container>
            
        </Form>
    );

}

export default ReadUserProfile;
