import { useContext, useState } from 'react';
import { AuthContext } from '../../Context/AuthContext';
import useRequestHandler from '../useRequestHandler';

const useReadCompanyManagementSystems = () => {

    const { acquireTokenSilently, apiClient } = useContext(AuthContext);
    const { errorHandler, successHandler } = useRequestHandler();
    const [ managementSystems, setManagementSystems ] = useState({});

    /** Read company management systems form */
    const readCompanyManagementSystems = async () => {
        try {
            setManagementSystems({});

            const accessToken = await acquireTokenSilently();

            if (accessToken === "login_required") throw Error("login_required");

            const result = await apiClient.read(accessToken, "management/systems");

            const data = result.data || null;
            const companyManagementSystem = data.companyManagementSystem || null;
            if (companyManagementSystem) setManagementSystems(companyManagementSystem);

            return successHandler(result, "useReadCompanyManagementSystems", "read", { hide: true });

        } catch (error) {

            // Hide errors (Company management form row is not found until it has been updated once)
            return errorHandler(error, "useReadCompanyManagementSystems", "read", { hide: true });

        }
    }

    return { managementSystems, readCompanyManagementSystems };

}

export default useReadCompanyManagementSystems;
