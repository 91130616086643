import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from './../../Context/AuthContext';
import useRequestHandler from './../useRequestHandler';

const useSetNewPassword = () => {

    const { t } = useTranslation();
    const { apiClient } = useContext(AuthContext);    
    const { errorHandler, successHandler } = useRequestHandler();

    /** Set new password for the user
     * @param {object} password - object discribing password
     * @example
     * {
     *     "guid": "",
     *     "password": ""
     * }
    */
    const setPassword = async (password) => {
        try {

            const result = await apiClient.create(null, "users/setnewpassword", password);

            return successHandler(result, "useSetNewPassword", "update", { message: t('success.passwordSetSuccesfully') });

        } catch (error) {
            return errorHandler(error, "useSetNewPassword", "update");
        }
    }

    return { setPassword };

}

export default useSetNewPassword;
