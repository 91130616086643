import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Form, Input, Container, Message } from 'semantic-ui-react';
import { DataPrivacy, Loading, PageTitle } from './../../Reusable/Elements';
import { SubmitButton } from './../../Reusable/Buttons';
import useSetNewPassword from './../../../Hooks/Authentication/useSetNewPassword';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const SetNewPassword = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    let query = useQuery();
    const { setPassword } = useSetNewPassword();
    const [ newPassword, setNewPassword ] = useState("");
    const [ retypeNewPassword, setRetypeNewPassword ] = useState("");
    const [ passwordError, setPasswordError ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);

    const onSubmit = async () => {
        try {

            const id = query.get("id");

            if (newPassword !== retypeNewPassword) return setPasswordError(true);
            if (newPassword === retypeNewPassword) setPasswordError(false);

            const pw = {
                guid: id,
                newPassword: newPassword
            }

            const result = await setPassword(pw);

            if (result.status === 200) return history.push("/login");

            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
        }
    }

    return (
        <Form className="authenticate" onSubmit={onSubmit} error={passwordError} autoComplete="off">
            
            <Loading active={isLoading} text={t('general.loading')} />

            {/* Title */}
            <PageTitle title={t('setnewpassword.title')} hideInfo/>

            {/* Password */}
            <Form.Field
                required
                minLength="8"
                maxLength="255"
                autoComplete="off"
                control={Input}
                label={t('general.password')}
                value={newPassword}
                onChange={(ev, {value}) => setNewPassword(value) }
                type="Password"
            />

             {/* Retype password */}
             <Form.Field
                required
                minLength="8"
                maxLength="255"
                autoComplete="off"
                control={Input}
                label={t('profile.retypePassword')}
                value={retypeNewPassword}
                onChange={(ev, {value}) => setRetypeNewPassword(value) }
                type="Password"
            />

            {/* Password mismatch error message  */}
            {passwordError && 
                <Message error header={t('general.error')} content={t('errors.passwordMismatch')} />
            }

             {/* Buttons */}
            <Container textAlign="right">
                <SubmitButton icon="save" color="green" title={t('general.save')} />
            </Container>
            <hr/>

             {/* DataPrivacy */}
            <DataPrivacy className="green" />

        </Form>
    );

}

export default SetNewPassword;
