import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../Context/AuthContext';
import useRequestHandler from './../useRequestHandler';

const useDeleteUser = () => {

    const { t } = useTranslation();
    const { acquireTokenSilently, apiClient } = useContext(AuthContext);
    const { errorHandler, successHandler } = useRequestHandler();
    const [ userDoesNotExistError, setUserDoesNotExistError ] = useState(false);

    /** Delete user 
     * @param {integer} id - user id
    */
    const deleteUser = async (id) => {
        try {

            const accessToken = await acquireTokenSilently();
            
            if (accessToken === "login_required") throw Error("login_required");

            const result = await apiClient.delete(accessToken, "users", id);

            return successHandler(result, "useDeleteUser", "delete", { message: t('success.userDeletedSuccesfully') });

        } catch (error) {

            const status = error.status || null;
            const data = error.data || {};
            const errorCode = data.errorCode || 0;

            if (status === 400 && errorCode === -1) setUserDoesNotExistError(true);

            return errorHandler(error, "useDeleteUser", "delete");

        }
    }

    return { userDoesNotExistError, setUserDoesNotExistError, deleteUser };

}

export default useDeleteUser;
