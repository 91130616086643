import React from 'react';
import { useTranslation } from 'react-i18next';
import { OpenButton } from './../../Reusable/Buttons';
import { Container } from 'semantic-ui-react';
import { PageTitle } from '../../Reusable/Elements';

const Log = ({version, date, description, changes, features, bugs}) => {

    const { t } = useTranslation();

    return (
        <Container>
            
            <h3>{t('releases.title') + " " + version}</h3>

            <Container style={{ paddingLeft: "20px", marginBottom: "20px" }}>
                <b>{t('releases.info')}</b>
                <ul>
                    {description && <li>{t('releases.description')}</li>}
                    {date &&  <li>{t('releases.date') + ": " + date}</li>}
                </ul>
            </Container>

            {changes && changes.length > 0 && 
                <Container style={{ paddingLeft: "20px" }}>
                    <b>{t('releases.changes')}</b>
                    <ul>
                        {changes.map((item, index) => <li key={index}>{item}</li>)}
                    </ul>
                </Container>
            }

            {features && features.length > 0 && 
                <Container style={{ paddingLeft: "20px" }}>
                    <b>{t('releases.features')}</b>
                    <ul>
                        {features.map((item, index) => <li key={index}>{item}</li>)}
                    </ul>
                </Container>
            }

            {bugs && bugs.length > 0 && 
                <Container style={{ paddingLeft: "20px" }}>
                    <b>{t('releases.bugs')}</b>
                    <ul>
                        {bugs.map((item, index) => <li key={index}>{item}</li>)}
                    </ul>
                </Container>
            }

            <hr/>

        </Container>
    );
}

const Releases = (props) => {

    const { t } = useTranslation();

    return (
        <div className="releases">

            <PageTitle title={t('navigation.releases')} hideInfo/>

            {/* Buttons */}
            <Container textAlign="right">
                <OpenButton icon="arrow left" floated={"right"} title={t('general.frontpage')} url="/" />
            </Container>

            {/* Add new versions allways on the top! */}

            <Log version="1.1.1"
                date="7.3.2023"
                changes={[]}
                features={[]}
                bugs={[
                    "Korjattu: Päivämääräkenttien muotoilu aiheutti virheen, jos käyttäjän alue oli muu kuin Suomi."
                ]}
            />

            <Log version="1.1.0"
                date="6.6.2022"
                changes={[
                    "Muutettu: riskien arvioinnin tallenna napin teksti ja kuvake.",
                    "Lisätty: kohteen arviointiin ohjeteksti kaikille sivuille.",
                    "Poistettu: riskien arvoinnista toimenpiteiden merkkimäärän rajoitus."
                ]}
                features={[
                    "Lisätty: luettelo muutoksista, ominaisuuksista ja korjauksista versioittain.",
                    "Lisätty: riskien arviointiin riskilukujen selitteet."
                ]}
                bugs={[
                    "Korjattu: riskiarvion aikataulun päivämäärä kenttään voi syöttää tekstiä."
                ]}
            />

        </div>
    );

}

export default Releases;
