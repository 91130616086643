import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Grid } from 'semantic-ui-react';
import { AuthContext } from './../../../Context/AuthContext';
import useReadCompany from './../../../Hooks/Company/useReadCompany';
import useListUsers from './../../../Hooks/Users/useListUsers';
import useListLocations from './../../../Hooks/Locations/useListLocations';
import PagedTable from './../../Reusable/Lists/PagedTable';
import Forbidden from './../../Pages/Forbidden';
import { Loading, PageTitle, Info } from './../../Reusable/Elements';
import { OpenButton } from './../../Reusable/Buttons';

const Company = ({company}) => {

    const { t } = useTranslation();

    return (
        <div className="company section">

            <h2>{t('company.title')}</h2>

            <Grid stackable>
                <Grid.Row>
                    <Grid.Column width={3}><b>{t('general.name')}</b></Grid.Column>
                    <Grid.Column width={13}>{company.name}</Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={3}><b>{t('general.lineOfBusiness')}</b></Grid.Column>
                    <Grid.Column width={13}>{company.lineOfBusinessDescription}</Grid.Column>
                </Grid.Row>
            </Grid>

            <OpenButton icon="edit" color="green" title={t('general.edit')} url="/company/edit" />

        </div>
    );
}

const Users = ({ users }) => {

    const { t } = useTranslation();

    return (
        <div className="users section tables">

            <h2>
                {t('users.title')}
                <OpenButton floated="right" icon="add" color="green" noTitle circular url="/users/new" />
            </h2>

            <PagedTable unstackable celled striped compact='very' pageSize={10}
                header={[
                    //t('general.name'),
                    t('general.lastName'),
                    t('general.firstName'),
                    t('general.email'),
                    t('general.admin'),
                    t('general.previousLogin')
                ]}
                content={users || []}
                contentKeys={[
                    //{key: 'firstName', key2: 'lastName', target: '/users/view/', type: 'linkWithTwoKeys', targetKey: 'id'},
                    {key: 'lastName', target: '/users/view/', type: 'link', targetKey: 'id'},
                    {key: 'firstName' },
                    {key: 'emailAddress' },
                    {key: 'isAdminUser', type: 'boolean' },
                    {key: 'previousLogin', type: 'date'}
                ]}
            />

        </div>
    );
}

const Locations = ({locations}) => {

    const { t } = useTranslation();

    return (
        <div className="locations section tables">

            <h2>
                {t('locations.title')}
                <OpenButton floated="right" icon="add" color="green" noTitle circular url="/locations/new" />
            </h2>

            <PagedTable unstackable celled striped compact='very' pageSize={10}
                header={[
                    t('general.name'),
                    t('general.responsiblePerson'),
                    t('general.description')                
                ]}
                content={locations || []}
                contentKeys={[
                    {key: 'name', target: '/locations/view/', type: 'link', targetKey: 'id'},
                    {key: 'responsiblePerson'},
                    {key: 'description' }
                ]}
            />

        </div>
    );
}

const Management = (props) => {

    const { t } = useTranslation();

    const { userProfile } = useContext(AuthContext);
    const { company, readCompany } = useReadCompany();
    const { users, listUsers } = useListUsers();
    const { locations, listLocations } = useListLocations();

    const [ isLoadingCompany, setIsLoadingCompany ] = useState(true);
    const [ isLoadingUsers, setIsLoadingUsers ] = useState(true);
    const [ isLoadingLocations, setIsLoadingLocations ] = useState(true);

    // Fetch company
    useEffect(() => {
        (async () => {
            await readCompany();
            setIsLoadingCompany(false);
        })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Fetch Users
    useEffect(() => {
        (async () => {
                await listUsers();
                setIsLoadingUsers(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Fetch locations
    useEffect(() => {
        (async () => {
            await listLocations();
            setIsLoadingLocations(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let manipulatedLocations = null;
    manipulatedLocations = locations.map(item => {
        item.responsiblePerson = item.users[0] ? item.users[0].lastName + ' ' + item.users[0].firstName : '';
        return item;
    });

    if (!userProfile || !userProfile.isAdminUser) return <Forbidden />;

    return (
        <Form className="management">

            <Loading active={ isLoadingCompany || isLoadingUsers || isLoadingLocations } text={t('general.loading')} />

            <PageTitle title={t('management.title')} hideInfo />

            <Info text={t('management.help.addUsersAndLocations')} />

            <Company company={company} />

            <Users users={users} />

            <Locations locations={manipulatedLocations} />

            <OpenButton icon="arrow left" floated={"right"} title={t('general.frontpage')} url="/" />

        </Form>

    );

}

export default Management;
