import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from './../../Context/AuthContext';
import useRequestHandler from './../useRequestHandler';

const useRequestNewPassword = () => {

    const { t } = useTranslation();
    const { apiClient } = useContext(AuthContext);
    const { errorHandler, successHandler } = useRequestHandler();

    /** request new password - email is sent to the users email
     * @param {string} username - username */
    const requestPassword = async (username) => {
        try {

            const result = await apiClient.create(null, "authentication/requestnewpassword", { "emailAddress": username });

            return successHandler(result, "useRequestNewPassword", "request", { message: t('success.passwordRequestEmailSend')});

        } catch (error) {
            return errorHandler(error, "useRequestNewPassword", "request", { "message": t('errors.userAccountDoesNotExist')});
        }
    }

    return { requestPassword };

}

export default useRequestNewPassword;
