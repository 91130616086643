import { useContext, useState } from 'react';
import { AuthContext } from '../../Context/AuthContext';
import useRequestHandler from '../useRequestHandler';
import { v4 as uuidv4 } from 'uuid';

const useListInspectionEvaluations = () => {

    const { acquireTokenSilently, apiClient } = useContext(AuthContext);
    const [ inspectionEvaluations, setInspectionEvaluations ] = useState([]);
    const { errorHandler, successHandler } = useRequestHandler();
    
    /** List inspection evaluations by inspection id*/
    const listInspectionEvaluations = async (inspectionId) => {
        try {

            setInspectionEvaluations([]);

            const accessToken = await acquireTokenSilently();

            if (accessToken === "login_required") throw Error("login_required");

            const result = await apiClient.list(accessToken, "inspections/" + inspectionId + "/evaluations");

            const data = result.data || null;
            const evaluations = data.evaluations || null;

            if (evaluations) {
                // Add guid which is used in front end when editing item
                const mappedEvaluations = evaluations.map(item => {
                    item.schedule = item.schedule ? new Date(item.schedule) : null;
                    item.uuid = uuidv4();
                    return item;
                });
                setInspectionEvaluations(mappedEvaluations);
            }

            return successHandler(result, "useListInspectionEvaluations", "list", { hide: true });

        } catch (error) {
            return errorHandler(error, "useListInspectionEvaluations", "list");
        }
    }

    return { inspectionEvaluations, setInspectionEvaluations, listInspectionEvaluations };

}

export default useListInspectionEvaluations;
