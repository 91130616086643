import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './../Layout/Routes';
import { MobileNavigation, Header, Content, Footer, Messages } from './../Layout/Layout';
import AuthContextProvider from './../../Context/AuthContext';
import MessageContextProvider from './../../Context/MessageContext';
import StateContextProvider from './../../Context/StateContext';

//import AppContextProvider from './../../Context/AppContext';
import 'semantic-ui-css/semantic.min.css';
import './App.css';

const App = (props) => {

    const [ mobileMenuOpen, setMobileMenuOpen ] = useState(false);
    const [ showManagementSystemReminder, setShowManagementSystemReminder ] = useState(false);

    return (
        <Router>
            <MessageContextProvider>
                <AuthContextProvider>
                    <StateContextProvider>
                        <div className="App">
                            <Header mobileMenuOpen={mobileMenuOpen} setMobileMenuOpen={setMobileMenuOpen} showManagementSystemReminder={showManagementSystemReminder} setShowManagementSystemReminder={setShowManagementSystemReminder} />
                            <Messages />
                            <MobileNavigation mobileMenuOpen={mobileMenuOpen} setMobileMenuOpen={setMobileMenuOpen} showManagementSystemReminder={showManagementSystemReminder}>
                                <Content>
                                    <Routes/>
                                </Content>
                                <Footer/>
                            </MobileNavigation>
                        </div>
                    </StateContextProvider>
                </AuthContextProvider>
            </MessageContextProvider>
        </Router>
    );

}

export default App;
