/** Key/Val storage library */

/** Save key and value to selected storage
 * @example
 *  import keyValueStorage from './keyValueStorage';
 *  const x = keyValueStorage();
 *  x.setStorageType("inmemory");
 *  x.setValue("abc", 123);
 *  x.getValue("abc");
 *  x.deleteKey("abc"); */
const keyValueStorage = (initialValue) => {

    // Properties
    let selectedStorage = initialValue || 'inmemory';
    let inMemoryStorageValue = null;

    /** Method set cookie
     * @param {string} key - cookie key
     * @param {string} value - cookie value
     * @param {string} exp - Optional expiration date in UTF / GMT format [new Date().toUTCString();]
     * @param {string} path - Optional cookie path f.e. "/" or "/user"
     * @param {string} domain - Optional cookie domain */
     const setCookie = (key, value, exp, path, domain) => {

        if (!key) throw Error("Key is mandatory");
        if (!value) throw Error("Value is mandatory");

        let cookie = key +"=" + value + ";";

        if (exp) cookie += " expires=" + exp + ";";
        if (path) cookie += " path=" + path + ";";
        if (domain) cookie += " domain=" + domain + ";";

        document.cookie(cookie);
        return true;

    }

    /** Method read all cookies *
    const getCookies = () => {
        return document.cookie;
    }*/

    /** Method read cookie by name */
    const getCookie = (name) => {
        if (!name) throw Error("Name is mandatory");
        const nameString = name + "="

        const value = document.cookie.split(";").filter(item => {
          return item.includes(nameString)
        })
      
        if (value.length) {
          return value[0].substring(nameString.length, value[0].length)
        } else {
          return ""
        }
    }

    /** Method delete cookie
    * @param {string} key - cookie key */
    const deleteCookie = (key) => {

        if (!key) throw Error("Key is mandatory");

        const cookie = key + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC;"

        document.cookie(cookie);

        return true;

    }

    /** Method set selected storage type
     * @description possible values are "inmemory, localStorage, sessionStorage, cookie". Default value is "inmemory".
     * @param {string} storage - used storage type */
    const setStorageType = (storage) => {

        // Set selected key/value storage
        if (storage === "inmemory") selectedStorage = "inmemory";
        if (storage === "localStorage") selectedStorage = "localStorage";
        if (storage === "sessionStorage") selectedStorage = "sessionStorage";
        if (storage === "cookie") selectedStorage = "cookie";

        // Set default
        selectedStorage = "inmemory";

    }

    /** Method return value from key/value storage
     * @param {string} key - key for the returned value */
    const getValue = (key) => {
        if (selectedStorage === "inmemory") return inMemoryStorageValue;
        if (selectedStorage === "localStorage") return localStorage.getItem(key);
        if (selectedStorage === "sessionStorage") return sessionStorage.getItem(key);
        if (selectedStorage === "cookie") return getCookie(key);
    };

    /** Method set value for the given key
     * @param {string} key - name of the stored item
     * @param {string} value - value of the stored item */
    const setValue = (key, value) => {

        if (!key) throw new Error("Key is mandatory");
        if (!value) throw new Error("Value is mandatory");
        if (typeof key !== "string") throw new Error("Key is not string");
        if (typeof value !== "string") throw new Error("Value is not string");

        // Set value for the key
        if (selectedStorage === "inmemory") {
            inMemoryStorageValue = value;
            return true;
        }

        if (selectedStorage === "localStorage") {
            localStorage.setItem(key, value);
            return true;
        }

        if (selectedStorage === "sessionStorage")  {
            sessionStorage.setItem(key, value);
            return true;
        }

        if (selectedStorage === "cookie") {
            const todayMs = new Date().getTime();
            const weekMs = 604800000; // 7 * 24 * 60 * 1000 One Week / 7 days
            const expMs = todayMs + weekMs;
            const exp = new Date(expMs);
            setCookie(key, value, exp, "/");
            return true;
        }

    }

    /** Method deletes value
     * @param {string} key - name of the stored value */
    const deleteValue = (key) => {

        // Delete refresh token
        if (selectedStorage === "inmemory") {
            inMemoryStorageValue = null;
            return true;
        }

        if (selectedStorage === "localStorage") {
            localStorage.removeItem(key);
            return true;
        }

        if (selectedStorage === "sessionStorage") {
            sessionStorage.removeItem(key);
            return true;
        }

        if (selectedStorage === "cookie") {
            return deleteCookie(key);
        }

    }

    // Public properties and methods
    return {
        setStorageType,
        getValue,
        setValue,
        deleteValue
    }

}

export default keyValueStorage;
