import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../Context/AuthContext';
import useRequestHandler from './../useRequestHandler';

const useCreateLocation = () => {

    const { t } = useTranslation();
    const { acquireTokenSilently, apiClient } = useContext(AuthContext);
    const { errorHandler, successHandler } = useRequestHandler();

    /** Create location
     * @param {object} location - object discribing the location
     * @example
     * {
     *     "name": "",
     *     "description": "",
     *     "locationUsers": [1]
     * }
    */
    const createLocation = async (location) => {
        try {

            const accessToken = await acquireTokenSilently();
            
            if (accessToken === "login_required") throw Error("login_required");

            const result = await apiClient.create(accessToken, "locations", location);

            return successHandler(result, "useCreateLocation", "create", { message: t('success.locationCreatedSuccesfully') });

        } catch (error) {
            
            return errorHandler(error, "useCreateLocation", "create");

        }
    }

    return { createLocation };

}

export default useCreateLocation;
