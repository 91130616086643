import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../Context/AuthContext';
import useRequestHandler from './../useRequestHandler';

const useUpdateUser = () => {

    const { t } = useTranslation();
    const { acquireTokenSilently, apiClient } = useContext(AuthContext);
    const { errorHandler, successHandler } = useRequestHandler();
    const [ userDoesNotExistError, setUserDoesNotExistError ] = useState(false);

    /** Update user
     * @param {object} user - object discribing the user
     * @example
     * {
     *     "id": 1,
     *     "firstName": "",
     *     "lastName": "",
     *     "emailAddress": "",
     *     "isAdminUser": true,
     *     "language": ""
     * }
    */
    const updateUser = async (user) => {
        try {

            const accessToken = await acquireTokenSilently();

            if (accessToken === "login_required") throw Error("login_required");

            const result = await apiClient.update(accessToken, "users", user);

            return successHandler(result, "useUpdateUser", "update", { message: t('success.userUpdatedSuccesfully') });

        } catch (error) {

            const status = error.status || null;
            const data = error.data || {};
            const errorCode = data.errorCode || 0;

            if (status === 400 && errorCode === -1) setUserDoesNotExistError(true);

            return errorHandler(error, "useUpdateUser", "update");
        }
    }

    return { userDoesNotExistError, setUserDoesNotExistError, updateUser };

}

export default useUpdateUser;
