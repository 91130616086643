import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Register from '../Pages/Register/Register';
import ConfirmRegistration from '../Pages/Register/ConfirmRegistration';
import Login from '../Pages/Authentication/Login';
import RequestNewPassword from '../Pages/Authentication/RequestNewPassword';
import SetNewPassword from '../Pages/Authentication/SetNewPassword';
import Management from '../Pages/Management/Management';
import ReadManagementSystems from '../Pages/Management/Company/ReadManagementSystems';
import UpdateManagementSystems from '../Pages/Management/Company/UpdateManagementSystems';
import UpdateCompany from '../Pages/Management/Company/UpdateCompany'; 
import CreateUser from '../Pages/Management/Users/CreateUser';
import ReadUser from '../Pages/Management/Users/ReadUser';
import UpdateUser from '../Pages/Management/Users/UpdateUser';
import ApproveInvitation from '../Pages/Management/Users/ApproveInvitation';
import CreateLocation from '../Pages/Management/Location/CreateLocation';
import ReadLocation from '../Pages/Management/Location/ReadLocation';
import UpdateLocation from '../Pages/Management/Location/UpdateLocation';
import ReadUserProfile from '../Pages/Profile/ReadUserProfile';
import UpdateProfile from '../Pages/Profile/UpdateProfile';
import ChangeProfilePassword from '../Pages/Profile/ChangeProfilePassword';
import Landing from '../Pages/Application/Landing';
import Releases from './../Pages/Application/Releases';
import Info from './../Pages/Application/info';
import Inspection from '../Pages/Inspection/Inspection';
import Report from '../Pages/Report/Report';
import EditRiskFactorEvaluation from './../Pages/Report/EditRiskFactorEvaluation';
import PageNotFound from './../Pages/PageNotFound';

const Routes = (props) => {
    return (
        <Switch>

            {/* App root */}
            <Route path='/' component={Landing} exact />

            {/* Registration */}
            <Route path='/register' component={Register} />
            <Route path='/confirmregistration' component={ConfirmRegistration} />

            {/* Login and reset pw */}
            <Route path='/login' component={Login} />
            <Route path='/requestnewpassword' component={RequestNewPassword} />
            <Route path='/setnewpassword' component={SetNewPassword} />
            <Route path='/approveinvitation' component={ApproveInvitation} />

            {/* Management */}
            <Route path='/management' component={Management} />
            <Route path='/managementsystems/view' component={ReadManagementSystems} />
            <Route path='/managementsystems/edit' component={UpdateManagementSystems} />
            <Route path='/company/edit' component={UpdateCompany} />
            <Route path='/users/new' component={CreateUser} />
            <Route path='/users/view/:id' component={ReadUser} />
            <Route path='/users/edit/:id' component={UpdateUser} />

            {/* Profile */}
            <Route path='/profile/view' component={ReadUserProfile} />
            <Route path='/profile/edit' component={UpdateProfile} />
            <Route path='/profile/changepassword' component={ChangeProfilePassword} />

            {/* App */}           
            <Route path='/releases' component={Releases} />
            <Route path='/info' component={Info} />           
            <Route path='/inspection' component={Inspection} />

            {/*<Route path='/locations' component={<p>Locations</p>} />*/}
            <Route path='/locations/new' component={CreateLocation} />
            <Route path='/locations/view/:id' component={ReadLocation} />
            <Route path='/locations/edit/:id' component={UpdateLocation} />

            {/* Report */}
            <Route path='/reports/' component={Report} />

            {/* Evaluations */}
            <Route path='/evaluations/:id' component={EditRiskFactorEvaluation} />   

            {/* Page Not Found */}
            <Route component={PageNotFound} />

        </Switch>
    );

}

export default Routes;
