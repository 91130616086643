import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Form, Input, Container } from 'semantic-ui-react';
import { SubmitButton, CancelButton } from './../../Reusable/Buttons';
import { Loading, PageTitle } from './../../Reusable/Elements';
import useReadUserProfile from './../../../Hooks/Profile/useReadUserProfile';
import useUpdateProfile from './../../../Hooks/Profile/useUpdateProfile';

const UpdateProfile = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    
    const { readUserProfile } = useReadUserProfile();
    const { updateProfile } = useUpdateProfile();

    const [ isLoading, setIsLoading ] = useState(true);
    const [ id, setId ] = useState(null);
    const [ firstName, setFirstName ] = useState("");
    const [ lastName, setLastName ] = useState("");
    const [ email, setEmail ] = useState("");

    // Read user profile and set state
    useEffect(() => {
        (async() => {
            const result = await readUserProfile();
            if (result && result.status === 200 && result.data) {
                setId(result.data.user.id);
                setFirstName(result.data.user.firstName);
                setLastName(result.data.user.lastName);
                setEmail(result.data.user.emailAddress);
            }
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Save updated user
    const onSubmit = async () => {
        try {

            const profile = {
                "id": id,
                "firstName": firstName,
                "lastName": lastName,
                "emailAddress": email
            }

            const result = await updateProfile(profile);

            if (result.status === 200) return history.goBack();

            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
        }

    }

    return (
        <Form className="profile" onSubmit={onSubmit}>

            <Loading active={isLoading} text={t('general.loading')} />

            {/* Title */}
            <PageTitle title={t('profile.title')} hideInfo/>

            {/* First name */}
            <Form.Field
                required
                maxLength="255"
                control={Input}
                label={t('general.firstName')}
                value={firstName}
                onChange={(ev, {value}) => setFirstName(value) }
                type="Text"
            />

            {/* Last name */}
            <Form.Field
                required
                maxLength="255"
                control={Input}
                label={t('general.lastName')}
                value={lastName}
                onChange={(ev, {value}) => setLastName(value) }
                type="Text"
            />

            {/* Email */}
            <Form.Field
                required
                maxLength="255"
                control={Input}
                label={t('registration.email')}
                value={email}
                onChange={(ev, {value}) => setEmail(value) }
                type="Text"
            />

            {/* Buttons */}
            <Container textAlign="right">
                <SubmitButton icon="save" color="green" title={t('general.save')} />
                <CancelButton />
            </Container>

        </Form>
    );


}

export default UpdateProfile;
