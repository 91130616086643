import React from 'react';
import { CSVLink } from "react-csv";
//import { Parser } from "json2csv";
import { CreateCSVButton } from './Buttons';

/** Generate CSV from data array
 * @docs 
 *  https://www.npmjs.com/package/react-csv
 *  https://www.npmjs.com/package/json2csv
 * */
 const GenerateCSVButton = ({headers, data, title, filename}) => {
    
    /*
        let csvData = [];
        if (data && data.length) {
            const json2csvParser = new Parser();
            csvData = json2csvParser.parse(data);
        }
    */

    if (!data || !data.length) return <CreateCSVButton disabled title={title} />

    return (
        <CSVLink 
            className="ui green small icon left labeled button"
            headers={headers}
            data={data}
            separator={";"}
            filename={filename || "export.csv"}
            //target="_blank"
        >
            <i aria-hidden="true" className="file outline icon"></i>
            {title}
        </CSVLink>
    );
}

export {
    GenerateCSVButton
};