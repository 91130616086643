import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams  } from 'react-router-dom';
import { Form, Container, Grid } from 'semantic-ui-react';
import { DeleteButton, CancelButton, EditButton } from '../../../Reusable/Buttons';
import useReadLocation from '../../../../Hooks/Locations/useReadLocation';
import useDeleteLocation from '../../../../Hooks/Locations/useDeleteLocation';
import useReadUser from '../../../../Hooks/Users/useReadUser';
import { AuthContext } from './../../../../Context/AuthContext';
import Forbidden from './../../../Pages/Forbidden';
import { Loading, PageTitle } from '../../../Reusable/Elements';
import { YesNoModal } from '../../../Reusable/Modals';

const ReadLocation = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const { id } = useParams();

    const { location, readLocation } = useReadLocation();
    const { deleteLocation } = useDeleteLocation();
    const { readUser } = useReadUser();
    const { userProfile } = useContext(AuthContext);

    const [ creator, setCreator ] = useState("");
    const [ modifier, setModifier ] = useState("");
    const [ personInCharge, setPersonInCharge ] = useState("");

    const [ isLoading, setIsLoading ] = useState(true);
    const [ isLoadingUsers, setIsLoadingUsers ] = useState(true);
    const [ deleteModalOpen, setDeleteModalOpen ] = useState(false);

    const onDeleteLocation = async () => {
        try {

            setIsLoading(true);

            const result = await deleteLocation(parseInt(id));
            
            if (result.status === 200 || result.status === 204) return history.goBack();

            setIsLoading(false);
            
        } catch (error) {
            setIsLoading(false);
        }
    }

    // 1.Fetch location
    useEffect(() => {
        (async () => {
            if (id) {
                await readLocation(id);
                setIsLoading(false);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    // 2.Fetch user data when location is fetched
    useEffect(() => {
        (async () => {
            if (location && location.users && location.users[0]) {

                const creator = await readUser(location.creator);
                const modifier = await readUser(location.modifier);
                const personInCharge = await readUser(location.users[0].id);

                if (creator.status === 200 && creator.data && creator.data.user && creator.data.user.id) setCreator(creator.data.user);
                if (modifier.status === 200 && modifier.data && modifier.data.user && modifier.data.user.id) setModifier(modifier.data.user);
                if (personInCharge.status === 200 && personInCharge.data && personInCharge.data.user && personInCharge.data.user.id) setPersonInCharge(personInCharge.data.user);
                
                setIsLoadingUsers(false);
            } else if(location && location.users && !location.users[0]) {
                setIsLoadingUsers(false);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);
    
    const creatorName = creator.firstName ? creator.firstName + " " + creator.lastName : "";
    const modifierName = modifier.firstName ? modifier.firstName + " " + modifier.lastName : "";
    const personInChargeName = personInCharge && personInCharge.firstName ? personInCharge.firstName + " " + personInCharge.lastName : "";

    if (!userProfile || !userProfile.isAdminUser) return <Forbidden />;

    return (
        <Form className="locations" >
            
            <Loading active={isLoading || isLoadingUsers} text={t('general.loading')} />

            {/* Delete modal */}
            <YesNoModal type="delete"
                header={t('general.delete')}
                text={t('locations.areYouSureYouWantToDeleteLocation')}
                method={onDeleteLocation}
                item={id}
                open={deleteModalOpen}
                setOpen={setDeleteModalOpen}
            />

            {/* Title */}
            <PageTitle title={t('locations.location')} hideInfo />

            <Grid stackable>

                {/* Name */}
                <Grid.Row>
                    <Grid.Column width={3}><b>{t('general.name')}</b></Grid.Column>
                    <Grid.Column width={13}>{location.name}</Grid.Column>
                </Grid.Row>

                {/* Description */}
                <Grid.Row>
                    <Grid.Column width={3}><b>{t('general.description')}</b></Grid.Column>
                    <Grid.Column width={13}>{location.description}</Grid.Column>
                </Grid.Row>

                {/* Person In Charge */}
                <Grid.Row>
                    <Grid.Column width={3}><b>{t('locations.personInCharge')}</b></Grid.Column>
                    <Grid.Column width={13}>{personInChargeName}</Grid.Column>
                </Grid.Row>

                {/* Created */}
                <Grid.Row>
                    <Grid.Column width={3}><b>{t('general.created')}</b></Grid.Column>
                    <Grid.Column width={13}>{location.created ? new Date(location.created).toLocaleDateString() : ""}</Grid.Column>
                </Grid.Row>
            
                {/* Created By */}
                <Grid.Row>
                    <Grid.Column width={3}><b>{t('general.createdBy')}</b></Grid.Column>
                    <Grid.Column width={13}>{creatorName}</Grid.Column>
                </Grid.Row>

                {/* Modified */}
                <Grid.Row>
                    <Grid.Column width={3}><b>{t('general.modified')}</b></Grid.Column>
                    <Grid.Column width={13}>
                        {location.modified ? new Date(location.modified).toLocaleDateString() : ""}
                    </Grid.Column>
                </Grid.Row>

                {/* Modified By */}
                <Grid.Row>
                    <Grid.Column width={3}><b>{t('general.modifiedBy')}</b></Grid.Column>
                    <Grid.Column width={13}>{modifierName}</Grid.Column>
                </Grid.Row>

            </Grid>

            {/* Buttons */}
            <Container textAlign="right">
                <EditButton icon="edit" color="green" title={t('general.edit')} url={"/locations/edit/" + id} />
                <DeleteButton icon="delete" onClick={() => setDeleteModalOpen(true)} color="red" title={t('general.delete')} />
                <CancelButton />
            </Container>

        </Form>
    );

}

export default ReadLocation;
