import { useContext, useState } from 'react';
import { AuthContext } from '../../Context/AuthContext';
import useRequestHandler from './../useRequestHandler';

const useReadCompany = () => {

    const { acquireTokenSilently, apiClient } = useContext(AuthContext);
    const { errorHandler, successHandler } = useRequestHandler();
    const [ company, setCompany ] = useState({});

    /** Read company */
    const readCompany = async () => {
        try {

            const accessToken = await acquireTokenSilently();

            if (accessToken === "login_required") throw Error("login_required");

            const result = await apiClient.read(accessToken, "company");

            const data = result.data || null;
            const company = data.company || null;

            if (company) setCompany(company);

            return successHandler(result, "useReadCompany", "read", { hide: true });

        } catch (error) {
            return errorHandler(error, "useReadCompany", "read");
        }
    }

    return { company, readCompany };

}

export default useReadCompany;
