
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Form, Input, Container, Message } from 'semantic-ui-react';
import { SubmitButton, CancelButton } from './../../Reusable/Buttons';
import { Loading, PageTitle } from './../../Reusable/Elements';
import useChangeProfilePassword from './../../../Hooks/Profile/useChangeProfilePassword';
import Utils from './../../../Utils/utils';

const ChangeProfilePassword = (props) => {

    const { t } = useTranslation();
    const history = useHistory();

    const { newPasswordMustBeDifferent, setNewPasswordMustBeDifferent, changeProfilePassword } = useChangeProfilePassword();

    const [ currentPassword, setCurrentPassword ] = useState("");
    const [ newPassword, setNewPassword ] = useState("");
    const [ retypePassword, setRetypePassword ] = useState("");
    const [ isLoading, setIsLoading ] = useState(false);
    const [ passwordError, setPasswordError ] = useState(false);
    const [ passwordNotValidError, setPasswordNotValidError ] = useState(false);

    const onSubmit = async () => {
        try {

            // Validate password
            let isValidPassword = Utils.isValidPassword(newPassword, { minLength: 8, uppercase: true, lowercase: true, number: false });
            if (!isValidPassword) return setPasswordNotValidError(true);
            if (!currentPassword) return;
            if (!newPassword) return;
            if (newPassword !== retypePassword) return setPasswordError(true);

            setIsLoading(true);

            const pw = {
                "currentPassword": currentPassword,
                "newPassword": newPassword
            };

            const result = await changeProfilePassword(pw);

            if (result.status === 200) return history.goBack();

            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
        }
    }

    return (
        <Form className="profile" onSubmit={onSubmit} error={passwordError || passwordNotValidError || newPasswordMustBeDifferent} autoComplete="off">

            <Loading active={isLoading} text={t('general.loading')} />

            {/* Title */}
            <PageTitle title={t('profile.changepassword')} hideInfo />

            {/* Current Password */}
            <Form.Field
                required
                minLength="8"
                maxLength="255"
                autoComplete="off"
                control={Input}
                label={t('profile.currentPassword')}
                value={currentPassword}
                onChange={(ev, {value}) => {
                    setCurrentPassword(value);
                }}
                type="Password"
            />

            {/* New Password */}
            <Form.Field
                required
                minLength="8"
                maxLength="255"
                autoComplete="off"
                control={Input}
                label={t('profile.newPassword')}
                value={newPassword}
                onChange={(ev, {value}) => {
                    setNewPassword(value);
                }}
                type="Password"
            />
            
            {/* Retype password */}
            <Form.Field
                required
                minLength="8"
                maxLength="255"
                autoComplete="off"
                control={Input}
                label={t('profile.retypePassword')}
                value={retypePassword}
                onChange={(ev, {value}) => {
                    setRetypePassword(value);
                    setNewPasswordMustBeDifferent(false);
                    setPasswordError(false);
                    setPasswordNotValidError(false);
                }}
                type="Password"
                error={ 
                        passwordError ?
                            { 
                                content: t('errors.passwordMismatch'),
                                pointing: 'below'
                            }
                        : 
                        passwordNotValidError ?
                            {
                                content: t('errors.passwordMustContainUppercaseAndLowerCaseLetters'),
                                pointing: "below"
                            }
                        : false
                       
                    }
            />

            {/* Password mismatch error message  */}
            {passwordError && 
                <Message error header={t('general.error')} content={t('errors.passwordMismatch')} />
            }

            {newPasswordMustBeDifferent && 
                <Message error content={t('errors.passwordMustBeDifferentFromTheCurrentPassword')} />
            }            

            {/* Buttons */}
            <Container textAlign="right">
                <SubmitButton icon="save" color="green" title={t('general.save')} />
                <CancelButton />
            </Container>

        </Form>
    );

}

export default ChangeProfilePassword;
