import React, { useEffect, useContext } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { createMedia } from "@artsy/fresnel";
import { Container, Dimmer, Loader, Segment, Image, Menu, Sidebar, Divider, Icon, Dropdown  } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faBars } from '@fortawesome/pro-duotone-svg-icons';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../Context/AuthContext';
import { StateContext } from '../../Context/StateContext';
import { MessageContext } from '../../Context/MessageContext';
import { DataPrivacy } from '../Reusable/Elements';

const Logo = (props) => {

    const { globalState } = useContext(StateContext);

    // if user is not logged in, we dont want to let user to the front page.
    return (
        <>
            {globalState.isPublicPath && 
                <Image size='small' src='/images/logo.png' alt="logo" className="logo"/>
            }

            {!globalState.isPublicPath && 
                <NavLink className="navlink" to ='/' exact>
                    <Image size='small' src='/images/logo.png' alt="logo" className="logo"/>
                </NavLink>
            }
        </>
    );
}

/** Profile dropdown is visible on large screens, mobile navigation on mobile */
const ProfileDropDown = (props) => {

    const { t } = useTranslation();
    const history = useHistory();

    const { userProfile, logout } = useContext(AuthContext);

    const name = userProfile && userProfile.firstName && userProfile.lastName ? userProfile.firstName + " " + userProfile.lastName : null;

    return (
        <Menu.Menu position='right'>
            <Dropdown
                item
                direction='left'
                text={name || t('general.name')}
                icon={<FontAwesomeIcon icon={faUserCircle}/>}
                className="logged-user"
            >
                <Dropdown.Menu>
                    {/* Profile */}
                    <Dropdown.Item 
                        key='profile' 
                        text={t('general.profile')} 
                        icon='user circle'
                        onClick={() => history.push("/profile/view")}
                    />
                    {/* logout */}
                    <Dropdown.Item 
                        key='logout' 
                        text={t('general.logout')} 
                        icon='sign out'
                        onClick={() => logout()}
                    />
                </Dropdown.Menu>
            </Dropdown>
            <Dropdown
                item
                direction='left'
                text={t('general.actions')}
                icon={<FontAwesomeIcon icon={faBars}/>}
                className="menu-dropdown"
            >

                <Dropdown.Menu>

                    {/* Nav logo */}
                    {/*<Dropdown.Item onClick={() => history.push("/")} >
                        <Image size='small' src='/images/logo.png' alt="logo" className="logo"/>
                    </Dropdown.Item>*/}

                    {/* Username */}
                    {/*<Dropdown.Item 
                        key='mobile' 
                        text={name || t('general.name')}
                        icon='user'
                        disabled
                    />

                    <Dropdown.Divider/>*/}

                    {/* New inspection */}
                    <Dropdown.Item 
                        key='evaluation' 
                        text={t('evaluation.new')} 
                        icon='add'
                        onClick={() => history.push("/inspection")}
                    />

                    {/* Reports */}
                    <Dropdown.Item 
                        key='reports' 
                        text={t('navigation.reports')} 
                        icon='chart pie'
                        onClick={() => history.push("/reports")}
                    />

                    <Dropdown.Divider/>

                    {/* Management - Visible to admins only! */}
                    { userProfile && userProfile.isAdminUser &&
                        <Dropdown.Item 
                            key='management' 
                            text={t('navigation.management')} 
                            icon='cogs'
                            onClick={() => history.push("/management")}
                        />
                    }

                    {/* Management systems */}
                    <Dropdown.Item 
                        key='managementsystems' 
                        text={t('navigation.managementsystems')} 
                        icon='clipboard list'
                        onClick={() => history.push("/managementsystems/view")}
                    />

                    {/* Company management form message */}
                    { props.showManagementSystemReminder && userProfile && userProfile.isAdminUser &&
                        <Dropdown.Item>
                            <i className="icon exclamation"></i>
                            {t('general.admin')}: <br/>
                            <div style={{ whiteSpace: "normal", color: "red", padding: "10px 0 0 30px" }}>
                                {t('managementsystems.navigationMessage')}
                            </div>
                        </Dropdown.Item>
                    }

                    <Dropdown.Divider/>

                    <Dropdown.Item key='releases' text={t('navigation.releases')} icon='boxes'  onClick={() => history.push("/releases")} />

                    {/* Info */}                   
                    <NavLink to="/info" target="_blank">
                        <Dropdown.Item 
                            key='info' 
                            text={t('general.info')} 
                            icon='info'
                        />
                    </NavLink>

                    {/* DataPrivacy */}
                    <Dropdown.Item>
                        <DataPrivacy />
                    </Dropdown.Item>

                </Dropdown.Menu>
            </Dropdown>
        </Menu.Menu>
    );

}

const Navigation = ({ mobile, setMobileMenuOpen, showManagementSystemReminder }) => {

    const { t } = useTranslation();

    const { globalState } = useContext(StateContext);

    return (
        <div className="navigation">
            <Segment vertical>
                <Menu fixed='top' size='large'>
                    <Container fluid className={mobile ? 'mobile' : ''}>

                        {/* Desktop */}
                        { !mobile && 
                            <>
                                <Menu.Item header><Logo/></Menu.Item>
                                {!globalState.isPublicPath &&
                                    <>
                                        <Menu.Item>
                                            <NavLink className="navlink" to ='/' exact>{t('app.name')}</NavLink>
                                        </Menu.Item>
                                        <ProfileDropDown position='right' showManagementSystemReminder={showManagementSystemReminder}/>
                                    </>
                                }
                            </>
                        }

                        {/* Mobile */}
                        { mobile && 
                            <>
                                <Menu.Item header><Logo/></Menu.Item>
                                {!globalState.isPublicPath &&
                                    <>
                                        {/*<Menu.Item>{t('app.name')}</Menu.Item>*/}
                                        <Menu.Item onClick={() => setMobileMenuOpen(true)} position='right'><Icon name='sidebar' />{t('general.menu')}</Menu.Item>
                                    </>
                                }
                            </>
                        }

                    </Container>
                </Menu>
            </Segment>
        </div>
    );
}

const MobileNavigation = ({ children, mobileMenuOpen, setMobileMenuOpen, showManagementSystemReminder }) => {

    const { t } = useTranslation();
    const { userProfile, logout } = useContext(AuthContext);

    return (
        <div className="mobile-navigation">

            {/* Sticky side bar wont work if transform is not set to none !!! */}
            {/* But If style={{ transform: 'none' }} is added then the app wont work :( 
                These is a patch: add patch class to pusahble and sidebar and add css classes
                .pushable:not(body).patch { -webkit-transform: unset; transform: unset;} .pushable:not(body) .patch.ui.sidebar {position: fixed;}
                (popup visibility caused by this is fxed with z-index in div after body)
                https://topic.alibabacloud.com/a/how-to-use-the-fixed-menu-and-sidebar-of-semantic-ui-in-react_1_51_31205875.html
            */}
            <Sidebar.Pushable as={Segment} className='patch'>

                <Sidebar as={Menu} 
                    fixed='left'
                    animation='overlay'
                    className='patch borderless'
                    style={{ padding: "10px" }}
                    onHide={() => setMobileMenuOpen(false)}
                    vertical
                    visible={mobileMenuOpen}
                >

                    {/* Nav logo */}
                    {/*<Menu.Item header>
                        <NavLink className="navlink" to ='/' exact onClick={() => setMobileMenuOpen(false)}>
                            <Image size='small' src='/images/logo.png' alt="logo" className="logo"/>
                        </NavLink>
                    </Menu.Item>*/}

                    <Menu.Item>
                        <NavLink activeClassName="activeLink" className="navlink" to='/' exact onClick={() => setMobileMenuOpen(false)}>
                            <i className="icon home" />{t('navigation.landing')}
                        </NavLink>
                    </Menu.Item>

                    <Divider />

                    <Menu.Item>
                        <NavLink activeClassName="activeLink" className="navlink" to='/inspection' onClick={() => setMobileMenuOpen(false)}>
                            <i className="icon add" />{t('evaluation.new')}
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item>
                        <NavLink activeClassName="activeLink" className="navlink" to='/reports' onClick={() => setMobileMenuOpen(false)}>
                            <i className="icon pie chart" />{t('navigation.reports')}
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item>
                        <NavLink activeClassName="activeLink" className="navlink" to='/profile/view' onClick={() => setMobileMenuOpen(false)}>
                            <i className="icon user circle" />{t('navigation.profile')}
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item>
                        <NavLink activeClassName="activeLink" className="navlink" onClick={() => { setMobileMenuOpen(false); logout(); }} to='/logout'>
                            <i className="icon sign out" />{t('general.logout')}
                        </NavLink>
                    </Menu.Item>

                    <Divider />

                    {/* Visible to admins only! */}
                    {/*{ userProfile && userProfile.isAdminUser && }
                    <Menu.Item>
                        <NavLink activeClassName="activeLink" className="navlink" to='/management' onClick={() => setMobileMenuOpen(false)}>
                            <i className="icon cogs" />{t('navigation.management')}
                        </NavLink>
                    </Menu.Item>*/}

                    <Menu.Item>
                        <NavLink activeClassName="activeLink" className="navlink" to='/managementsystems/view' onClick={() => setMobileMenuOpen(false)}>
                            <i className="icon clipboard list" />{t('navigation.managementsystems')}
                        </NavLink>
                    </Menu.Item>

                    {/* Company management form message */}
                    {showManagementSystemReminder && userProfile && userProfile.isAdminUser &&
                        <Menu.Item>
                            <NavLink to='/managementsystems/view'  onClick={() => setMobileMenuOpen(false)}>
                                <span style={{ color: "red" }}>
                                    <i className="icon exclamation" />
                                    {t('general.admin') + ": "} 
                                    {t('managementsystems.navigationMessage')}
                                </span>
                            </NavLink>
                        </Menu.Item>
                    }

                    <Divider/>

                    <Menu.Item>
                        <NavLink target="_blank" activeClassName="activeLink" className="navlink" to='/info' onClick={() => setMobileMenuOpen(false)}>
                            <i className="icon info" />{t('general.info')}
                        </NavLink>
                    </Menu.Item>

                    <Menu.Item>
                        <DataPrivacy />
                    </Menu.Item>

                </Sidebar>

                <Sidebar.Pusher dimmed={mobileMenuOpen}>
                    {children}
                </Sidebar.Pusher>

            </Sidebar.Pushable>
        </div>
    );
}

const Header = ({ mobileMenuOpen, setMobileMenuOpen, showManagementSystemReminder, setShowManagementSystemReminder }) => {

    const { globalState } = useContext(StateContext);

    useEffect(() => {
        if (globalState && globalState.managementSystems) {
            const show = globalState.managementSystems && globalState.managementSystems.id ? false : true;       
            setShowManagementSystemReminder(val => show)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalState]);   

    const { MediaContextProvider, Media } = createMedia({
        breakpoints: {
            sm: 0,
            md: 768,
            lg: 1024,
            xl: 1192,
        },
    });

    return (
        <div className="app-header no-print">
            <MediaContextProvider>
                <Media greaterThan='sm'>
                    <Navigation mobile={false} mobileMenuOpen={mobileMenuOpen} setMobileMenuOpen={setMobileMenuOpen} showManagementSystemReminder={showManagementSystemReminder} />
                </Media>
                <Media lessThan='md'>
                    <Navigation mobile={true} mobileMenuOpen={mobileMenuOpen} setMobileMenuOpen={setMobileMenuOpen} showManagementSystemReminder={showManagementSystemReminder} />
                </Media>
            </MediaContextProvider>
        </div>
    );
}

const Content = (props) => {
    return (
        <Container className="page">
            {props.children}
        </Container>
    );
}

const Footer = (props) => {
    return (
        <footer className="footer">
        </footer>
    );
}

const Loading = ({text}) => {
    return (
        <div id='loader'>
            <Segment>
                <Dimmer active>
                    <div>
                        <Image src='/images/logo.png' className="logo"/>
                    </div>
                    <Loader size='massive'>{text}</Loader>
                </Dimmer>
            </Segment>
        </div>
    );
}

const Messages = (props) => {
    
    const { getMessages } = useContext(MessageContext);
    let messages = getMessages() || [];

    return (
        <div className="message-container">
            <div className={messages.length ? "messages active" : "messages" }>
                {messages}
            </div>
        </div>
    );

}

export {
    Navigation,
    MobileNavigation,
    Header,
    Content,
    Footer,
    Loading,
    Messages
};
