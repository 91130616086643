import { useContext, useState } from 'react';
import { AuthContext } from '../../Context/AuthContext';
import useRequestHandler from '../useRequestHandler';

const useReadEvaluation = () => {

    const { acquireTokenSilently, apiClient } = useContext(AuthContext);
    const { errorHandler, successHandler } = useRequestHandler();
    const [ evaluation, setEvaluation ] = useState({});

    const readEvaluation = async (evaluationId) => {
        try {

            const accessToken = await acquireTokenSilently();

            if (accessToken === "login_required") throw Error("login_required");

            const result = await apiClient.read(accessToken, "evaluations", evaluationId);

            const data = result.data || null;
            const evaluation = data.evaluation || null;

            if (evaluation) setEvaluation(evaluation);

            return successHandler(result, "useReadEvaluation", "read", { hide: true });

        } catch (error) {
            return errorHandler(error, "useReadEvaluation", "read");
        }
    }

    return { evaluation, readEvaluation };

}

export default useReadEvaluation;
