import { useContext } from 'react';
import { AuthContext } from '../../Context/AuthContext';
import useRequestHandler from '../useRequestHandler';

const useUpdateCompanyManagementSystems = () => {

    const { acquireTokenSilently, apiClient } = useContext(AuthContext);
    const { errorHandler, successHandler } = useRequestHandler();

    /** Update company management systems form */
    const updateCompanyManagementSystems = async (data) => {
        try {

            const accessToken = await acquireTokenSilently();

            if (accessToken === "login_required") throw Error("login_required");

            const result = await apiClient.update(accessToken, "management/systems", data);

            return successHandler(result, "useUpdateCompanyManagementSystems", "update", { hide: true });

        } catch (error) {

            return errorHandler(error, "useUpdateCompanyManagementSystems", "update");

        }
    }

    return { updateCompanyManagementSystems };

}

export default useUpdateCompanyManagementSystems;
