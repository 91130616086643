import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, Prompt } from 'react-router-dom';
import { Form, Input, Container, Checkbox } from 'semantic-ui-react';
import { SubmitButton, CancelButton } from '../../../Reusable/Buttons';
import useCreateUser from '../../../../Hooks/Users/useCreateUser';
import { AuthContext } from './../../../../Context/AuthContext';
import { Loading, PageTitle } from '../../../Reusable/Elements';
import Forbidden from './../../../Pages/Forbidden';

const CreateUser = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const { userExistError, setUserExistError, createUser } = useCreateUser();
    const { userProfile } = useContext(AuthContext);

    const [ firstName, setFirstName ] = useState("");
    const [ lastName, setLastName ] = useState("");
    const [ emailAddress, setEmailAddress ] = useState("");
    const [ isAdminUser, setIsAdminUser ] = useState(false);

    const [ isLoading, setIsLoading ] = useState(false);
    const [ formIsFilledOutAndSaved, setFormIsFilledOutAndSaved ] = useState(false);

    const onSubmit = async () => {
        try {

            const user = {
                "firstName": firstName,
                "lastName": lastName,
                "emailAddress": emailAddress,
                "isAdminUser": isAdminUser,
                "language": "fi"
            }

            setIsLoading(true);

            const result = await createUser(user);

            if (result.status === 200 || result.status === 201) {
                setFormIsFilledOutAndSaved(true);
                return history.goBack();
            }

            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
        }
    }

    if (!userProfile || !userProfile.isAdminUser) return <Forbidden />;

    return (
        <Form className="users" onSubmit={onSubmit} error={userExistError}>
            
            <Prompt when={!formIsFilledOutAndSaved} message={t('warnings.leaveForm')} />

            <Loading active={isLoading} text={t('general.loading')} />
        
            {/* Title */}
            <PageTitle title={t('users.new')} hideInfo/>

            {/* First name */}
            <Form.Field
                required
                maxLength="255"
                control={Input}
                label={t('general.firstName')}
                value={firstName}
                onChange={(ev, {value}) => setFirstName(value) }
                type="Text"
            />

            {/* Last name */}
            <Form.Field
                required
                maxLength="255"
                control={Input}
                label={t('general.lastName')}
                value={lastName}
                onChange={(ev, {value}) => setLastName(value) }
                type="Text"
            />

            {/* Email */}
            <Form.Field
                required
                maxLength="255"
                control={Input}
                label={t('general.emailAddress')}
                value={emailAddress}
                onChange={(ev, {value}) => {
                    setUserExistError(false);
                    setEmailAddress(value);
                }}
                type="Text"
                error={ userExistError ? { content: t('errors.userExist'), pointing: 'below' } : null }
            />

            {/* User is admin */}
            <Form.Field
                control={Checkbox}
                label={t('general.admin')}
                placeholder={'Checkbox'}
                checked={isAdminUser}
                onChange={(ev, {checked}) => setIsAdminUser(checked) }
            />

            <Container textAlign="right">
                <SubmitButton icon="save" color="green" title={t('general.save')} />
                <CancelButton />
            </Container>
            
        </Form>
    );

}

export default CreateUser;
