import React from 'react';
import { Message, Icon } from 'semantic-ui-react'
//import { useTranslation } from 'react-i18next';
//import './Messages.css';

const InfoMessage = (props) => {
    const onDismiss = () => {
        if (props && props.onDismiss) props.onDismiss();
    }
    return (
        <Message size="tiny" info onDismiss={() => onDismiss()} style={{ marginTop: '5px', marginBottom: '5px' }}>
            <Message.Content>
                <Message.Header>
                    <Icon name='circle notched' loading />
                    {props.header}
                </Message.Header>
                {props.content}
            </Message.Content>
        </Message>
    )
}

const PositiveMessage = (props) => {
    const onDismiss = () => {
        if (props && props.onDismiss) props.onDismiss();
    }
    return (
        <Message size="tiny" positive onDismiss={() => onDismiss()} style={{ marginTop: '5px', marginBottom: '5px' }}>
            <Message.Content>
                <Message.Header>
                    <Icon name='circle notched' />
                    {props.header}</Message.Header>
                {props.content}
                <br/>
                {props.optional}
            </Message.Content>
        </Message>
    )
}

const WarningMessage = (props) => {
    const onDismiss = () => {
        if (props && props.onDismiss) props.onDismiss();
    }
    return (
        <Message size="tiny" warning onDismiss={() => onDismiss()} style={{ marginTop: '5px', marginBottom: '5px' }}>
            <Message.Content>
                <Message.Header>
                    <Icon name='exclamation' />    
                    {props.header}
                </Message.Header>
                {props.content}
                <br/>
                {props.optional}
            </Message.Content>
        </Message>
    )
}

const ErrorMessage = (props) => {
    const onDismiss = () => {
        if (props && props.onDismiss) props.onDismiss();
    }
    return (
        <Message size="tiny" negative onDismiss={() => onDismiss()} style={{ marginTop: '5px', marginBottom: '5px' }}>
            <Message.Content>
                <Message.Header>
                    <Icon name='exclamation triangle'/>
                    {props.header}
                </Message.Header>
                {props.content}
                <br/>
                {props.optional}
            </Message.Content>
        </Message>
    )
}

const FetchingMessage = (props) => {
    const onDismiss = () => {
        if (props && props.onDismiss) props.onDismiss();
    }
    return (
        <Message size="tiny" positive onDismiss={() => onDismiss()} style={{ marginTop: '5px', marginBottom: '5px' }}>
            <Message.Content>
                <Message.Header>
                    <Icon name='circle notched' loading />
                    {props.header}
                </Message.Header>
                {props.content}
            </Message.Content>
        </Message>
    )
}

const SavingMessage = (props) => {
    const onDismiss = () => {
        if (props && props.onDismiss) props.onDismiss();
    }
    return (
        <Message size="tiny" info icon onDismiss={() => onDismiss()} style={{ marginTop: '5px', marginBottom: '5px' }}>
            <Message.Content>
                <Message.Header>
                    <Icon name='check' />
                    {props.header}
                </Message.Header>
                {props.content}
            </Message.Content>
        </Message>
    )
}

export {
    InfoMessage,
    PositiveMessage,
    WarningMessage,
    ErrorMessage,
    SavingMessage,
    FetchingMessage
};