import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../Context/AuthContext';
import useRequestHandler from './../useRequestHandler';

const useUpdateCompany = () => {

    const { t } = useTranslation();
    const { acquireTokenSilently, apiClient } = useContext(AuthContext);
    const { errorHandler, successHandler } = useRequestHandler();

    /** Update company 
     * @param {object} company - object discribing company
     * @example
     * {
     *     "id": 1,
     *     "name": "",
     *     "lineOfBusiness": ""
     * }
    */
    const updateCompany = async (company) => {
        try {

            const accessToken = await acquireTokenSilently();

            if (accessToken === "login_required") throw Error("login_required");

            const result = await apiClient.update(accessToken, "company", company);

            return successHandler(result, "useUpdateCompany", "update", { message: t('success.companyUpdatedSuccesfully') });

        } catch (error) {

            return errorHandler(error, "useUpdateCompany", "update");

        }
    }

    return { updateCompany };

}

export default useUpdateCompany;
