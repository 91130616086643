import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../Context/AuthContext';
import useRequestHandler from './../useRequestHandler';

const useCreateInspection = () => {

    const { t } = useTranslation();
    const { acquireTokenSilently, apiClient } = useContext(AuthContext);
    const { errorHandler, successHandler } = useRequestHandler();

    /** Create inspection
     * @param {object} inspection - object discribing the inspection
     * @example
     * {
     *     "locationId": 1,
     *     "date": "2011-10-05T14:48:00.000Z",
     *     "workGroup": "varhar(1000)",
     *     "description": "varhar(MAX)"
     * } */
    const createInspection = async (inspection) => {
        try {

            const accessToken = await acquireTokenSilently();

            if (accessToken === "login_required") throw Error("login_required");

            const result = await apiClient.create(accessToken, "inspections", inspection);

            return successHandler(result, "useCreateInspection", "create", { message: t('success.inspectionCreatedSuccesfully') });

        } catch (error) {
            return errorHandler(error, "useCreateInspection", "create");
        }
    }

    return { createInspection };

}

export default useCreateInspection;
