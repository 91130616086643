import { useContext, useState } from 'react';
import { AuthContext } from '../../Context/AuthContext';
import useRequestHandler from './../useRequestHandler';
import { useTranslation } from 'react-i18next';

const useReadUser = () => {
    
    const { t } = useTranslation();
    const { acquireTokenSilently, apiClient } = useContext(AuthContext);
    const [ user, setUser ] = useState({});
    const { errorHandler, successHandler } = useRequestHandler();

    /** Read user 
     * @param {integer} id - user id
    */
    const readUser = async (id) => {
        try {

            const accessToken = await acquireTokenSilently();

            if (accessToken === "login_required") throw Error("login_required");

            const result = await apiClient.read(accessToken, "users", id);
            
            const data = result.data || {}
            const user = data.user || null;
            if (user) setUser(user);

            return successHandler(result, "useReadUser", "read", { hide: true });

        } catch (error) {
            return errorHandler(error, "useReadUser", "read", { message: t('errors.readUserFailed')});
        }
    }

    return { user, readUser };

}

export default useReadUser;
