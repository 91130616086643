import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../Context/AuthContext';
import useRequestHandler from './../useRequestHandler';

const useCreateEvaluation = () => {
    
    const { t } = useTranslation();
    const { acquireTokenSilently, apiClient } = useContext(AuthContext);
    const { errorHandler, successHandler } = useRequestHandler();
    
    /** Create evaluation
     * @param {array} evaluations - object discribing the evaluation
     * @example
     * [{
     *     "inspectionId": 1,
     *     "riskFactorsId": 1,
     *     "description": "",
     *     "recognized": "",
     *     "riskPropability": 1,
     *     "riskConcequences": 1,
     *     "riskFactor": 1,
     *     "proceeding": "",
     *     "riskPropabilityAfterProceeding": 1,
     *     "riskConcequencesAfterProceeding": 1,
     *     "riskFactorAfterProceeding": 1,
     *     "responsiblePerson": "",
     *     "schedule": "2011-10-05T14:48:00.000Z",
     *     "evalutationCompleted": false
     * }]
    */
    const createEvaluation = async (evaluations) => {
        try {

            const accessToken = await acquireTokenSilently();

            if (accessToken === "login_required") throw Error("login_required");

            const result = await apiClient.create(accessToken, "evaluations", evaluations);

            return successHandler(result, "useCreateEvaluation", "create", { message: t('success.evaluationCreatedSuccesfully') });

        } catch (error) {
            return errorHandler(error, "useCreateEvaluation", "create");
        }
    }

    return { createEvaluation };

}

export default useCreateEvaluation;
