import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, Prompt } from 'react-router-dom';
import { Form, Input, Container, Checkbox } from 'semantic-ui-react';
import { Loading, PageTitle } from '../../../Reusable/Elements';
import { SubmitButton, CancelButton } from '../../../Reusable/Buttons';
import { AuthContext } from './../../../../Context/AuthContext';
import useReadUser from '../../../../Hooks/Users/useReadUser';
import useUpdateUser from '../../../../Hooks/Users/useUpdateUser';
import Forbidden from './../../../Pages/Forbidden';

const UpdateUser = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const { id } = useParams();

    const { user, readUser } = useReadUser();
    const { userAlreadyExistError, updateUser } = useUpdateUser();
    const { userProfile } = useContext(AuthContext);

    const [ userId, setUserId ] = useState(null);
    const [ firstName, setFirstName ] = useState("");
    const [ lastName, setLastName ] = useState("");
    const [ emailAddress, setEmailAddress ] = useState("");
    const [ isAdminUser, setIsAdminUser ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(true);
    const [ formIsFilledOutAndSaved, setFormIsFilledOutAndSaved ] = useState(false);

    // Fetch user
    useEffect(() => {
        (async () => {
            if (id) {
                await readUser(id);
                setIsLoading(false);
            }            
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    // Set user properties
    useEffect(() => {
        if (user) {
            setUserId(user.id || null);
            setFirstName(user.firstName || "");
            setLastName(user.lastName || "");
            setEmailAddress(user.emailAddress || "");
            setIsAdminUser(user.isAdminUser || false);
        }            
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const onSubmit = async () => {
        try {
            
            setIsLoading(true);

            const user = {
                "id": userId,
                "firstName": firstName,
                "lastName": lastName,
                "emailAddress": emailAddress,
                "isAdminUser": isAdminUser
            };
            
            const result = await updateUser(user);

            if (result.status === 200 || result.status === 204) {
                setFormIsFilledOutAndSaved(true);
                return history.goBack()
            };

            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
        }
    }
    
    let userIsViewingOwnProfile = userProfile && user && userProfile.id && user.id && userProfile.id === user.id ? true : false;

    if (!userProfile || !userProfile.isAdminUser) return <Forbidden />;

    return (
        <Form className="users" onSubmit={onSubmit} error={userAlreadyExistError} >
            
            <Prompt when={!formIsFilledOutAndSaved} message={t('warnings.leaveForm')} />

            <Loading active={isLoading} text={t('general.loading')} />

            {/* Title */}
            <PageTitle title={t('users.update')} hideInfo/>

            {/* First name */}
            <Form.Field
                required
                maxLength="255"
                control={Input}
                label={t('general.firstName')}
                value={firstName}
                onChange={(ev, {value}) => setFirstName(value) }
                type="Text"
            />

            {/* Last name */}
            <Form.Field
                required
                maxLength="255"
                control={Input}
                label={t('general.lastName')}
                value={lastName}
                onChange={(ev, {value}) => setLastName(value) }
                type="Text"
            />

            {/* Email */}
            <Form.Field
                required
                maxLength="255"
                control={Input}
                label={t('general.emailAddress')}
                value={emailAddress}
                onChange={(ev, {value}) => setEmailAddress(value) }
                type="Text"
                error={ userAlreadyExistError ? { content: t('errors.userExist'), pointing: 'below' } : null }
            />

            {/* User is admin */}
            { !userIsViewingOwnProfile && 
                <Form.Field
                    control={Checkbox}
                    label={t('general.admin')}
                    placeholder={'Checkbox'}
                    checked={isAdminUser}
                    onChange={(ev, {checked}) => setIsAdminUser(checked) }
                />
            }

            <Container textAlign="right">
                <SubmitButton icon="save" color="green" title={t('general.save')} />
                <CancelButton onClick={() => {setFormIsFilledOutAndSaved(true); history.goBack();} }/>
            </Container>
            
        </Form>
    );

}

export default UpdateUser;
