import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, Prompt } from 'react-router-dom';
import { Form, Input, Container } from 'semantic-ui-react';
import { SubmitButton, CancelButton } from '../../../Reusable/Buttons';
import { AuthContext } from './../../../../Context/AuthContext';
import { StateContext } from './../../../../Context/StateContext';
import useReadCompany from '../../../../Hooks/Company/useReadCompany';
import useUpdateCompany from '../../../../Hooks/Company/useUpdateCompany';
import { Loading, PageTitle } from '../../../Reusable/Elements';
import Forbidden from './../../../Pages/Forbidden';

const UpdateCompany = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const { globalState } = useContext(StateContext);
    const { company, readCompany } = useReadCompany();
    const { updateCompany } = useUpdateCompany();
    const { userProfile } = useContext(AuthContext);

    const [ id, setId ] = useState(null);
    const [ name, setName ] = useState("");
    const [ lineOfBusiness, setLineOfBusiness ] = useState("");

    const [ isLoading, setIsLoading ] = useState(true);
    const [ formIsFilledOutAndSaved, setFormIsFilledOutAndSaved ] = useState(false);

    // Fetch company
    useEffect(() => {
        (async () => { await readCompany(); })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Set values
    useEffect(() => {
        (async () => {
            if (company.id) {
                setId(company.id || 0);
                setName(company.name || "");
                setLineOfBusiness(company.lineOfBusiness || 0);
                setIsLoading(false);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company]);

    const onSubmit = async () => {
        try {

            setIsLoading(true);

            const company = {
                id: id,
                name: name,
                lineOfBusiness: lineOfBusiness
            }

            const result = await updateCompany(company);

            if (result.status === 200) {
                setFormIsFilledOutAndSaved(true);
                return history.goBack();
            }

            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
        }
    }
    
    if (!userProfile || !userProfile.isAdminUser) return <Forbidden />;

    return (
        <Form className="company" onSubmit={onSubmit}>
            
            <Prompt when={!formIsFilledOutAndSaved} message={t('warnings.leaveForm')} />

            <Loading active={isLoading} text={t('general.loading')} />
            
            <PageTitle title={t('company.update')} hideInfo/>

            {/* Company name */}
            <Form.Field
                required
                maxLength="255"
                control={Input}
                label={t('general.name')}
                value={name}
                onChange={(ev, {value}) => setName(value) }
                type="Text"
            />

            {/* Line of Business */}
            <Form.Select
                required
                maxLength="255"
                label={t('registration.lineOfBusiness')}
                value={lineOfBusiness}
                options={globalState.lineOfBusinessOptions}
                onChange={(ev, {value}) => setLineOfBusiness(value) }
            />

            <Container textAlign="right">
                <SubmitButton icon="save" color="green" title={t('general.save')} />
                <CancelButton />
            </Container>

        </Form>
    );

}

export default UpdateCompany;
