import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../Context/AuthContext';
import useRequestHandler from './../useRequestHandler';

const useRegister = () => {

    const { t } = useTranslation();
    const { apiClient } = useContext(AuthContext);
    const { errorHandler, successHandler } = useRequestHandler();
    const [ companyAlreadyExistError, setCompanyAlreadyExistError ] = useState(false);
    const [ userAlreadyExistError, setUserAlreadyExistError ] = useState(false);
    
    /** Register company and create admin user
     * @param {object} registration - Registration object
     * @example
     * {
     *     "company": {
     *         "name": "",
     *         "lineOfBusiness": 1
     *     },
     *     "user": {
     *         "firstName": "",
     *         "lastName": "",
     *         "emailAddress": ""
     *         "language": "fi",
     *         "password": ""
     *     }
     * }
     * @returns {object} - token object */
    const register = async (registration) => {
        try {

            const result = await apiClient.create(null, "registration", registration);

            return successHandler(result, "useRegister", "register", { message: t('success.registrationEmailSent') });

        } catch (error) {

            const status = error.status || null;
            const data = error.data || {};
            const errorCode = data.errorCode || 0;
            let options = {};

            if (status === 400 && errorCode === -2) {
                setCompanyAlreadyExistError(true);
                options.message = t('errors.companyExist');
                options.hide = true;
            }

            if (status === 400 && errorCode === -12) {
                setUserAlreadyExistError(true);
                options.message = t('errors.userExist');
                options.hide = true;
            }

            return errorHandler(error, "useRegister", "register", options);

        }
    }

    return { 
        companyAlreadyExistError, setCompanyAlreadyExistError,
        userAlreadyExistError, setUserAlreadyExistError,
        register
    };

}

export default useRegister;
