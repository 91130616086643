import React from 'react';
//import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid, Dimmer, Loader, Container, List, Label } from 'semantic-ui-react'
import { InfoButton } from './../Reusable/Buttons';
import Config from './../../Config/config';

/** App data privacy link to pdf */
const DataPrivacy = ({className}) => {
    const { t } = useTranslation();
    return (
        <a 
            className={className}
            href={Config.app.dataPrivacyLink}
            target="_blank"
            rel="noreferrer"
        >
            <i className="icon file pdf"></i>
            {t('registration.dataPrivacyDocument')}
        </a>
    )
}

/** App front page description section */
const Description = ({title, description, link, linkTitle}) => {
    return (
        <div className="description" >
            <b>{title}</b>
            <p>{description}</p>
            <a className="orange" href={link}>{linkTitle.toUpperCase()} <i className="icon arrow right"></i></a> 
        </div>
    );
}

/** App page info section */
const Info = ({text,bullets,more}) => {
    return (
        <Label className='help-label'>
            <List>
                <List.Item icon='info' content={text} />
                {bullets &&
                    <List.Item>
                        <List bulleted>
                        {bullets.map((item, index) =>
                            <List.Item key={index} content={item} />
                        )}
                        </List>
                    </List.Item>
                }
                {more &&
                    <Container style={{ marginTop:'5px', paddingLeft: '14px'}}>
                        { more.map((item, index) => <div key={index}>{item}</div>) }
                    </Container>
                }
            </List>
        </Label>
    );
}

/** App front page process box */
const ProcessBox = ({ number, title, content }) => {
    return (<div className="box">
        <Grid columns={2}>
            <Grid.Row stretched>
                <Grid.Column width={1} textAlign='center' verticalAlign='middle'>
                    <span className="orange number">{number}</span>
                </Grid.Column>
                <Grid.Column width={15}>
                    <div><b>{title}</b></div>
                    <div>{content}</div>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </div>);
}

/* Page content loading overlay with loader icon */
const Loading = ({text, active}) => {
    const id = active ? 'overlay': '';
    return (
        <div>
            <Dimmer active={active} id={id} >
                <Loader size='massive'>{text}</Loader>
            </Dimmer>
        </div>
    );
}

/** Page title with info button */
const PageTitle = ({title, floated, hideInfo, infoColor, href, active}) => {
    const { t } = useTranslation();
    return (
        <Container className="tt-nav">
            <div className="title">
                <InfoButton hidden={hideInfo ? hideInfo : false} as='a' target='_blank' href={href} floated={floated || "right"} circular color={infoColor}/>
                <h1>{title || t('general.title')}</h1>
                <hr className={active ? "orange" : ""} />
            </div>
        </Container>
    )
}

/* Page title App mini navigation container with info button */
const WizardMenu = ({columns, children, hideInfo, href, infoFloated, infoTitle, visible}) => {
    //const { t } = useTranslation();
    const style={ }

    if (!visible) return null;

    return (
        <Container className="tt-nav">
            <Grid columns={columns || 3} className="nopadding">
                <Grid.Row className='info-container'>
                {!hideInfo &&
                    <Container fluid>
                        <Grid columns={2}>
                            <Grid.Row>
                                <Grid.Column width={8} verticalAlign="middle"><h3><b>{infoTitle}</b></h3></Grid.Column>
                                <Grid.Column width={8} textAlign="right">
                                    <InfoButton as='a' target='_blank' href={href || ""} floated={infoFloated || "right"} circular style={style} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                }
                </Grid.Row>
                <Grid.Row>
                    {children}
                </Grid.Row>
            </Grid>
        </Container>
    );
}

/* App mini navigation item */
const WizardItem= ({textAlign, title, active, children}) => {
    const { t } = useTranslation();
    return (
        <Grid.Column textAlign={textAlign || "center"} className="title">
            {title || t('general.title')}
            {children}
            <hr className={active ? "orange" : ""}/>
        </Grid.Column>
    );
}

export {
    PageTitle,
    DataPrivacy,
    Description,
    Info,
    ProcessBox,
    Loading,
    WizardMenu,
    WizardItem
};