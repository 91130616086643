import { useContext, useState } from 'react';
import { AuthContext } from '../../Context/AuthContext';
import useRequestHandler from '../useRequestHandler';

const useReadLocationsCsvData = () => {

    const { acquireTokenSilently, apiClient } = useContext(AuthContext);
    const { errorHandler, successHandler } = useRequestHandler();
    const [ locationsCsvData, setLocationsCsvData ] = useState([]);

    const readLocationsCsvData = async () => {
        try {

            const accessToken = await acquireTokenSilently();

            if (accessToken === "login_required") throw Error("login_required");

            const result = await apiClient.read(accessToken, "export/locations");

            const data = result.data || null;
            const reports = data.reports || [];

            if (data) setLocationsCsvData(reports);

            return successHandler(result, "useReadLocationsCsvData", "read", { hide: true });

        } catch (error) {
            return errorHandler(error, "useReadLocationsCsvData", "read");
        }
    }

    return { locationsCsvData, readLocationsCsvData };

}

export default useReadLocationsCsvData;
