import { useContext, useState } from 'react';
import { AuthContext } from '../../Context/AuthContext';
import useRequestHandler from './../useRequestHandler';

const useReadUserProfile = () => {

    const { acquireTokenSilently, apiClient } = useContext(AuthContext);
    const [ userProfile, setUserProfile ] = useState({});
    const { errorHandler, successHandler } = useRequestHandler();

    /** Read user profile */
    const readUserProfile = async () => {
        try {

            const accessToken = await acquireTokenSilently();

            if (accessToken === "login_required") throw Error("login_required");

            const result = await apiClient.read(accessToken, "profile");

            const data = result.data || {};
            const user = data.user || null;
            if (user) setUserProfile(user);

            return successHandler(result, "useReadUserProfile", "read", { hide: true });

        } catch (error) {
            return errorHandler(error, "useReadUserProfile", "read");
        }
    }

    return { userProfile, readUserProfile };

}

export default useReadUserProfile;
