const config = {
    test: {
        environment: "test",
        app: {
            rootPath: "https://ttk-tt-app-test.app19.systemsgarden.com",
            dataPrivacyLink: "https://ttk-tt-app-prod.app19.systemsgarden.com/data/Tietosuojaseloste_Tyoturvallisuustutka_2021.pdf",
            publicRoutes: [
                "/register",
                "/login",
                "/requestnewpassword",
                "/setnewpassword",
                "/confirmregistration",
                "/approveinvitation",
                "/info"
            ]
        },
        webApi: {
            rootPath: "https://ttk-tt-api-test.app19.systemsgarden.com",
            apiVersion: "/api/v1/"
        },
        monitoring: {
            instrumentationKey: "fb9b4ef2-ebf7-4c77-b0f2-39f4b2e70bb5",
            maxBatchInterval: 0,
            disableTelemetry: true
        },
        development: {
            debug: false,
            logToken: false
        }
    },
    production: {
        environment: "production",
        app: {
            rootPath: "https://ttk-tt-app-prod.app19.systemsgarden.com",
            dataPrivacyLink: "https://ttk-tt-app-prod.app19.systemsgarden.com/data/Tietosuojaseloste_Tyoturvallisuustutka_2021.pdf",
            publicRoutes: [
                "/register",
                "/login",
                "/requestnewpassword",
                "/setnewpassword",
                "/confirmregistration",
                "/approveinvitation",
                "/info"
            ]
        },
        webApi: {
            rootPath: "https://ttk-tt-api-prod.app19.systemsgarden.com",
            apiVersion: "/api/v1/"
        },
        monitoring: {
            instrumentationKey: "fb9b4ef2-ebf7-4c77-b0f2-39f4b2e70bb5",
            maxBatchInterval: 0,
            disableTelemetry: true
        },
        development: {
            debug: false,
            logToken: false
        }        
    }
};

// TTK does not have "development" environment. Only test and prod in the app 13 server
// Choose environment [config.test || config.production ]
let selectedEnvironment = config.production;

// Export selected environment
export default selectedEnvironment;
