import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Form, Input, Container } from 'semantic-ui-react';
import { DataPrivacy, Loading, PageTitle } from './../../Reusable/Elements';
import { SubmitButton, CancelButton } from './../../Reusable/Buttons';
import useRequestNewPassword from './../../../Hooks/Authentication/useRequestNewPassword';

const RequestNewPassword = (props) => {

    const { t } = useTranslation();
    const history = useHistory();

    const [ username, setUsername ] = useState("");
    const { requestPassword } = useRequestNewPassword();
    const [ isLoading, setIsLoading ] = useState(false);

    const onSubmit = async () => {
        try {

            setIsLoading(true);

            const result = await requestPassword(username);

            if (result.status === 200) history.push("/login");
            
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
        }
    }

    return (
        <Form className="authenticate" onSubmit={onSubmit}>

            <Loading active={isLoading} text={t('general.loading')} />

            {/* Title */}
            <PageTitle title={t('requestnewpassword.title')} hideInfo />

            {/* Username */}
            <Form.Field
                required
                focus
                maxLength="255"
                control={Input}
                label={t('general.username')}
                value={username}
                onChange={(ev, {value}) => setUsername(value) }
                type="Text"
            />

            {/* Buttons */}
            <Container textAlign="right">
                <SubmitButton color='green' icon="key" title={t('requestnewpassword.reset')} />
                <CancelButton />
            </Container>
            <hr/>

            {/* DataPrivacy */}
            <DataPrivacy className="green"/>

        </Form>
    );


}

export default RequestNewPassword;
