import React, { useState, createContext, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import config from './../Config/config';
import useReadCompanyManagementSystems from './../Hooks/Company/useReadCompanyManagementSystems';
import { AuthContext } from './../Context/AuthContext';

export const StateContext = createContext();

const StateContextProvider = ({children}) => {

    const { t } = useTranslation();
    const history = useHistory();
    let location = useLocation();
    const [ locations, setLocations ] = useState([]);
    const [ isPublicPath, setIsPublicPath ] = useState(true);

    /* Inspection states - used in report */
    const [ locationObj, setLocationObj ] = useState(null);
    const [ locationId, setLocationId ] = useState(0);
    const [ selectedEvaluationId, setSelectedEvaluationId ] = useState(0); // user edit old evaluation
    const { managementSystems, readCompanyManagementSystems } = useReadCompanyManagementSystems();
    const { userProfile } = useContext(AuthContext);
    
    // Used in "register" and "updateCompany" pages
    const lineOfBusinessOptions = [
        {
            key: 1,
            text: t('registration.agricultureAndForestry'),
            value: 1
        },
        {
            key: 2,
            text: t('registration.foodIndustry'),
            value: 2
        },
        {
            key: 3,
            text: t('registration.metalAndElectronicsIndustry'),
            value: 3
        },
        {
            key: 4,
            text: t('registration.lumberIndustry'),
            value: 4
        },
        {
            key: 5,
            text: t('registration.chemicalIndustry'),
            value: 5
        },
        {
            key: 6,
            text: t('registration.otherIndustry'),
            value: 6
        },
        {
            key: 7,
            text: t('registration.construction'),
            value: 7
        },
        {
            key: 8,
            text: t('registration.transportationAndLogistics'),
            value: 8
        },
        {
            key: 9,
            text: t('registration.serviceIndustry'),
            value: 9
        },
        {
            key: 10,
            text: t('registration.municipality'),
            value: 10
        },
        {
            key: 11,
            text: t('registration.congregation'),
            value: 11
        },
        {
            key: 12,
            text: t('registration.government'),
            value: 12
        },
        {
            key: 13,
            text: t('registration.other'),
            value: 13
        }
    ]

    // Fetch company management systems on load
    useEffect(() => {
        if (userProfile && !managementSystems.id) {
            (async () => {
                await readCompanyManagementSystems();
            })();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userProfile]);

    // When location changes, check if user is on public path or on path that requires authentication
    useEffect(() => {
        const currentPath = history.location.pathname;
        const currentPathIsPublicPath = config.app.publicRoutes.find(path => path === currentPath);
        if (currentPathIsPublicPath) return setIsPublicPath(true);
        setIsPublicPath(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    /** Public properties and methods
     * @description Add all properties and method here which should be accessible outside.
     * Prevent straight data manipulation when not giving straight access to state and by using get and set methods **/ 
    const globalState = {

        isPublicPath,
        lineOfBusinessOptions,
        managementSystems,
                
        // Inspection properties and methods
        locations, setLocations,
        locationObj, setLocationObj,
        locationId, setLocationId,
        selectedEvaluationId, setSelectedEvaluationId

    }

    return (
        <StateContext.Provider value={{ globalState }}>
            {children}
        </StateContext.Provider>
    );

}

export default StateContextProvider;
