import { useContext, useState } from 'react';
import { AuthContext } from '../../Context/AuthContext';
import useRequestHandler from '../useRequestHandler';

const useListRiskFactorCategories = () => {

    const { acquireTokenSilently, apiClient } = useContext(AuthContext);
    const [ riskFactorCategories, setRiskFactorCategories ] = useState([]);
    const { errorHandler, successHandler } = useRequestHandler();

    /** List Risk Factor Categories */
    const listRiskFactorCategories = async () => {
        try {

            const accessToken = await acquireTokenSilently();

            if (accessToken === "login_required") throw Error("login_required");

            const result = await apiClient.list(accessToken, "riskfactorcategories");
            
            const data = result.data || {};
            const riskFactorCategories = data.riskFactorCategories || null;

            // Set data available to global state and return also from the hook
            if (riskFactorCategories) setRiskFactorCategories(riskFactorCategories);
            

            return successHandler(result, "useListRiskFactorCategories", "list", { hide: true });

        } catch (error) {
            return errorHandler(error, "useListRiskFactorCategories", "list");
        }
    }

    return { riskFactorCategories, listRiskFactorCategories };

}

export default useListRiskFactorCategories;
