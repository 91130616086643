
/** Create location dropdown options with default value
 * @param {Array} locations - locations array */
const createLocationOptions = (t, locations) => {
    let options = [];
    if (locations && locations.length) {
        options = locations.map((item,index) => {
            return {
                "key": index + 1,
                "text": item.name,
                "value": item.id
            }
        });
    }
    options.unshift({ "key": 0, "text": t('locations.chooseLocation'), "value": 0 })
    return options;
}

/** Create CSV headers */
const createCsvHeaders = (t) => {
    const headers = [
        //{ label: "Location id", key: "locationId" },
        { label: t('inspection.location'), key: "locationName" },
        //{ label: "Location Description", key: "locationDescription" },
        //{ label: "Location Created", key: "locationCreated" },
        //{ label: "Location Created By First Name", key: "locationCreatedByFirstName" },
        //{ label: "Location Created By Last Name", key: "locationCreatedByLastName" },
        //{ label: "Inspection Id", key: "inspectionId" },
        { label: t('general.createdDate'), key: "inspectionDate" },
        { label: t('inspection.workgroup'), key: "inspectionWorkGroup" },
        { label: t('inspection.description'), key: "inspectionDescription" },
        //{ label: "Inspection Created By First Name", key: "inspectionCreatedByFirstName" },
        //{ label: "Inspection Created By Last Name", key: "inspectionCreatedByLastName" },
        //{ label: "RiskFactor Category Id", key: "riskFactorCategoryId" },
        { label: t('inspection.riskfactorlist'), key: "riskFactorCategoryName" },
        //{ label: "RiskFactor Id", key: "riskFactorId" },
        { label: t('inspection.sector'), key: "riskFactorName" },
        { label: t('inspection.dangerousSituation'), key: "riskFactorDescription" },
        //{ label: "Evaluation Id", key: "evaluationId" },
        { label: t('evaluation.description'), key: "evaluationDescription" },
        { label: t('evaluation.riskPropability'), key: "evaluationRiskProbability" },
        { label: t('evaluation.riskConsequences'), key: "evaluationRiskConcequences" },
        { label: t('evaluation.riskImpact'), key: "evaluationRiskImpact" },
        { label: t('evaluation.proceeding'), key: "evaluationProceeding" },
        { label: t('evaluation.riskProbabilityAfterProceeding'), key: "evaluationRiskProbabilityAfterProceeding" },
        { label: t('evaluation.riskConsequencesAfterProceeding'), key: "evaluationRiskConcequencesAfterProceeding" },
        { label: t('evaluation.riskImpactAfterProceeding'), key: "evaluationRiskImpactAfterProceeding" },
        { label: t('evaluation.responsiblePerson'), key: "evaluationResponsiblePerson" },
        { label: t('evaluation.schedule'), key: "evaluationSchedule" }
    ];
    return headers;
}

/** Create empty CSV headers */
const createEmptyCsvHeaders = (t) => {
const headers = [
    { label: " ", key: "c1" },
    { label: " ", key: "c2" },
    { label: " ", key: "c3" },
    { label: " ", key: "c4" },
    { label: " ", key: "c5" },
    { label: " ", key: "c6" },
    { label: " ", key: "c7" },
    { label: " ", key: "c8" },
    { label: " ", key: "c9" },
    { label: " ", key: "c10" },
    { label: " ", key: "c11" },
    { label: " ", key: "c12" },
    { label: " ", key: "c13" },
    { label: " ", key: "c14" },
    { label: " ", key: "c15" },
    { label: " ", key: "c16" },
    { label: " ", key: "c17" }
];
return headers;
}

/** Create CSV header section - array of objects
 *  @returns {Array} array of objects (delimeter is ;)
 *  @example
 *  "
 *      Raportti
 *      Työturvallisuuskeskus: Työturvallisuustutka
 *
 *" */
const createCsvHeaderSection = (t) => {

    const header = [
    {"c1": " ","c2": " ","c3": " ","c4": " ","c5": " ","c6": " ","c7": " ","c8": " ","c9": " ","c10": " ","c11": " ","c12": " ","c13": " ", "c14": " ", "c15": " ", "c16": " ", "c17":" " },
    {"c1": t('report.report'),"c2": "","c3": "","c4": "","c5": "","c6": "","c7": "","c8": "","c9": "","c10": "","c11": "","c12": "","c13": "", "c14": "", "c15": "", "c16": "", "c17":"" },
    {"c1": t('app.company') + " " + t('app.name'),"c2": "","c3": "","c4": "","c5": "","c6": "","c7": "","c8": "","c9": "","c10": "","c11": "","c12": "","c13": "", "c14": "", "c15": "", "c16": "", "c17":"" },
    {"c1": "","c2": "","c3": "","c4": "","c5": "","c6": "","c7": "","c8": "","c9": "","c10": "","c11": "","c12": "","c13": "", "c14": "", "c15": "", "c16": "", "c17":"" },
];
    return header;
}

/** Create CSV location details section - array of objects
 *  @returns {Array} array of objects (delimeter is ;)
 *  @example
 *  "
 *
 *      Kohde	        Kokeilu 1
 *      Luontipvm	    28.10.2021
 *      Tekijät	        Teppo testaaja
 *      Lisätiedot	    Lorem ipsum…
 *" */
const createCsvDetailsSection = (t, row) => {

    let location = row && row.locationName ? row.locationName : "";
    let date = row && row.inspectionDate ? new Date(row.inspectionDate).toLocaleDateString('fi-FI') : "";
    let workgroup = row && row.inspectionWorkGroup ? row.inspectionWorkGroup : "";
    let description = row && row.inspectionDescription ? row.inspectionDescription : "";

    const detail = [];
    detail.push({"c1": "","c2": "","c3": "","c4": "","c5": "","c6": "","c7": "","c8": "","c9": "","c10": "","c11": "","c12": "","c13": "", "c14": "", "c15": "", "c16": "", "c17":"" });
    detail.push({"c1": t('locations.location'),"c2": location,"c3": "","c4": "","c5": "","c6": "","c7": "","c8": "","c9": "","c10": "","c11": "","c12": "","c13": "", "c14": "", "c15": "", "c16": "", "c17":"" });
    detail.push({"c1": t('general.createdDate'),"c2": date,"c3": "","c4": "","c5": "","c6": "","c7": "","c8": "","c9": "","c10": "","c11": "","c12": "","c13": "", "c14": "", "c15": "", "c16": "", "c17":"" });
    detail.push({"c1": t('inspection.workgroup'),"c2": workgroup,"c3": "","c4": "","c5": "","c6": "","c7": "","c8": "","c9": "","c10": "","c11": "","c12": "","c13": "", "c14": "", "c15": "", "c16": "", "c17":"" });
    detail.push({"c1": t('inspection.description'),"c2": description,"c3": "","c4": "","c5": "","c6": "","c7": "","c8": "","c9": "","c10": "","c11": "","c12": "","c13": "", "c14": "", "c15": "", "c16": "", "c17":"" });
    detail.push({"c1": "","c2": "","c3": "","c4": "","c5": "","c6": "","c7": "","c8": "","c9": "","c10": "","c11": "","c12": "","c13": "", "c14": "", "c15": "", "c16": "", "c17":"" });

    return detail;

}

/** Create CSV location row headers */
const createCsvLocationRowHeaders = (t) => {
    return [
        {"c1": "","c2": "","c3": "","c4": "","c5": "","c6": "","c7": "","c8": "","c9": "","c10": "","c11": "","c12": "","c13": "", "c14": "", "c15": "", "c16": "", "c17":"" },
        {
            "c1": t('inspection.riskfactorlist'),
            "c2": t('inspection.sector'),
            "c3": t('inspection.dangerousSituation'),
            "c4": t('evaluation.riskPropability'),
            "c5": t('evaluation.riskConsequences'),
            "c6": t('evaluation.riskImpact'),
            "c7": t('evaluation.proceeding'),
            "c8": t('evaluation.riskProbabilityAfterProceeding'),
            "c9": t('evaluation.riskConsequencesAfterProceeding'),
            "c10": t('evaluation.riskImpactAfterProceeding'),
            "c11": t('evaluation.responsiblePerson'),
            "c12": t('evaluation.schedule'),
            "c13": "",
            "c14": "",
            "c15": "",
            "c16": "",
            "c17": ""
        }
    ];
}

/** Create CSV content section - array of objects
 * @param {object} - array or object
 *  @returns {Array} array of objects (delimeter is ;)
 *  @example
 *  "
 * 
 *      Vaaratekijälista	Osa-alue        Vaaratilanne	Vaaratilanteen  Todennäköisyys ...
 *      Altisteet	        Kemikaalit	    tkoktoko	    2	            4              ...
 *      Muut	            Melu	        Lorem ipsum	    1	            2              ...
 *  
 *  " */
const createCSVContentSection = (array) => {

    //let data = [];
    let content = [];
    let evaluationSchedule = "";

    // Case array is ARRAY, no need to done anything.
    //if (Array.isArray(array)) data = array;

    // Case array is object, wrap it around array
    //if (array instanceof Object) data = [array];
    
    if (array && array.length) {
        
        array.forEach(item => {

            evaluationSchedule = item && item.evaluationSchedule ? new Date(item.evaluationSchedule).toLocaleDateString('fi-FI') : "";

            content.push({
                "c1": item.riskFactorCategoryName ? item.riskFactorCategoryName : "",
                "c2": item.riskFactorName ? item.riskFactorName : "",
                "c3": item.evaluationDescription ? item.evaluationDescription : "",
                "c4": item.evaluationRiskProbability>0 ? item.evaluationRiskProbability : "",
                "c5": item.evaluationRiskConcequences>0 ? item.evaluationRiskConcequences : "",
                "c6": item.evaluationRiskImpact>0 ? item.evaluationRiskImpact : "",
                "c7": item.evaluationProceeding ? item.evaluationProceeding : "",
                "c8": item.evaluationRiskProbabilityAfterProceeding>0 ? item.evaluationRiskProbabilityAfterProceeding : "",
                "c9": item.evaluationRiskConcequencesAfterProceeding>0 ? item.evaluationRiskConcequencesAfterProceeding : "",
                "c10": item.evaluationRiskImpactAfterProceeding>0 ? item.evaluationRiskImpactAfterProceeding : "",
                "c11": item.evaluationResponsiblePerson ? item.evaluationResponsiblePerson : "",
                "c12": evaluationSchedule,
                "c13": "",
                "c14": "",
                "c15": "",
                "c16": "",
                "c17": ""
            });

        });
        content.push({"c1": "","c2": "","c3": "","c4": "","c5": "","c6": "","c7": "","c8": "","c9": "","c10": "","c11": "","c12": "","c13": "", "c14": "", "c15": "", "c16": "", "c17":"" });
    }
    
    return content;

}

/** Create CSV footer section - array of objects
 *  @returns {Array} array of objects (delimeter is ;)
 *  @example
 *  "
 *      Todennäköisyys	
 *      1	Epätodennäköinen
 *      2	Kuukausittain
 *      3	Viikoittain
 *      4	Päivittäin
 *      5	Välitön
 *	
*      Seuraukset	
*      1	Merkityksetön
*      2	Vähäinen
*      3	Keskitasoa
*      4	Vakava
*      5	Erittäin vakava
*      
*" */
const createCsvFooterSection = (t) => {
    const footer = [];
    footer.push({"c1": "","c2": "","c3": "","c4": "","c5": "","c6": "","c7": "","c8": "","c9": "","c10": "","c11": "","c12": "","c13": "", "c14": "", "c15": "", "c16": "", "c17":"" });
    footer.push({"c1": "","c2": "","c3": "","c4": "","c5": "","c6": "","c7": "","c8": "","c9": "","c10": "","c11": "","c12": "","c13": "", "c14": "", "c15": "", "c16": "", "c17":"" });
    footer.push({"c1": t('general.propability'),"c2": "","c3": "","c4": "","c5": "","c6": "","c7": "","c8": "","c9": "","c10": "","c11": "","c12": "","c13": "", "c14": "", "c15": "", "c16": "", "c17":"" });
    footer.push({"c1": "1","c2": t('evaluation.probability1'),"c3": "","c4": "","c5": "","c6": "","c7": "","c8": "","c9": "","c10": "","c11": "","c12": "","c13": "", "c14": "", "c15": "", "c16": "", "c17":"" });
    footer.push({"c1": "2","c2": t('evaluation.probability2'),"c3": "","c4": "","c5": "","c6": "","c7": "","c8": "","c9": "","c10": "","c11": "","c12": "","c13": "", "c14": "", "c15": "", "c16": "", "c17":"" });
    footer.push({"c1": "3","c2": t('evaluation.probability3'),"c3": "","c4": "","c5": "","c6": "","c7": "","c8": "","c9": "","c10": "","c11": "","c12": "","c13": "", "c14": "", "c15": "", "c16": "", "c17":"" });
    footer.push({"c1": "4","c2": t('evaluation.probability4'),"c3": "","c4": "","c5": "","c6": "","c7": "","c8": "","c9": "","c10": "","c11": "","c12": "","c13": "", "c14": "", "c15": "", "c16": "", "c17":"" });
    footer.push({"c1": "5","c2": t('evaluation.probability5'),"c3": "","c4": "","c5": "","c6": "","c7": "","c8": "","c9": "","c10": "","c11": "","c12": "","c13": "", "c14": "", "c15": "", "c16": "", "c17":"" });
    footer.push({"c1": t('general.consequences'),"c2": "","c3": "","c4": "","c5": "","c6": "","c7": "","c8": "","c9": "","c10": "","c11": "","c12": "","c13": "", "c14": "", "c15": "", "c16": "", "c17":"" });
    footer.push({"c1": "1","c2": t('evaluation.consequence1'),"c3": "","c4": "","c5": "","c6": "","c7": "","c8": "","c9": "","c10": "","c11": "","c12": "","c13": "", "c14": "", "c15": "", "c16": "", "c17":"" });
    footer.push({"c1": "2","c2": t('evaluation.consequence2'),"c3": "","c4": "","c5": "","c6": "","c7": "","c8": "","c9": "","c10": "","c11": "","c12": "","c13": "", "c14": "", "c15": "", "c16": "", "c17":"" });
    footer.push({"c1": "3","c2": t('evaluation.consequence3'),"c3": "","c4": "","c5": "","c6": "","c7": "","c8": "","c9": "","c10": "","c11": "","c12": "","c13": "", "c14": "", "c15": "", "c16": "", "c17":"" });
    footer.push({"c1": "4","c2": t('evaluation.consequence4'),"c3": "","c4": "","c5": "","c6": "","c7": "","c8": "","c9": "","c10": "","c11": "","c12": "","c13": "", "c14": "", "c15": "", "c16": "", "c17":"" });
    footer.push({"c1": "5","c2": t('evaluation.consequence5'),"c3": "","c4": "","c5": "","c6": "","c7": "","c8": "","c9": "","c10": "","c11": "","c12": "","c13": "", "c14": "", "c15": "", "c16": "", "c17":"" });
    footer.push({"c1": t('general.riskfactor'),"c2": "","c3": "","c4": "","c5": "","c6": "","c7": "","c8": "","c9": "","c10": "","c11": "","c12": "","c13": "", "c14": "", "c15": "", "c16": "", "c17":"" });
    footer.push({"c1": t('general.riskfactorScale'), "c2": "","c3": "","c4": "","c5": "","c6": "","c7": "","c8": "","c9": "","c10": "","c11": "","c12": "","c13": "", "c14": "", "c15": "", "c16": "", "c17":"" });
    footer.push({"c1": "","c2": "","c3": "","c4": "","c5": "","c6": "","c7": "","c8": "","c9": "","c10": "","c11": "","c12": "","c13": "", "c14": "", "c15": "", "c16": "", "c17":"" });
    return footer;
}

/** Create CSV report */
const createLocationsCsvReport = (t, locations, csvData) => {

    // Temporary csv creation variables
    let filteredByLocation = [];
    let singleLocationDetails = [];
    let singleLocationRowHeaders = [];
    let singleLocationRows = [];

    // CSV variables
    const csv = [];
    let csvHeader = [];
    let csvLocationContent = [];
    let csvFooter = [];

    // Create CSV Header
    csvHeader = createCsvHeaderSection(t);

    // Get content rows to the CSV grouped by location
    locations.forEach(location => {

        // Get current location rows
        if (csvData && csvData.length) {
            filteredByLocation = csvData.filter(item => item.locationId === location.id);
        }

        // if current location contains csv data, create content
        if (filteredByLocation && filteredByLocation.length) {

            // Get single location details section
            singleLocationDetails = createCsvDetailsSection(t, filteredByLocation[0]);

            // Get single location row headers
            singleLocationRowHeaders = createCsvLocationRowHeaders(t);

            // Get single location content rows
            singleLocationRows = createCSVContentSection(filteredByLocation);

            // Push all data to csv content
            csvLocationContent = csvLocationContent.concat(singleLocationDetails);
            csvLocationContent = csvLocationContent.concat(singleLocationRowHeaders);
            csvLocationContent = csvLocationContent.concat(singleLocationRows);

        }

    });

    // Create CSV Footer
    csvFooter = createCsvFooterSection(t);

    let temp1 = csv.concat(csvHeader);
    let temp2 = temp1.concat(csvLocationContent);
    let temp3 = temp2.concat(csvFooter);

    return temp3;

}

/** Function adds riskfactor and category
 * @param {Array} evaluations - Array or evaluations
 * @param {Array} factors - Array or risk factors
 * @param {Array} categories - Array or risk factors categories
*/
    const mapEvaluations = (evaluations, factors, categories) => {
    let mappedEvaluations = [];

    // Add risk factor
    if (evaluations && evaluations.length) {
        mappedEvaluations = evaluations.map(evaluation => {
            if (factors) {
                evaluation.riskFactor = factors.find(riskFactor => {
                    return riskFactor.id === evaluation.riskFactorId
                });
            }
            return evaluation;
        });
    }

    // Add category
    mappedEvaluations = mappedEvaluations.map((evaluation) => {
        if (categories && evaluation.riskFactor) {
            evaluation.riskFactorCategory = categories.find(category => {
                return category.id === evaluation.riskFactor.categoryId;
            });
        }
        return evaluation;
    });  

    return mappedEvaluations;
}

/** Order evaluations by risk factor
 * @param {Array} evaluations - mapped evaluations array */
const sortEvaluations = (evaluations) => {
    const sortedEvaluations = evaluations.sort((a,b) => b.riskImpact - a.riskImpact);
    return sortedEvaluations;
}

/** Filter evaluations which are NOT completed.
 * @param {Array} evaluations - sorted and mapped evaluations array */
    const filterEvaluations = (evaluations) => {
    const filteredEvaluations = evaluations.filter(item => item.evaluationCompleted === true);
    return filteredEvaluations;
}

/** Function map, sort and filter evaluations to be used in the report page
 * @param {Array} evaluations - sorted and mapped evaluations array
 * @param {Array} factors - risk factors array
 * @param {Array} categories - risk factor categories array */
    const modifyEvaluations = (evaluations, factors, categories) => {
    const mappedEvaluations = mapEvaluations(evaluations, factors, categories);
    const sortedAndMappedEvaluations = sortEvaluations(mappedEvaluations);
    const filteredEvaluations = filterEvaluations(sortedAndMappedEvaluations);
    return filteredEvaluations;
}    

export {
    createLocationOptions,
    createCsvHeaders,
    createEmptyCsvHeaders,
    createLocationsCsvReport,
    modifyEvaluations
}