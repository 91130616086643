import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, Prompt } from 'react-router-dom';
import { Form, Input, Container, Checkbox, TextArea } from 'semantic-ui-react';
import { CancelButton, SubmitButton } from '../../../Reusable/Buttons';
import { AuthContext } from './../../../../Context/AuthContext';
import useUpdateCompanyManagementSystems from './../../../../Hooks/Company/useUpdateCompanyManagementSystems';
import useReadCompanyManagementSystems from './../../../../Hooks/Company/useReadCompanyManagementSystems';
import Forbidden from './../../../Pages/Forbidden';
import { Loading, PageTitle, Info } from './../../../Reusable/Elements';
import DatePicker, { registerLocale } from "react-datepicker";
import utils from './../../../../Utils/utils';
import "react-datepicker/dist/react-datepicker.css";
import fi from 'date-fns/locale/fi';
registerLocale('fi', fi);

const UpdateManagementSystems = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    //const { globalState } = useContext(StateContext);

    const { managementSystems, readCompanyManagementSystems } = useReadCompanyManagementSystems();
    const { updateCompanyManagementSystems } = useUpdateCompanyManagementSystems();
    const { userProfile } = useContext(AuthContext);

    const [ id, setId ] = useState(null);
    //const [ companyId, setCompanyId ] = useState(null);
    const [ labourProtectionOfficer, setLabourProtectionOfficer ] = useState("");
    const [ labourProtectionRepresentative, setLabourProtectionRepresentative ] = useState("");

    const [ occupationalSafetyAndHealthPolicyDiscussed, setOccupationalSafetyAndHealthPolicyDiscussed ] = useState(null);
    const [ occupationalHealthServicesDiscussed, setOccupationalHealthServicesDiscussed ] = useState(null);
    const [ occupationalHealthServicesContractDiscussed, setOccupationalHealthServicesContractDiscussed ] = useState(null);
    const [ occupationalHealthServicesWorkplaceSurveyDiscussed, setOccupationalHealthServicesWorkplaceSurveyDiscussed ] = useState(null);
    const [ drugAndAlcoholAbusePreventionPlanDiscussed, setDrugAndAlcoholAbusePreventionPlanDiscussed ] = useState(null);
    const [ earlyInterventionDiscussed, setEarlyInterventionDiscussed ] = useState(null);
    const [ familiarisedAndEmergencyPlanHaveBeenMade, setFamiliarisedAndEmergencyPlanHaveBeenMade ] = useState(null);

    const [ informationToIdentifyDangerousChemicals, setInformationToIdentifyDangerousChemicals ] = useState("");
    const [ listOfDangerousChemicalsExists, setListOfDangerousChemicalsExists ] = useState(false);
    const [ dangerousChemicalPackagesAreMarkedOnReceive, setDangerousChemicalPackagesAreMarkedOnReceive ] = useState(false);
    const [ materialSafetyDataSheetsAreUpToDate, setMaterialSafetyDataSheetsAreUpToDate ] = useState(false);
    const [ workWhichRequiresCertificateOfCompetency, setWorkWhichRequiresCertificateOfCompetency ] = useState("");

    const [ isLoading, setIsLoading ] = useState(false);
    const [ formIsFilledOutAndSaved, setFormIsFilledOutAndSaved ] = useState(false);

    // Update management systems form
    const onSubmit = async () => {
        try {

            setIsLoading(true);
        
            setFormIsFilledOutAndSaved(true);

            // Dates must be converted first to date and the to ISO date
            const managementSystemsForm = {

                //company: companyId,
                labourProtectionOfficer: labourProtectionOfficer,
                labourProtectionRepresentative: labourProtectionRepresentative,

                occupationalSafetyAndHealthPolicyDiscussed: occupationalSafetyAndHealthPolicyDiscussed ? occupationalSafetyAndHealthPolicyDiscussed.toISOString() : null,
                occupationalHealthServicesDiscussed: occupationalHealthServicesDiscussed ? occupationalHealthServicesDiscussed.toISOString() : null,
                occupationalHealthServicesContractDiscussed: occupationalHealthServicesContractDiscussed ? occupationalHealthServicesContractDiscussed.toISOString() : null,
                occupationalHealthServicesWorkplaceSurveyDiscussed: occupationalHealthServicesWorkplaceSurveyDiscussed ? occupationalHealthServicesWorkplaceSurveyDiscussed.toISOString() : null,
                drugAndAlcoholAbusePreventionPlanDiscussed: drugAndAlcoholAbusePreventionPlanDiscussed ? drugAndAlcoholAbusePreventionPlanDiscussed.toISOString() : null,
                earlyInterventionDiscussed: earlyInterventionDiscussed ? earlyInterventionDiscussed.toISOString() : null,
                familiarisedAndEmergencyPlanHaveBeenMade: familiarisedAndEmergencyPlanHaveBeenMade ? familiarisedAndEmergencyPlanHaveBeenMade.toISOString() : null,

                informationToIdentifyDangerousChemicals: informationToIdentifyDangerousChemicals,
                listOfDangerousChemicalsExists: listOfDangerousChemicalsExists,
                dangerousChemicalPackagesAreMarkedOnReceive: dangerousChemicalPackagesAreMarkedOnReceive,
                materialSafetyDataSheetsAreUpToDate: materialSafetyDataSheetsAreUpToDate,
                workWhichRequiresCertificateOfCompetency: workWhichRequiresCertificateOfCompetency
            }

            // Add id if management form is found, else new is created
            if (id) managementSystemsForm.id = id;

            const result =  await updateCompanyManagementSystems(managementSystemsForm);

            if (result.status === 200 || result.status === 201) {
                await readCompanyManagementSystems(); // update global state!
                return history.goBack();
            };

            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
        }
    }

    useEffect(() =>{
        //console.log("scroll to top");
        //window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        const ms = document.getElementById('managementsystems');
        if (ms) ms.scrollIntoView();
    }, []);

    // 1. Fetch company management systems => Moved to global state
    useEffect(() => {
        (async () => {
            await readCompanyManagementSystems();
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // 2. Set data (if form is not save before, row is not found and api return 404 with error code -1)
    useEffect(() => {
        if (managementSystems && managementSystems.id) {
            setId(managementSystems.id);
            //setCompanyId(managementSystems.company);
            if (managementSystems.labourProtectionOfficer) setLabourProtectionOfficer(managementSystems.labourProtectionOfficer);
            if (managementSystems.labourProtectionRepresentative) setLabourProtectionRepresentative(managementSystems.labourProtectionRepresentative);

            if (managementSystems.occupationalSafetyAndHealthPolicyDiscussed) setOccupationalSafetyAndHealthPolicyDiscussed(new Date(managementSystems.occupationalSafetyAndHealthPolicyDiscussed));
            if (managementSystems.occupationalHealthServicesDiscussed) setOccupationalHealthServicesDiscussed(new Date(managementSystems.occupationalHealthServicesDiscussed));
            if (managementSystems.occupationalHealthServicesContractDiscussed) setOccupationalHealthServicesContractDiscussed(new Date(managementSystems.occupationalHealthServicesContractDiscussed));
            if (managementSystems.occupationalHealthServicesWorkplaceSurveyDiscussed) setOccupationalHealthServicesWorkplaceSurveyDiscussed(new Date(managementSystems.occupationalHealthServicesWorkplaceSurveyDiscussed));
            if (managementSystems.drugAndAlcoholAbusePreventionPlanDiscussed) setDrugAndAlcoholAbusePreventionPlanDiscussed(new Date(managementSystems.drugAndAlcoholAbusePreventionPlanDiscussed));
            if (managementSystems.earlyInterventionDiscussed) setEarlyInterventionDiscussed(new Date(managementSystems.earlyInterventionDiscussed));
            if (managementSystems.familiarisedAndEmergencyPlanHaveBeenMade) setFamiliarisedAndEmergencyPlanHaveBeenMade(new Date(managementSystems.familiarisedAndEmergencyPlanHaveBeenMade));

            if (managementSystems.informationToIdentifyDangerousChemicals) setInformationToIdentifyDangerousChemicals(managementSystems.informationToIdentifyDangerousChemicals || "");
            if (managementSystems.listOfDangerousChemicalsExists) setListOfDangerousChemicalsExists(managementSystems.listOfDangerousChemicalsExists || false);
            if (managementSystems.dangerousChemicalPackagesAreMarkedOnReceive) setDangerousChemicalPackagesAreMarkedOnReceive(managementSystems.dangerousChemicalPackagesAreMarkedOnReceive || false);
            if (managementSystems.materialSafetyDataSheetsAreUpToDate) setMaterialSafetyDataSheetsAreUpToDate(managementSystems.materialSafetyDataSheetsAreUpToDate|| false);
            if (managementSystems.workWhichRequiresCertificateOfCompetency) setWorkWhichRequiresCertificateOfCompetency(managementSystems.workWhichRequiresCertificateOfCompetency || "");
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [managementSystems]);

    if (!userProfile || !userProfile.isAdminUser) return <Forbidden />;

    return (
        <Form id="managementsystems" className="managementsystems" onSubmit={onSubmit}>

            <Prompt when={!formIsFilledOutAndSaved} message={t('warnings.leaveForm')} />

            <Loading active={ isLoading } text={t('general.loading')} />

            <PageTitle title={t('managementsystems.update')} hideInfo/>

            <Info text={t('managementsystems.help')} />

            <h2>{t('managementsystems.labourProtectionCollaboration')}</h2>

            <Form.Field
                focus
                maxLength="255"
                control={Input}
                label={t('managementsystems.labourProtectionOfficer')}
                value={labourProtectionOfficer}
                onChange={(ev, {value}) => setLabourProtectionOfficer(value) }
                type="Text"
            />

            <Form.Field
                maxLength="255"
                control={Input}
                label={t('managementsystems.labourProtectionRepresentative')}
                value={labourProtectionRepresentative}
                onChange={(ev, {value}) => setLabourProtectionRepresentative(value) }
                type="Text"
            />

            <h2>{t('managementsystems.occupationalSafetyAndHealthPolicyDiscussed')}</h2>

            <Form.Field label={t('managementsystems.discussedAtCollaborationMeeting')} />
            <DatePicker 
                label={t('managementsystems.discussedAtCollaborationMeeting')}
                selected={occupationalSafetyAndHealthPolicyDiscussed}
                dateFormat={"dd.MM.yyyy"}
                locale="fi"
                onChange={(date) => {
                    date = utils.setDateTimeToCurrentTime(date);
                    setOccupationalSafetyAndHealthPolicyDiscussed(date)}
                }
            />

            <h2>{t('managementsystems.occupationalHealthServicesDiscussed')}</h2>
            
            <Form.Field label={t('managementsystems.discussedAtCollaborationMeeting')} />
            <DatePicker 
                label={t('managementsystems.discussedAtCollaborationMeeting')}
                selected={occupationalHealthServicesDiscussed}
                dateFormat={"dd.MM.yyyy"}
                locale="fi"
                onChange={(date) => {
                    date = utils.setDateTimeToCurrentTime(date);
                    setOccupationalHealthServicesDiscussed(date)}
                }
            />

            <h2>{t('managementsystems.occupationalHealthServicesContractDiscussed')}</h2>
            
            <Form.Field label={t('managementsystems.discussedAtCollaborationMeeting')} />
            <DatePicker 
                label={t('managementsystems.discussedAtCollaborationMeeting')}
                selected={occupationalHealthServicesContractDiscussed}
                dateFormat={"dd.MM.yyyy"}
                locale="fi"
                onChange={(date) => {
                    date = utils.setDateTimeToCurrentTime(date);
                    setOccupationalHealthServicesContractDiscussed(date)}
                }
            />

            <h2>{t('managementsystems.occupationalHealthServicesWorkplaceSurveyDiscussed')}</h2>
            <Form.Field label={t('managementsystems.discussedAtCollaborationMeeting')} />
            <DatePicker 
                label={t('managementsystems.discussedAtCollaborationMeeting')}
                selected={occupationalHealthServicesWorkplaceSurveyDiscussed}
                dateFormat={"dd.MM.yyyy"}
                locale="fi"
                onChange={(date) => {
                    date = utils.setDateTimeToCurrentTime(date);
                    setOccupationalHealthServicesWorkplaceSurveyDiscussed(date)}
                }
            />

            <h2>{t('managementsystems.drugAndAlcoholAbusePreventionPlanDiscussed')}</h2>
            <Form.Field label={t('managementsystems.discussedAtCollaborationMeeting')} />
            <DatePicker 
                label={t('managementsystems.discussedAtCollaborationMeeting')}
                selected={drugAndAlcoholAbusePreventionPlanDiscussed}
                dateFormat={"dd.MM.yyyy"}
                locale="fi"
                onChange={(date) => {
                    date = utils.setDateTimeToCurrentTime(date);
                    setDrugAndAlcoholAbusePreventionPlanDiscussed(date)}
                }
            />

            <h2>{t('managementsystems.earlyInterventionDiscussed')}</h2>
            <Form.Field label={t('managementsystems.discussedAtCollaborationMeeting')} />
            <DatePicker 
                label={t('managementsystems.discussedAtCollaborationMeeting')}
                selected={earlyInterventionDiscussed}
                dateFormat={"dd.MM.yyyy"}
                locale="fi"
                onChange={(date) => {
                    date = utils.setDateTimeToCurrentTime(date);
                    setEarlyInterventionDiscussed(date)}
                }
            />

            <h2>{t('managementsystems.familiarisedAndEmergencyPlanHaveBeenMade')}</h2>
            <Form.Field label={t('managementsystems.discussedAtCollaborationMeeting')} />
            <DatePicker 
                label={t('managementsystems.discussedAtCollaborationMeeting')}
                selected={familiarisedAndEmergencyPlanHaveBeenMade}
                dateFormat={"dd.MM.yyyy"}
                locale="fi"
                onChange={(date) => {
                    date = utils.setDateTimeToCurrentTime(date);
                    setFamiliarisedAndEmergencyPlanHaveBeenMade(date)}
                }
            />

            <h2>{t('managementsystems.informationToIdentifyDangerousChemicals')}</h2>
            <Form.Field
                control={Checkbox}
                label={t('managementsystems.listOfDangerousChemicalsExists')}
                //placeholder={'Checkbox'}
                checked={listOfDangerousChemicalsExists}
                onChange={(ev, {checked}) => setListOfDangerousChemicalsExists(checked) }
            />
            <Form.Field
                control={Checkbox}
                label={t('managementsystems.dangerousChemicalPackagesAreMarkedOnReceive')}
                //placeholder={'Checkbox'}
                checked={dangerousChemicalPackagesAreMarkedOnReceive}
                onChange={(ev, {checked}) => setDangerousChemicalPackagesAreMarkedOnReceive(checked) }
            />
            <Form.Field
                control={Checkbox}
                label={t('managementsystems.materialSafetyDataSheetsAreUpToDate')}
                //placeholder={'Checkbox'}
                checked={materialSafetyDataSheetsAreUpToDate}
                onChange={(ev, {checked}) => setMaterialSafetyDataSheetsAreUpToDate(checked) }
            />

            <Form.Field
                //maxLength="255"
                control={TextArea}
                label={t('managementsystems.workWhichRequiresCertificateOfCompetency')}
                value={workWhichRequiresCertificateOfCompetency}
                onChange={(ev, {value}) => setWorkWhichRequiresCertificateOfCompetency(value) }
                type="Text"
            />

            {/* Buttons */}
            <Container textAlign="right">
                <SubmitButton icon="save" color="green" title={t('general.save')} />
                <CancelButton />
            </Container>

        </Form>
    );

}

export default UpdateManagementSystems;
