import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Container, TextArea } from 'semantic-ui-react';
import { SubmitButton, CancelButton } from '../../Reusable/Buttons';
import { Loading, PageTitle } from '../../Reusable/Elements';
import { StateContext } from '../../../Context/StateContext';
import { Slider } from "react-semantic-ui-range";
import {YesNoModal} from '../../Reusable/Modals';
import NavigationPrompt from "react-router-navigation-prompt";
import useReadEvaluation from './../../../Hooks/Evaluation/useReadEvaluation';
import useUpdateEvaluation from './../../../Hooks/Evaluation/useUpdateEvaluation';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import utils from './../../../Utils/utils';
import fi from 'date-fns/locale/fi';
registerLocale('fi', fi);

const EditRiskFactorEvaluation = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const { id } = useParams();
    const { globalState } = useContext(StateContext);
    const { evaluation, readEvaluation} = useReadEvaluation();
    const { updateEvaluation} = useUpdateEvaluation();

    const [ inspectionId, setInspectionId ] = useState(null);
    const [ riskFactorId, setRiskFactorId ] = useState(null);
    const [ description, setDescription ] = useState("");
    //const [ recognized, setRecognized ] = useState("");
    const [ riskProbability, setRiskProbability ] = useState(0);
    const [ riskConsequences, setRiskConsequences ] = useState(0);
    const [ riskImpact, setRiskImpact ] = useState(0);
    const [ proceeding, setProceeding ] = useState("");
    const [ riskProbabilityAfterProceeding, setRiskProbabilityAfterProceeding ] = useState(0);
    const [ riskConsequencesAfterProceeding, setRiskConsequencesAfterProceeding ] = useState(0);
    const [ riskImpactAfterProceeding, setRiskImpactAfterProceeding ] = useState(0);
    const [ responsiblePerson, setResponsiblePerson ] = useState("");
    const [ schedule, setSchedule ] = useState(new Date());

    const [ modalIsOpen, setModalIsOpen ] = useState(false);
    const [ formIsFilledOutAndSaved, setFormIsFilledOutAndSaved ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);

    const riskProbabilitySliderSettings = {
        start: 0,
        min: 0,
        max: 5,
        step: 1,
        onChange: value => {
            setRiskProbability(value);
        }
    };
    const riskConsequencesSliderSettings = {
        start: 0,
        min: 0,
        max: 5,
        step: 1,
        onChange: value => {
            setRiskConsequences(value);
        }
    };
    const riskProbabilityAfterProceedingSliderSettings = {
        start: 0,
        min: 0,
        max: 5,
        step: 1,
        onChange: value => {
            setRiskProbabilityAfterProceeding(value);
        }
    };
    const riskConsequencesAfterProceedingSliderSettings = {
        start: 0,
        min: 0,
        max: 5,
        step: 1,
        onChange: value => {
            setRiskConsequencesAfterProceeding(value);
        }
    };

    useEffect(() => {
        // Calculate risk impact when probability or consequences change
        setRiskImpact(riskProbability * riskConsequences);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[riskProbability, riskConsequences]);

    useEffect(() => {
        // Calculate risk impact after proceeding when probability or consequences change
        if (proceeding && proceeding.length > 0) setRiskImpactAfterProceeding(riskProbabilityAfterProceeding * riskConsequencesAfterProceeding);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[riskProbabilityAfterProceeding, riskConsequencesAfterProceeding]);

    useEffect(() => {
        // Fetch evaluation
        if (id) {
            (async () => {
                setIsLoading(true);
                await readEvaluation(id);
            })();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        // Set evaluation data when fetched
        if (evaluation) {
            setInspectionId(evaluation.inspectionId);
            setRiskFactorId(evaluation.riskFactorId)
            setDescription(evaluation.description);
            //setRecognized(evaluation.recognized);
            setRiskProbability(evaluation.riskProbability);
            setRiskConsequences(evaluation.riskConsequences);
            setRiskImpact(evaluation.riskImpact);
            setProceeding(evaluation.proceeding);
            setRiskProbabilityAfterProceeding(evaluation.riskProbabilityAfterProceeding);
            setRiskConsequencesAfterProceeding(evaluation.riskConsequencesAfterProceeding);
            setRiskImpactAfterProceeding(evaluation.riskImpactAfterProceeding);
            setResponsiblePerson(evaluation.responsiblePerson);
            if (evaluation.schedule) setSchedule(new Date(evaluation.schedule));
            setIsLoading(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [evaluation]);

    const onUpdateEvaluation = async () => {

        setFormIsFilledOutAndSaved(true);
        setIsLoading(true);

        const evaluation = {
            "id": id,
            "inspectionId": inspectionId,
            "riskFactorId": riskFactorId,
            "description": description,
            //"recognized": recognized,
            "riskProbability": riskProbability,
            "riskConsequences": riskConsequences,
            "riskImpact": riskImpact,
            "proceeding": proceeding,
            "riskProbabilityAfterProceeding": riskProbabilityAfterProceeding,
            "riskConsequencesAfterProceeding": riskConsequencesAfterProceeding,
            "riskImpactAfterProceeding": riskImpactAfterProceeding,
            "responsiblePerson": responsiblePerson,
            "schedule": schedule ? schedule.toISOString() : null,
            "evaluationCompleted": true,
            "isEvaluated": true
        }

        let result = await updateEvaluation(evaluation)

        if (result.status === 200 || result.status === 201 || result.status === 204) return history.goBack();

        setIsLoading(false);
        setFormIsFilledOutAndSaved(false);

    }

    return (
        <div className="inspection">
            <Form onSubmit={onUpdateEvaluation}>

                <Loading active={ isLoading } text={t('general.loading')} />

                <PageTitle title={t('evaluation.editRiskFactorEvaluation')} href="/info" />

                <NavigationPrompt
                    when={!formIsFilledOutAndSaved}
                >
                    {({ isActive, onCancel, onConfirm }) => (
                        <YesNoModal
                            type="warning"
                            header={t('warnings.title')}
                            text={t('warnings.leaveForm')}
                            method={(answer) => {
                                if (!answer) onCancel();
                                if (answer) onConfirm();
                            }}
                            //item={null}
                            open={isActive || modalIsOpen}
                            setOpen={setModalIsOpen}
                            noClick={true}
                        />
                    )}
                </NavigationPrompt>

                {/* Risk description */}
                <Form.Field
                    maxLength="255"
                    rows={2}
                    control={TextArea}
                    label={t('evaluation.description')}
                    value={description || ""}
                    onChange={(ev, {value}) => setDescription(value) }
                    type="Text"
                />

                {/* Risk propability */}
                <Container className="evaluation-container">

                    <Form.Field label={t('evaluation.riskProbability')} />

                    {riskProbability === 0 && <p>{ t('evaluation.probability0') + " (" + riskProbability + ")"}</p>}
                    {riskProbability === 1 && <p>{ t('evaluation.probability1') + " (" + riskProbability + ")"}</p>}
                    {riskProbability === 2 && <p>{ t('evaluation.probability2') + " (" + riskProbability + ")"}</p>}
                    {riskProbability === 3 && <p>{ t('evaluation.probability3') + " (" + riskProbability + ")"}</p>}
                    {riskProbability === 4 && <p>{ t('evaluation.probability4') + " (" + riskProbability + ")"}</p>}
                    {riskProbability === 5 && <p>{ t('evaluation.probability5') + " (" + riskProbability + ")"}</p>}

                    <Slider discrete value={riskProbability} color="black" settings={riskProbabilitySliderSettings} />

                </Container>

                {/* Evaluate Risk consequences */}
                <Container className="evaluation-container">

                    <Form.Field label={t('evaluation.evaluateRiskConsequences')} />

                    {riskConsequences === 0 && <p>{ t('evaluation.consequence0') + " (" + riskConsequences + ")"}</p>}
                    {riskConsequences === 1 && <p>{ t('evaluation.consequence1') + " (" + riskConsequences + ")"}</p>}
                    {riskConsequences === 2 && <p>{ t('evaluation.consequence2') + " (" + riskConsequences + ")"}</p>}
                    {riskConsequences === 3 && <p>{ t('evaluation.consequence3') + " (" + riskConsequences + ")"}</p>}
                    {riskConsequences === 4 && <p>{ t('evaluation.consequence4') + " (" + riskConsequences + ")"}</p>}
                    {riskConsequences === 5 && <p>{ t('evaluation.consequence5') + " (" + riskConsequences + ")"}</p>}

                    <Slider discrete value={riskConsequences} color="black" settings={riskConsequencesSliderSettings} />
                    
                    {riskConsequences === 0 && <p>{t('evaluation.consequence0Explanation')}</p>}
                    {riskConsequences === 1 && <p>{t('evaluation.consequence1Explanation')}</p>}
                    {riskConsequences === 2 && <p>{t('evaluation.consequence2Explanation')}</p>}
                    {riskConsequences === 3 && <p>{t('evaluation.consequence3Explanation')}</p>}
                    {riskConsequences === 4 && <p>{t('evaluation.consequence4Explanation')}</p>}
                    {riskConsequences === 5 && <p>{t('evaluation.consequence5Explanation')}</p>}
                   
                </Container>

                {/* Risk Impact */}
                <Container className="evaluation-container">
                    <Form.Field label={t('evaluation.riskImpactExplination')} />
                    {(riskImpact === 0) ? <p>{"- / 25"}</p> : <p>{riskImpact} {" / 25"}</p>}
                </Container>

                {/* Risk proceeding */}
                <Container>
                    <Form.Field
                        maxLength="255"
                        rows={2}
                        control={TextArea}
                        label={t('evaluation.proceeding')}
                        value={proceeding || ""}
                        onChange={(ev, {value}) => {
                            setProceeding(value);
                            if (value.length > 0) setRiskImpactAfterProceeding(riskProbabilityAfterProceeding * riskConsequencesAfterProceeding);
                        }}
                        type="Text"
                    />
                </Container>

                {/* Responsible Person */}
                <Container className="evaluation-container">
                    <Form.Field
                        maxLength="255"
                        rows={2}
                        control={TextArea}
                        label={t('evaluation.responsiblePerson')}
                        value={responsiblePerson || ""}
                        onChange={(ev, {value}) => setResponsiblePerson(value) }
                        type="Text"
                    />
                </Container>

                {/* Schedule */}
                <Container className="evaluation-container">
                    <Form.Field label={t('evaluation.schedule')} />
                    <DatePicker 
                        label={t('evaluation.schedule')}
                        selected={schedule}
                        dateFormat={"dd.MM.yyyy"}
                        locale="fi"
                        onChange={(date) => {
                            date = utils.setDateTimeToCurrentTime(date);
                            setSchedule(date)}
                        }
                    />
                </Container>

                {/* Risk propability after proceeding*/}
                <Container className="evaluation-container">
                    
                    <Form.Field label={t('evaluation.evaluateRiskProbabilityAfterProceeding')} />

                    {riskProbabilityAfterProceeding === 0 && <p>{ t('evaluation.probability0') + " (" + riskProbabilityAfterProceeding + ")"}</p>}
                    {riskProbabilityAfterProceeding === 1 && <p>{ t('evaluation.probability1') + " (" + riskProbabilityAfterProceeding + ")"}</p>}
                    {riskProbabilityAfterProceeding === 2 && <p>{ t('evaluation.probability2') + " (" + riskProbabilityAfterProceeding + ")"}</p>}
                    {riskProbabilityAfterProceeding === 3 && <p>{ t('evaluation.probability3') + " (" + riskProbabilityAfterProceeding + ")"}</p>}
                    {riskProbabilityAfterProceeding === 4 && <p>{ t('evaluation.probability4') + " (" + riskProbabilityAfterProceeding + ")"}</p>}
                    {riskProbabilityAfterProceeding === 5 && <p>{ t('evaluation.probability5') + " (" + riskProbabilityAfterProceeding + ")"}</p>}

                    <Slider discrete value={riskProbabilityAfterProceeding} color="black" settings={riskProbabilityAfterProceedingSliderSettings} />

                </Container>

                {/* Evaluate Risk consequences after proceeding*/}
                <Container className="evaluation-container">

                    <Form.Field label={t('evaluation.evaluateRiskConsequencesAfterProceeding')} />
                    
                    {riskConsequencesAfterProceeding === 0 && <p>{ t('evaluation.consequence0') + " (" + riskConsequencesAfterProceeding + ")"}</p>}
                    {riskConsequencesAfterProceeding === 1 && <p>{ t('evaluation.consequence1') + " (" + riskConsequencesAfterProceeding + ")"}</p>}
                    {riskConsequencesAfterProceeding === 2 && <p>{ t('evaluation.consequence2') + " (" + riskConsequencesAfterProceeding + ")"}</p>}
                    {riskConsequencesAfterProceeding === 3 && <p>{ t('evaluation.consequence3') + " (" + riskConsequencesAfterProceeding + ")"}</p>}
                    {riskConsequencesAfterProceeding === 4 && <p>{ t('evaluation.consequence4') + " (" + riskConsequencesAfterProceeding + ")"}</p>}
                    {riskConsequencesAfterProceeding === 5 && <p>{ t('evaluation.consequence5') + " (" + riskConsequencesAfterProceeding + ")"}</p>}

                    <Slider discrete value={riskConsequencesAfterProceeding} color="black" settings={riskConsequencesAfterProceedingSliderSettings} />

                </Container>

                {/* Risk impact after proceeding */}
                <Container className="evaluation-container">

                    <Form.Field label={t('evaluation.evaluateRiskImpactAfterProceeding')} />

                    {(riskImpactAfterProceeding === 0) ? <p>{"- / 25"}</p> : <p>{riskImpactAfterProceeding} {" / 25"}</p>}
                   
                </Container>

                {/* Buttons */}
                <Container textAlign="right">
                    <SubmitButton icon="save" color="green" title={t('general.save')} disabled={globalState.locationId === 0 || isLoading } />
                    <CancelButton onClick={() => history.goBack()} />
                </Container>

            </Form>
        </div>
    );

}

export default EditRiskFactorEvaluation;
