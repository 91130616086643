import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import NavigationPrompt from "react-router-navigation-prompt";
import { Form, Input, Container, TextArea, Select, Accordion, Grid, Icon, Button, Transition } from 'semantic-ui-react';
import { Slider } from "react-semantic-ui-range";
import { v4 as uuidv4 } from 'uuid';
import { Loading, WizardMenu, WizardItem, PageTitle, Info } from './../../Reusable/Elements';
import { CancelButton, OpenButton, SubmitButton, NewButton, InfoButton } from './../../Reusable/Buttons';
import { YesNoModal } from './../../Reusable/Modals';
import useListLocations from './../../../Hooks/Locations/useListLocations';
import useReadLatestInspection from './../../../Hooks/Inspection/useReadLatestInspection';
import useListInspectionEvaluations from './../../../Hooks/Evaluation/useListInspectionEvaluations';
import useListRiskFactorCategories from './../../../Hooks/RiskFactorCategories/useListRiskFactorCategories';
import useListRiskFactors from './../../../Hooks/RiskFactors/useListRiskFactors';
import useCreateInspection from './../../../Hooks/Inspection/useCreateInspection';
import useCreateEvaluation from './../../../Hooks/Evaluation/useCreateEvaluation';
import useCreateRiskFactor from './../../../Hooks/RiskFactors/useCreateRiskFactor';
import { createMedia } from "@artsy/fresnel";
import DatePicker, { registerLocale } from "react-datepicker";
import utils from './../../../Utils/utils';
import "react-datepicker/dist/react-datepicker.css";
import fi from 'date-fns/locale/fi';
registerLocale('fi', fi);

const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
        sm: 0,
        md: 768,
        lg: 1024,
        xl: 1192,
    },
});

const RiskImpactInfo = ({ visible = false }) => {
    
    const { t } = useTranslation();

    return (
        <Transition visible={visible} duration={200} animation="slide down" >
            <Container style={{ padding: '20px', backgroundColor: '#efefef', borderRadius: '5px', color: 'rgba(0,0,0,.6)' }}>

                {/* Risk explanation */}
                <Form.Field label={t('evaluation.explanation')} />
                {t('evaluation.riskImpactExplination')}

                <br/><br/>

                1-4 {t('evaluation.riskImpact1')}<br/>
                5-8 {t('evaluation.riskImpact2')}<br/>
                9-14 {t('evaluation.riskImpact3')}<br/>
                15-19 {t('evaluation.riskImpact4')}<br/>
                20-25 {t('evaluation.riskImpact5')}<br/>

                <br/><br/>

                <Form.Field label={t('evaluation.riskImpact1')} />
                <p>{t('evaluation.riskImpact1Explanation')}</p>

                <Form.Field label={t('evaluation.riskImpact2')} />
                <p>{t('evaluation.riskImpact2Explanation')}</p>

                <Form.Field label={t('evaluation.riskImpact3')} />
                <p>{t('evaluation.riskImpact3Explanation')}</p>

                <Form.Field label={t('evaluation.riskImpact4')} />
                <p>{t('evaluation.riskImpact4Explanation')}</p>

                <Form.Field label={t('evaluation.riskImpact5')} />
                <p>{t('evaluation.riskImpact5Explanation')}</p>

            </Container>
        </Transition>
    )

}

/* Page 4 - Main page */
const EvaluateRiskFactor = ({
    visible,
    isLoading,
    selectedInspection,
    getEvaluation,
    editEvaluation,
    selectedPage,
    setSelectedPage,
    previousPage
}) => {

    const { t } = useTranslation();
    const scrollToTopRef = useRef();
    const [ inspectionId, setInspectionId ] = useState(null);
    const [ riskFactorId, setRiskFactorId ] = useState(null);
    const [ description, setDescription ] = useState("");
    const [ recognized, setRecognized ] = useState("");
    const [ riskProbability, setRiskProbability ] = useState(0);                                    // value must be 0 unless user moves slider
    const [ riskConsequences, setRiskConsequences ] = useState(0);                                  // value must be 0 unless user moves slider
    const [ riskImpact, setRiskImpact ] = useState(0);                                              // value must be 0 unless user moves slider
    const [ proceeding, setProceeding ] = useState("");
    const [ riskProbabilityAfterProceeding, setRiskProbabilityAfterProceeding ] = useState(0);      // value must be 0 unless user moves slider
    const [ riskConsequencesAfterProceeding, setRiskConsequencesAfterProceeding ] = useState(0);    // value must be 0 unless user moves slider
    const [ riskImpactAfterProceeding, setRiskImpactAfterProceeding ] = useState(0);                // value must be 0 unless user moves slider AND proceeding is added
    const [ responsiblePerson, setResponsiblePerson ] = useState("");
    const [ schedule, setSchedule ] = useState(null);
    const [ riskFactorInfo1IsVisible, setRiskFactorInfo1IsVisible ] = useState(false);
    const [ riskFactorInfo2IsVisible, setRiskFactorInfo2IsVisible ] = useState(false);

    const riskProbabilitySliderSettings = {
        start: 0,
        min: 0,
        max: 5,
        step: 1,
        onChange: value => {
            setRiskProbability(value);
        }
    };
    const riskConsequencesSliderSettings = {
        start: 0,
        min: 0,
        max: 5,
        step: 1,
        onChange: value => {
            setRiskConsequences(value);
        }
    };
    const riskProbabilityAfterProceedingSliderSettings = {
        start: 0,
        min: 0,
        max: 5,
        step: 1,
        onChange: value => {
            setRiskProbabilityAfterProceeding(value);
        }
    };
    const riskConsequencesAfterProceedingSliderSettings = {
        start: 0,
        min: 0,
        max: 5,
        step: 1,
        onChange: value => {
            setRiskConsequencesAfterProceeding(value);
        }
    };

    const updateInspection = async () => {
        const inspection = {
            "uuid": selectedInspection,
            "inspectionId": inspectionId,
            "riskFactorId": riskFactorId,
            "description": description,
            "recognized": recognized,
            "riskProbability": riskProbability,
            "riskConsequences": riskConsequences,
            "riskImpact": riskImpact,
            "proceeding": proceeding,
            "riskProbabilityAfterProceeding": riskProbabilityAfterProceeding,
            "riskConsequencesAfterProceeding": riskConsequencesAfterProceeding,
            "riskImpactAfterProceeding": riskImpactAfterProceeding,
            "responsiblePerson": responsiblePerson,
            "schedule": schedule,
            "evaluationCompleted": false, // completed  is set to false if user modify inspection
            "isEvaluated": true
        }

        editEvaluation(selectedInspection, inspection);

        if (previousPage === 1) setSelectedPage(1);
        if (previousPage === 2) setSelectedPage(2);

    }

    useEffect(() => {
        // Calculate risk impact when probability or consequences change
        setRiskImpact(riskProbability * riskConsequences);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[riskProbability, riskConsequences]);

    useEffect(() => {
        // Calculate risk impact after proceeding when probability or consequences change
        if (proceeding.length > 0) setRiskImpactAfterProceeding(riskProbabilityAfterProceeding * riskConsequencesAfterProceeding);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[riskProbabilityAfterProceeding, riskConsequencesAfterProceeding]);

    useEffect(() => {
        // When user enters the evaluate "page", scroll to top.
        if (scrollToTopRef && scrollToTopRef.current && selectedPage && selectedPage === 4) {
            scrollToTopRef.current.scrollIntoView();
        }  
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[visible]);

    useEffect(()=>{
        const evaluation = getEvaluation(selectedInspection);
        if (evaluation) {
            setInspectionId(evaluation.inspectionId);
            setRiskFactorId(evaluation.riskFactorId)
            setDescription(evaluation.description);
            setRecognized(evaluation.recognized);
            setRiskProbability(evaluation.riskProbability);
            setRiskConsequences(evaluation.riskConsequences);
            setRiskImpact(evaluation.riskImpact);
            setProceeding(evaluation.proceeding);
            setRiskProbabilityAfterProceeding(evaluation.riskProbabilityAfterProceeding);
            setRiskConsequencesAfterProceeding(evaluation.riskConsequencesAfterProceeding);
            setRiskImpactAfterProceeding(evaluation.riskImpactAfterProceeding);
            setResponsiblePerson(evaluation.responsiblePerson);
            setSchedule(evaluation.schedule);            
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedInspection]);

    if (!visible) return null;

    return (
        <div className="evaluate-risk-factor">

            <div ref={scrollToTopRef}></div>

            <PageTitle title={t('evaluation.evaluateriskfactor')} href="/info#evaluation" />

            <Info
                text={t('inspection.help.evaluateRiskFactor')}
                bullets={[
                    t('inspection.help.evaluateRiskFactorStep1'),
                    t('inspection.help.evaluateRiskFactorStep2'),
                    t('inspection.help.evaluateRiskFactorStep3')
                    ]}
                more={[
                    t('inspection.help.remenberToSaveOften')
                ]}
            />

            <Loading active={ isLoading } text={t('general.loading')} />

            {/* Evaluation completed prompt is moved!. Before evaluation completed was asked for every evaluation. Now only once when saved.
                <YesNoModal
                    type="ready"
                    header={t('evaluation.evaluation')}
                    text={
                        <div>
                            <h3>{t('evaluation.isEvaluationCompleted')}</h3><br/>
                            <p>{t('evaluation.completedEvaluationIsEditable')}</p>
                            <p>{t('evaluation.onlyCompletedEvaluationsAreVisibleOnReports')}</p>
                        </div>
                    }
                    method={(answer, id) => {
                        updateInspection(answer);
                    }}
                    //item={""}
                    open={open}
                    setOpen={setOpen}
                    noClick={true}
                />
            */}

            {/* Risk description */}
            <Form.Field
                maxLength="255"
                rows={2}
                control={TextArea}
                label={t('evaluation.description')}
                value={description || ""}
                onChange={(ev, {value}) => setDescription(value) }
                type="Text"
            />

            {/* Risk propability */}
            <Container className="evaluation-container">

                <Form.Field label={t('evaluation.riskProbability')} />

                {riskProbability === 0 && <p>{ t('evaluation.probability0') + " (" + riskProbability + ")"}</p>}
                {riskProbability === 1 && <p>{ t('evaluation.probability1') + " (" + riskProbability + ")"}</p>}
                {riskProbability === 2 && <p>{ t('evaluation.probability2') + " (" + riskProbability + ")"}</p>}
                {riskProbability === 3 && <p>{ t('evaluation.probability3') + " (" + riskProbability + ")"}</p>}
                {riskProbability === 4 && <p>{ t('evaluation.probability4') + " (" + riskProbability + ")"}</p>}
                {riskProbability === 5 && <p>{ t('evaluation.probability5') + " (" + riskProbability + ")"}</p>}

                <Slider discrete value={riskProbability} color="black" settings={riskProbabilitySliderSettings} />

            </Container>

            {/* Evaluate Risk consequences */}
            <Container className="evaluation-container">

                <Form.Field label={t('evaluation.evaluateRiskConsequences')} />

                {riskConsequences === 0 && <p>{ t('evaluation.consequence0') + " (" + riskConsequences + ")"}</p>}
                {riskConsequences === 1 && <p>{ t('evaluation.consequence1') + " (" + riskConsequences + ")"}</p>}
                {riskConsequences === 2 && <p>{ t('evaluation.consequence2') + " (" + riskConsequences + ")"}</p>}
                {riskConsequences === 3 && <p>{ t('evaluation.consequence3') + " (" + riskConsequences + ")"}</p>}
                {riskConsequences === 4 && <p>{ t('evaluation.consequence4') + " (" + riskConsequences + ")"}</p>}
                {riskConsequences === 5 && <p>{ t('evaluation.consequence5') + " (" + riskConsequences + ")"}</p>}

                <Slider discrete value={riskConsequences} color="black" settings={riskConsequencesSliderSettings} />
                
                {riskConsequences === 0 && <p>{t('evaluation.consequence0Explanation')}</p>}
                {riskConsequences === 1 && <p>{t('evaluation.consequence1Explanation')}</p>}
                {riskConsequences === 2 && <p>{t('evaluation.consequence2Explanation')}</p>}
                {riskConsequences === 3 && <p>{t('evaluation.consequence3Explanation')}</p>}
                {riskConsequences === 4 && <p>{t('evaluation.consequence4Explanation')}</p>}
                {riskConsequences === 5 && <p>{t('evaluation.consequence5Explanation')}</p>}
                
            </Container>

            {/* Risk Impact */}
            <Container className="evaluation-container">

                <Form.Field label={
                    <>
                        {t('evaluation.riskImpactExplination')}
                        <InfoButton style={{ marginLeft: '10px' }} circular icon='help' onClick={()=>setRiskFactorInfo1IsVisible(!riskFactorInfo1IsVisible)} />
                    </>
                } />

                {(riskImpact === 0) ? <p>{"- / 25"}</p> : <p>{riskImpact} {" / 25"}</p>}

                {/* Info */}
                <RiskImpactInfo visible={riskFactorInfo1IsVisible} />

            </Container>

            {/* Risk proceeding */}
            <Container>
                <Form.Field
                    rows={2}
                    control={TextArea}
                    label={t('evaluation.proceeding')}
                    value={proceeding || ""}
                    onChange={(ev, {value}) => {
                        setProceeding(value)
                        if (value.length > 0) setRiskImpactAfterProceeding(riskProbabilityAfterProceeding * riskConsequencesAfterProceeding);
                    }}
                    type="Text"
                />
            </Container>
            
            {/* Responsible Person */}
            <Container className="evaluation-container">
                <Form.Field
                    maxLength="255"
                    rows={2}
                    control={TextArea}
                    label={t('evaluation.responsiblePerson')}
                    value={responsiblePerson || ""}
                    onChange={(ev, {value}) => setResponsiblePerson(value) }
                    type="Text"
                />
            </Container>

            {/* Schedule */}
            <Container className="evaluation-container">
                <Form.Field label={t('evaluation.schedule')} />
                <DatePicker
                    label={t('evaluation.schedule')}
                    selected={schedule}
                    dateFormat={"dd.MM.yyyy"}
                    locale="fi"
                    onChange={(date) => {
                        date = utils.setDateTimeToCurrentTime(date);
                        setSchedule(date);
                    }}
                />
            </Container>

            {/* Risk propability after proceeding*/}
            <Container className="evaluation-container">
                
                <Form.Field label={t('evaluation.evaluateRiskProbabilityAfterProceeding')} />

                {riskProbabilityAfterProceeding === 0 && <p>{ t('evaluation.probability0') + " (" + riskProbabilityAfterProceeding + ")"}</p>}
                {riskProbabilityAfterProceeding === 1 && <p>{ t('evaluation.probability1') + " (" + riskProbabilityAfterProceeding + ")"}</p>}
                {riskProbabilityAfterProceeding === 2 && <p>{ t('evaluation.probability2') + " (" + riskProbabilityAfterProceeding + ")"}</p>}
                {riskProbabilityAfterProceeding === 3 && <p>{ t('evaluation.probability3') + " (" + riskProbabilityAfterProceeding + ")"}</p>}
                {riskProbabilityAfterProceeding === 4 && <p>{ t('evaluation.probability4') + " (" + riskProbabilityAfterProceeding + ")"}</p>}
                {riskProbabilityAfterProceeding === 5 && <p>{ t('evaluation.probability5') + " (" + riskProbabilityAfterProceeding + ")"}</p>}

                <Slider
                    discrete
                    value={riskProbabilityAfterProceeding}
                    color="black"
                    settings={riskProbabilityAfterProceedingSliderSettings}
                />

            </Container>


            {/* Evaluate Risk consequences after proceeding*/}
            <Container className="evaluation-container">

                <Form.Field label={t('evaluation.evaluateRiskConsequencesAfterProceeding')} />
                
                {riskConsequencesAfterProceeding === 0 && <p>{ t('evaluation.consequence0') + " (" + riskConsequencesAfterProceeding + ")"}</p>}
                {riskConsequencesAfterProceeding === 1 && <p>{ t('evaluation.consequence1') + " (" + riskConsequencesAfterProceeding + ")"}</p>}
                {riskConsequencesAfterProceeding === 2 && <p>{ t('evaluation.consequence2') + " (" + riskConsequencesAfterProceeding + ")"}</p>}
                {riskConsequencesAfterProceeding === 3 && <p>{ t('evaluation.consequence3') + " (" + riskConsequencesAfterProceeding + ")"}</p>}
                {riskConsequencesAfterProceeding === 4 && <p>{ t('evaluation.consequence4') + " (" + riskConsequencesAfterProceeding + ")"}</p>}
                {riskConsequencesAfterProceeding === 5 && <p>{ t('evaluation.consequence5') + " (" + riskConsequencesAfterProceeding + ")"}</p>}

                <Slider
                    discrete
                    value={riskConsequencesAfterProceeding}
                    color="black"
                    settings={riskConsequencesAfterProceedingSliderSettings}
                />

            </Container>

            {/* Risk impact after proceeding */}
            <Container className="evaluation-container">

                <Form.Field label={
                    <>
                        {t('evaluation.evaluateRiskImpactAfterProceeding')}
                        <InfoButton
                            style={{ marginLeft: '10px' }}
                            circular
                            icon='help'
                            onClick={() => setRiskFactorInfo2IsVisible(!riskFactorInfo2IsVisible)}
                        />
                    </>
                }/>

                {(riskImpactAfterProceeding === 0) ? <p>{"- / 25"}</p> : <p>{riskImpactAfterProceeding} {" / 25"}</p>}
            
                {/* Info */}
                <RiskImpactInfo visible={riskFactorInfo2IsVisible} />

            </Container>


            {/* Buttons */}
            <Container textAlign="right">
                <SubmitButton
                    icon="check"
                    color="green"
                    title={t('general.ok')}
                    //onClick={() => setOpen(true)}
                    onClick={() => updateInspection() }
                    type="reset"
                />
                <CancelButton onClick={() => {
                    if (previousPage === 1) setSelectedPage(1);
                    if (previousPage === 2) setSelectedPage(2);
                }} />
            </Container>
            
        </div>
    );

}

/* Page 3 - Main page */
const CreateRiskFactor = ({
    visible,
    isLoading,
    setSelectedPage,
    setIsLoading,
    selectedLocation,
    listRiskFactors
}) => {

    const { t } = useTranslation();
    const { createRiskFactor } = useCreateRiskFactor();
    const [ name, setName ] = useState("");
    //const [ description, setDescription ] = useState("");
    const [ infoText, setInfoText ] = useState("");
    const [ nameError, setNameError ] = useState(false);
    const [ infoError, setInfoError ] = useState(false);

    const saveRiskFactor = async (ev) => {

        if (!name) return setNameError(true);
        if (!infoText) return setInfoError(true);
        
        setIsLoading(true);

        const newRiskFactor = {
            locationId: selectedLocation,
            name: name,
            description: infoText,
            infoText: infoText
        }

        const result = await createRiskFactor(newRiskFactor);

        if (result.status === 200 || result.status === 201) {
            await listRiskFactors(selectedLocation);
            setSelectedPage(1);
        }

        setIsLoading(false);

    }

    if (!visible) return null;

    return (
        <div className="create-risk-factor">

            <PageTitle title={t('inspection.createNewRiskFactor')} href="/info#risk-factor" />

            {/* Title */}
            <Form.Field
                required
                maxLength="255"
                control={Input}
                label={t('riskFactors.name')}
                placeholder={t('riskFactors.hazardouschemicals')}
                value={name}
                onChange={(ev, {value}) => {
                    setName(value);
                    setNameError(false);
                }}
                type="Text"
                error={ nameError }
            />

            {/* Description 
            <Form.Field
                required
                maxLength="255"
                control={TextArea}
                label={t('riskFactors.description')}
                value={description}
                onChange={(ev, {value}) => setDescription(value) }
                type="Text"
            />*/}

            {/* Info text */}
            <Form.Field
                required
                maxLength="255"
                control={TextArea}
                label={t('riskFactors.infoText')}
                placeholder={t('riskFactorInfotexts.hazardouschemicals')}
                value={infoText}
                onChange={(ev, {value}) => {
                    setInfoError(false);
                    setInfoText(value);
                }}
                type="Text"
                error={ infoError }
            />

            {/* Buttons */}
            <Container textAlign="right">
                <SubmitButton type="reset" icon="save" color="green" title={t('general.save')} onClick={saveRiskFactor}/>
                <CancelButton onClick={() => setSelectedPage(1)} />
            </Container>

        </div>
    );

}

/* Page 2 - Accordion list content */
const ListEvaluatedRiskFactorsCategoryContent = ({
    evaluation,
    setSelectedPage,
    setSelectedInspection,
    setPreviousPage
}) => {

    const { t } = useTranslation();

    return (
            <Accordion.Content>

                <p>{evaluation.description}</p>
                
                <OpenButton
                    title={t('evaluation.evaluateriskfactor')}
                    color={"green"}
                    icon='arrow left'
                    type="reset"
                    //disabled={!globalState.locationId}
                    onClick={() => {
                        setSelectedInspection(evaluation.uuid);
                        setPreviousPage(2);
                        setSelectedPage(4);
                    }}
                />

                <br/><br/>

            </Accordion.Content>
    );

}

/* Page 2 - Accordion list title */
const ListEvaluatedRiskFactorsCategoryTitle = ({
    riskFactorCategory,
    riskFactor,
    evaluation,
    index,
    activeIndex,
    setActiveIndex
}) => {

    const { t } = useTranslation();

    return (
        <Accordion.Title
            active={activeIndex === index}
            index={index}
            onClick={(ev, data) => {
                if (activeIndex === index) setActiveIndex(null);
                if (activeIndex !== index) setActiveIndex(index);
            }}
        >
            <Grid>
                <Grid.Row columns={2}>
                    <Grid.Column verticalAlign={"middle"} style={{ width: "10px !important" }}>
                        <div style={{ marginLeft: "30px" }}>
                            {riskFactorCategory && t('riskFactorCategories.' + riskFactorCategory.referenceName)}
                        </div>
                        <div>
                            <p>
                        
                                {/* Common category risk factors have reference name */}
                                {riskFactorCategory &&  evaluation.evaluationCompleted && riskFactorCategory.id !== 5 &&
                                    <>
                                        <Icon name="checkmark" color="green" />
                                        <span style={{ marginLeft: "5px" }}>{riskFactor && t('riskFactors.' + riskFactor.referenceName)}</span>
                                    </>                                
                                }
                                {riskFactorCategory &&  !evaluation.evaluationCompleted && riskFactorCategory.id !== 5 &&
                                    <span style={{ marginLeft: "30px" }}>{riskFactor && t('riskFactors.' + riskFactor.referenceName)}</span>
                                }

                                {/* Other category dont have reference name ! */}
                                {riskFactorCategory &&  evaluation.evaluationCompleted && riskFactorCategory.id === 5 &&
                                    <>
                                        <Icon name="checkmark" color="green" />
                                        <span style={{ marginLeft: "5px" }}>{riskFactor && riskFactor.name}</span>
                                    </>                                
                                }
                                {riskFactorCategory &&  !evaluation.evaluationCompleted && riskFactorCategory.id === 5 &&
                                    <span style={{ marginLeft: "30px" }}>{riskFactor && riskFactor.name}</span>
                                }

                            </p>
                        </div>

                        <div style={{ marginLeft: "30px" }}>
                            {t('evaluation.riskImpact') + " " + evaluation.riskImpact + "/25 "}
                            {evaluation.riskImpactAfterProceeding !== 0 &&
                                <>
                                    <Icon name='arrow right'/>
                                    {evaluation.riskImpactAfterProceeding + "/25"}
                                </>
                            }
                        </div>

                    </Grid.Column>
                    <Grid.Column textAlign="right">
                        <Icon name={activeIndex === index ? "angle right" : "angle down"}  />
                    </Grid.Column>
                </Grid.Row>
            </Grid>                     
        </Accordion.Title>
    );

}

/* Page 2 - Main page */
const ListEvaluatedRiskFactors = ({
    visible,
    isLoading,
    evaluations,
    riskFactors,
    riskFactorCategories,
    selectedLocation,
    setSelectedPage,
    setSelectedInspection,
    setPreviousPage
}) => {

    const { t } = useTranslation();

    const [ activeIndex, setActiveIndex ] = useState(null);

    const mappedEvaluations = evaluations.map((evaluation) => {

        evaluation.riskFactor = riskFactors.find(riskFactor => {
            return riskFactor.id === evaluation.riskFactorId
        });

        if (evaluation.riskFactor){
            evaluation.riskFactorCategory = riskFactorCategories.find(category => category.id === evaluation.riskFactor.categoryId);
        }
        
        return evaluation;

    });

    const sortedAndMappedEvaluations = mappedEvaluations.sort((a,b) => b.riskImpact - a.riskImpact);

    const categoryPanels = sortedAndMappedEvaluations.map((evaluation, index) => {

        return {
            key: 'category-panel-' + index,
            title: <ListEvaluatedRiskFactorsCategoryTitle 
                        riskFactorCategory={evaluation.riskFactorCategory}
                        riskFactor={evaluation.riskFactor}
                        evaluation={evaluation}
                        index={index}
                        activeIndex={activeIndex}
                        setActiveIndex={setActiveIndex}
                   />,
            content: {
                content: <ListEvaluatedRiskFactorsCategoryContent
                                evaluation={evaluation}
                                selectedLocation={selectedLocation}
                                setSelectedPage={setSelectedPage}
                                setSelectedInspection={setSelectedInspection}
                                setPreviousPage={setPreviousPage}
                        />,
                active: activeIndex === index
            }
        }
    });

    if (!visible) return null;

    return (
        <div className="list-evaluated-risk-factors">

            <Info
                text={t('inspection.help.evaluateRiskFactors')}
                more={[
                    t('inspection.help.remenberToSaveOften')
                ]}
            />

            {/* Location is selected => show content */}
            { !isLoading && selectedLocation > 0 && 
                <Accordion defaultActiveIndex={0} panels={categoryPanels} />
            }       

            { sortedAndMappedEvaluations.length === 0 &&
                <p>{t('errors.risksNotIdentified')}</p>
            }

        </div>
    );

}

/* Page 1 - Accordion list sub accordion content  */
const IdentifyRiskFactorsRiskFactorContent = ({
    factor,
    evaluations,
    addNewEvaluation,
    editEvaluation,
    removeEvaluation,
    setSelectedPage,
    setSelectedInspection,
    setPreviousPage
}) => {

    const { t } = useTranslation();

    const addEvaluation = () => {
        // Set default values for the added risk factor
        const defaults = {
            "uuid": uuidv4(),
            "inspectionId": null,
            "riskFactorId": factor.id,
            "description": "",
            "recognized": "",
            "riskProbability": 0,
            "riskConsequences": 0,
            "riskImpact": 0,
            "proceeding": "",
            "riskProbabilityAfterProceeding": 0,
            "riskConsequencesAfterProceeding": 0,
            "riskImpactAfterProceeding": 0,
            "responsiblePerson": "",
            "schedule": null, // Schedule must be null if not selected,
            "evaluationCompleted": false,
            "isEvaluated": false
        }

        addNewEvaluation(defaults);

        return true;

    }

    // Filter added evaluations from global state
    const filteredEvaluations = evaluations.filter(item => item.riskFactorId === factor.id);

    return (
        <div>

            {/* Default risk factor description */}
            {factor.categoryId !== 5 &&
                <p>{t('riskFactorInfotexts.' + factor.referenceName)}</p>
            }

            {/* User created risk factor description */}
            {factor.categoryId === 5 &&
                <p>{factor.description}</p>
            }

            {/* list all identified risk factors */}
            {filteredEvaluations.map((item, index) => {
                return (
                    <Fragment key={index}>

                        { item.evaluationCompleted && <Icon name="checkmark" color="green" /> }

                        {/* Add new risk factor button */}
                        <Input 
                            fluid
                            style={{ marginBottom : "10px" }}
                            maxLength="255"
                            label={<Button icon="delete" type="button" onClick={() => removeEvaluation(item.uuid)} />}
                            labelPosition="right"
                            placeholder={t('evaluation.evaluateRisksInfo')}
                            value={item.description}
                            onChange={(ev, {value}) => {
                                item.description = value;
                                editEvaluation(item.uuid, item);
                            }}
                        />

                        {/* Evaluate added risk factor link */}
                        <Button className="green  ui button basic link" onClick={() => {
                            setSelectedInspection(item.uuid);
                            setPreviousPage(1);
                            setSelectedPage(4);
                        }}>
                            {t('evaluation.evaluateriskfactor')}
                        </Button>

                        <br/><br/>
                    </Fragment>
                )
            })}

            {/* Identify risk factor => add new evaluation object and show description field */}
            <NewButton type="button" title={t('evaluation.createNewEvaluation')} onClick={() => addEvaluation()}/>

        </div>
    );
}

/* Page 1 - Accordion list sub accordion title */
const IdentifyRiskFactorsRiskFactorTitle = ({ referenceName, index, activeIndex, setActiveIndex, factor, evaluations }) => {

    const { t } = useTranslation();

    // Filter added evaluations from global state
    const filteredEvaluations = evaluations.filter(item => item.riskFactorId === factor.id);

    return (
        <Accordion.Title
            active={activeIndex === index}
            index={index}
            onClick={(ev, data) => {
                if (activeIndex === index) setActiveIndex(null);
                if (activeIndex !== index) setActiveIndex(index);
            }}
        >
            <Grid>
                <Grid.Row columns={2}>

                    <Grid.Column>
                        <h3><b>{
                            referenceName
                            ? 
                                t('riskFactors.' + referenceName)
                            :
                                filteredEvaluations.length === 0
                                ?
                                    factor.name
                                :
                                    factor.name + " (" + filteredEvaluations.length + " " + t('general.pcs') + ")"
                                    //factor.name + " (" + filteredEvaluations.length + " " + t('evaluation.identified') + ")"
                            }
                        </b></h3>
                    </Grid.Column>

                    <Grid.Column textAlign="right">
                        <Icon name={activeIndex === index ? "angle right" : "angle down"}  />
                    </Grid.Column>

                </Grid.Row>
            </Grid>                     
        </Accordion.Title>
    );

}

/* Page 1 - Accordion list content */
const IdentifyRiskFactorsCategoryContent = ({
    category,
    evaluations,
    addNewEvaluation,
    removeEvaluation,
    editEvaluation,
    setSelectedPage,
    setSelectedInspection,
    setPreviousPage
}) => {
    
    const { t } = useTranslation();

    const [ activeSubIndex, setActiveSubIndex ] = useState(null);

    /* Create risk factor titles / headers */
    const riskFactorPanels = category.factors.map((factor, index) => {
        return {
            key: 'risk-factor-panel-' + index,
            title: <IdentifyRiskFactorsRiskFactorTitle 
                reference={factor.referenceName}
                index={index}
                activeIndex={activeSubIndex}
                setActiveIndex={setActiveSubIndex}
                factor={factor}
                evaluations={evaluations}
            />,
            content: {
                content: <IdentifyRiskFactorsRiskFactorContent
                            factor={factor}
                            evaluations={evaluations}
                            addNewEvaluation={addNewEvaluation}
                            removeEvaluation={removeEvaluation}
                            editEvaluation={editEvaluation}
                            setSelectedPage={setSelectedPage}
                            setSelectedInspection={setSelectedInspection}
                            setPreviousPage={setPreviousPage}
                        />,
                active: activeSubIndex === index
            }
        }
    });

    /* Show create own category button under "Other" category */
    let ownCategoryButton = null;

    if (category.id === 5) {
        ownCategoryButton = <OpenButton
                                icon="plus"
                                color="green"
                                onClick={() => setSelectedPage(3)}
                                className="create-inspection"
                                title={t('inspection.createNewRiskFactor')}
                            />
    }

    return (
            <Accordion.Content>
                
                <Accordion.Accordion panels={riskFactorPanels} />
                
                {ownCategoryButton}

            </Accordion.Content>
    );

}

/* Page 1 - Accordion list title */
const IdentifyRiskFactorsCategoryTitle = ({ reference, count, index, activeIndex, setActiveIndex }) => {

    const { t } = useTranslation();

    return (
        <Accordion.Title
            active={activeIndex === index}
            index={index}
            onClick={(ev, data) => {
                if (activeIndex === index) setActiveIndex(null);
                if (activeIndex !== index) setActiveIndex(index);
            }}
        >
            <Grid>
                <Grid.Row columns={2}>

                    <Grid.Column>
                        <h3><b>{t('riskFactorCategories.' + reference)}</b></h3>
                        <p>{count + " " + t('inspection.riskFactor')}</p>
                    </Grid.Column>

                    <Grid.Column textAlign="right">
                        <Icon name={activeIndex === index ? "angle right" : "angle down"}  />
                    </Grid.Column>

                </Grid.Row>
            </Grid>                     
        </Accordion.Title>
    );

}

/* Page 1 - Main page */
const IdentifyRiskFactors = ({
    visible,
    setSelectedPage,
    selectedLocation,
    riskFactorCategories,
    riskFactors,
    evaluations,
    addNewEvaluation,
    removeEvaluation,
    editEvaluation,
    setSelectedInspection,
    setPreviousPage
}) => {

    const { t } = useTranslation();

    const [ activeIndex, setActiveIndex ] = useState(null);

    /* Map all riskfactors inside the categories */
    const groupedRiskFactors = riskFactorCategories.map(category => {
        category.factors = riskFactors.filter(item => item.categoryId === category.id);
        return category;
    });

    /* Create category panels */
    const categoryPanels = groupedRiskFactors.map((category, index) => {
        return {
            key: 'category-panel-' + index,
            title: <IdentifyRiskFactorsCategoryTitle 
                reference={category.referenceName}
                count={category.factors.length}
                index={index}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
            />,
            content: {
                content: <IdentifyRiskFactorsCategoryContent
                            category={category}
                            evaluations={evaluations}
                            addNewEvaluation={addNewEvaluation}
                            removeEvaluation={removeEvaluation}
                            editEvaluation={editEvaluation}
                            setSelectedPage={setSelectedPage}
                            setSelectedInspection={setSelectedInspection}
                            setPreviousPage={setPreviousPage}
                        />,
                active: activeIndex === index
            }
        }
    });

    if (!visible) return null;

    return (
        <div className="identify-risk-factors">

            <Info
                text={t('inspection.help.identifyRiskFactors')}
                bullets={[
                    t('inspection.help.identifyRiskFactorsStep1'),
                    t('inspection.help.identifyRiskFactorsStep2'),
                    t('inspection.help.identifyRiskFactorsStep3'),
                    t('inspection.help.identifyRiskFactorsStep4'),
                    t('inspection.help.identifyRiskFactorsStep5')
                ]}
                more={[
                    t('inspection.help.remenberToSaveOften')
                ]}
            />

            {/* Location is selected => show content */}
            { selectedLocation > 0 && 
                <Accordion defaultActiveIndex={0} panels={categoryPanels} />
            }

        </div>
    );

}

/* Page 0 */
const ChooseLocation = ({
    visible,
    isLoading,
    location,
    locations,
    selectedLocation, setSelectedLocation,
    date, setDate,
    workgroup, setWorkgroup,
    description, setDescription
}) => {

    const { t } = useTranslation();

    let locationOptions = locations.map((location, index) => {
        return {
            "key": index + 1,
            "text": location.name,
            "value": location.id
        }
    });

    locationOptions.unshift({ "key": 0, "text": t('locations.chooseLocation'), "value": 0 });

    if (!visible) return null;

    return (
        <div className="choose-location">

            {/* Show warning if no locations are found */}
            {!isLoading && locations.length === 0 &&
                <p>{t('general.itemsNotFound')}</p>
            }

            {/* Show location name when only one location is found */}
            {!isLoading && locations.length === 1 &&
                <>
                    <Info text={t('inspection.help.fillInInformation')} />
                    <Form.Field label={t('inspection.location')} />
                    {<p>{location.name}</p>}
                </>
            }

            {/* Show location dropdown when multiple locations are found */}
            {!isLoading && locations.length > 1 &&
                <>

                    <Info
                        text={t('inspection.help.selectLocation')}
                        more={[
                            t('inspection.help.remenberToSaveOften')
                        ]}
                    />

                    <Form.Field
                        required
                        label={t('inspection.location')}
                        control={Select}
                        value={selectedLocation}
                        options={locationOptions}
                        onChange={(ev, {value}) => {
                            setSelectedLocation(value);
                        }}
                    />
                </>
            }

            {/* Show field only when location is selected */}
            { selectedLocation > 0 && 
                <>

                    {/* Date */}
                    <Form.Field label={t('inspection.date')} />
                    <DatePicker
                        //required
                        label={t('inspection.date')}
                        selected={date}
                        locale="fi"
                        dateFormat={"dd.MM.yyyy"}
                        onChange={(date) => {
                            date = utils.setDateTimeToCurrentTime(date);
                            setDate(date);
                        }}
                    />
                    
                    <div style={{ marginTop: '20px'}}></div>

                    {/* Workgroup */}
                    <Form.Field
                        //required
                        maxLength="255"
                        control={Input}
                        label={t('inspection.workgroup')}
                        value={workgroup}
                        onChange={(ev, {value}) => setWorkgroup(value) }
                        type="Text"                        
                    />

                    {/* Description */}
                    <Form.Field
                        //required
                        maxLength="255"
                        control={TextArea}
                        label={t('inspection.description')}
                        value={description}
                        onChange={(ev, {value}) => setDescription(value) }
                        type="Text"
                    />

                </>                
            }

        </div>
    );

}

const Buttons = ({
    page,
    setPage,
    disabled,
    hidden
}) => {

    const { t } = useTranslation();

    const onClickNextPage = () => {
        if (page < 3) setPage(page+1);
    }

    const onClickPreviousPage = () => {
        if (page > 0) setPage(page-1);
    }

    return (
        <Container textAlign="right" className="button-container">

            {/* From page 0 to page 1 button */}
            {(page === 0) &&
                <OpenButton
                    title={t('navigation.toIdentifyRiskFactors')}
                    color={"green"}
                    onClick={onClickNextPage}
                    disabled={disabled}
                />
            }

            {/* From page 1 to Previous page 0 button  */}
            {(page === 1) &&
                <OpenButton
                    title={t('navigation.toLocationSelection')}
                    color={"green"}
                    onClick={onClickPreviousPage}
                    icon='arrow left'
                    disabled={disabled}
                />
            }

            {/* From page 1 to page 2 button */}
            {(page === 1) &&
                <OpenButton
                    title={t('navigation.toEvaluation')}
                    color={"green"}
                    onClick={onClickNextPage}
                    disabled={disabled}
                />
            }

            {/* From page 2 to Previous page 1 button  */}
            {(page === 2) &&
                <OpenButton
                    title={t('navigation.toIdentifyRiskFactors')}
                    color={"green"}
                    onClick={onClickPreviousPage}
                    icon='arrow left'
                    disabled={disabled}
                />
            }

            {/* Show save inspection and cancel inspection buttons only on the main views (chooseLocation, identifyRiskFactors, listEvaluated) */}
            {(page === 0 || page === 1 || page === 2) &&
            <>
                <SubmitButton icon="save" color="green" title={t('general.save')} disabled={disabled} />
                <CancelButton url="/" hidden={hidden} />
            </>
            }
        </Container>
    );
}

const WarningPrompt = ({formIsCompleted}) => {

    const { t } = useTranslation();
    const [ modalIsOpen, setModalIsOpen ] = useState(false);

    return (

        <NavigationPrompt 
            when={(currentLocation, nextLocation) => {
                
                if (!nextLocation) return !formIsCompleted;

                return !formIsCompleted && !nextLocation.pathname.startsWith("/inspection");

            }}
        >
            {({ isActive, onCancel, onConfirm }) => (
                <YesNoModal
                    type="warning"
                    header={t('warnings.title')}
                    text={t('warnings.leaveForm')}
                    method={(answer) => {
                        if (!answer) onCancel();
                        if (answer) onConfirm();
                    }}
                    //item={null}
                    open={isActive || modalIsOpen}
                    setOpen={setModalIsOpen}
                    noClick={true}
                />
            )}
        </NavigationPrompt>
    );

}

const Inspection = (props) => {

    const { t } = useTranslation();
    //const history = useHistory();

    /* Configuration state loaded on page load */
    const { locations, listLocations } = useListLocations();
    const { riskFactorCategories, listRiskFactorCategories } = useListRiskFactorCategories([]);

    /* Configuration state - loaded on location select */
    const { latestInspection, readLatestInspection } = useReadLatestInspection(null); // null if previous inspection is not found!
    const { riskFactors, listRiskFactors } = useListRiskFactors([]);
    const { inspectionEvaluations, listInspectionEvaluations } = useListInspectionEvaluations([]);

    /* New Inspection state - user enter */
    const [ location, setLocation ] = useState({});
    const [ selectedLocation, setSelectedLocation ] = useState(0);
    const [ selectedInspection, setSelectedInspection ] = useState(0);
    const [ date, setDate ] = useState(new Date());
    const [ workgroup, setWorkgroup ] = useState("");
    const [ description, setDescription ] = useState("");
    const [ evaluations, setEvaluations ] = useState([]);

    /* General state */
    const [ isLoading, setIsLoading ] = useState(false);
    const [ selectedPage, setSelectedPage ] = useState(0);
    const [ previousPage, setPreviousPage ] = useState(0);
    const [ formIsCompleted, setFormIsCompleted ] = useState(false);
    const [ itemToDelete, setItemToDelete ] = useState(null);
    const [ deleteModalOpen, setDeleteModalOpen ] =useState(false);
    const [ evaluationIsCompletedModalIsOpen, setEvaluationIsCompletedModalIsOpen ] = useState(false);

    /* Create and update */
    const { createInspection } = useCreateInspection();
    const { createEvaluation } = useCreateEvaluation();

    /* Add new evaluation to evaluation state (use getEvaluationTemplate to get correct fields) */
    const addNewEvaluation = (evaluation) => {
        setEvaluations(items => {
            const clone = [...items];
            clone.push(evaluation);
            return clone;
        });
    };

    /* Edit evaluation from evaluation state */
    const editEvaluation = (uuid, newEvaluation) => {
        setEvaluations(items => {
            const mapped = items.map(item => {
                if (item.uuid === uuid) item = newEvaluation;                
                return item;
            });
            return mapped;
        });
    }

    /* Open confirmation modal before removing evaluation from the evaluation state */
    const removeEvaluation = (uuid) => {
        setItemToDelete(uuid);
        setDeleteModalOpen(true);
    }

    /* remove evaluation from the evaluation state */
    const handleDeleteItem = () => {
        setEvaluations(evaluations => {
            const filtered = evaluations.filter(item => item.uuid !== itemToDelete);
            return filtered;
        });
    }

    /* Get evaluation by uuid from the evaluation state */
    const getEvaluation = (uuid) => {
        return evaluations.find(evaluation => evaluation.uuid === uuid);
    }

    /* Save and close inspection creation */
    const saveInspection = async (evaluationIsCompleted) => {
        try {            

            setFormIsCompleted(true);
            setIsLoading(true);

            // New inspection object
            const newInspection = {
                "locationId": selectedLocation,
                "date": date.toISOString(),
                "workgroup": workgroup,
                "description": description
            }

            // 1. Save inspection and get inspection id
            const result = await createInspection(newInspection);

            const status = result.status || 201;
            const data = result.data || null;
            const id = data && data.id  ? data.id : null;

            if (status !== 201) {
                setIsLoading(false);
                return;
            }

            // 2. Map inspection id to all evaluations
            if (status === 201 && id && evaluations.length) {

                const mappedEvaluations = evaluations.map(item => {
                    item.inspectionId = id;
                    item.evaluationCompleted = evaluationIsCompleted;
                    item.schedule = item.schedule ? item.schedule.toISOString(): null;
                    delete item.riskFactorCategory;
                    delete item.riskFactor
                    delete item.uuid;
                    delete item.isEvaluated;
                    return item;
                });

                let evaluationsObject = {
                    "evaluations": mappedEvaluations
                }

                // 3. Save evaluations
                await createEvaluation(evaluationsObject);
                await listInspectionEvaluations(id);

            }

            setIsLoading(false);
            //return history.push('/');

        } catch (error) {
            setIsLoading(false);
        }
    }

    /* 1.Load location and categories when user opens the form */
    useEffect(() => {
        (async () => {
            setIsLoading(true);
            await listLocations();
            await listRiskFactorCategories();
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* 2.Set default location if only one is found */
    useEffect(() => {
        if (locations && locations.length === 1) {
            setLocation(locations[0]);
            setSelectedLocation(locations[0].id);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locations]);

    /* 3.Load latest inspection and evaluations when user changes location */
    useEffect(() => {
        if (selectedLocation !== 0) {
            (async () => {

                setIsLoading(true);      

                // Clear previous when user change location
                setDate(new Date());
                setWorkgroup("");
                setDescription("");
                setEvaluations([]);

                let location = locations.find(item => item.id === selectedLocation);
                setLocation(location);

                await readLatestInspection(parseInt(selectedLocation));
                await listRiskFactors(selectedLocation);

                setIsLoading(false);

            })();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLocation]);

    /* 4.Set latest Inspection data when state changes and fetch inspection data*/
    useEffect(() => {
        if (latestInspection.id) {
            (async () => {
                setDate(new Date(latestInspection.date));
                setWorkgroup(latestInspection.workGroup);
                setDescription(latestInspection.description);
                await listInspectionEvaluations(latestInspection.id);
            })();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [latestInspection]);

    /* 5.Set latest Inspection evaluation data when state changes */
    useEffect(() => {
        if (inspectionEvaluations.length) {
            setEvaluations(inspectionEvaluations);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inspectionEvaluations]);

    return (
        <div className="inspection">
            <Form onSubmit={() => { setEvaluationIsCompletedModalIsOpen(true) /*saveInspection();*/ }} >

                <Loading active={ isLoading } text={t('general.loading')} />

                <YesNoModal
                    type="ready"
                    header={t('evaluation.evaluation')}
                    text={
                        <div>
                            <h3>{t('evaluation.isEvaluationCompleted')}</h3><br/>
                            <p>{t('evaluation.completedEvaluationIsEditable')}</p>
                            <p>{t('evaluation.onlyCompletedEvaluationsAreVisibleOnReports')}</p>
                        </div>
                    }
                    method={(answer, id) => {
                        saveInspection(answer);
                    }}
                    //item={""}
                    open={evaluationIsCompletedModalIsOpen}
                    setOpen={setEvaluationIsCompletedModalIsOpen}
                    noClick={true}
                />

                <YesNoModal 
                    type='delete'
                    header={t('general.warning')}
                    text={t('warnings.evaluationIsDeleted')}
                    method={handleDeleteItem}
                    item={itemToDelete}
                    open={deleteModalOpen}
                    setOpen={setDeleteModalOpen}
                />

                <WarningPrompt formIsCompleted={formIsCompleted} />
               
                <MediaContextProvider>
                    <Media greaterThan='sm'>
                        <WizardMenu href={selectedPage===0 ? "/info#location" : selectedPage===1 || selectedPage===3 ? "/info#risk-factor" : "/info#evaluation"} visible={selectedPage === 0 || selectedPage === 1 || selectedPage === 2} infoTitle={location && location.name ? t('inspection.inspectionLocation') + ': ' + location.name : t('inspection.inspectionLocation') + ': '}>
                            <WizardItem title={t('locations.location')} active={selectedPage === 0 ? true : false} />
                            <WizardItem title={t('inspection.identification')} active={(selectedPage === 1 || selectedPage === 3) ? true : false} />
                            <WizardItem title={t('evaluation.evaluation')} active={(selectedPage === 2 || selectedPage === 4) ? true : false} />
                        </WizardMenu>
                    </Media>
                    <Media lessThan='md'>
                        <WizardMenu href={selectedPage===0 ? "/info#location" : selectedPage===1 || selectedPage===3 ? "/info#risk-factor" : "/info#evaluation"} visible={selectedPage === 0 || selectedPage === 1 || selectedPage === 2} infoTitle={location && location.name ? t('inspection.inspectionLocation') + ': ' + location.name : t('inspection.inspectionLocation') + ': '}>
                            <WizardItem title={t('locations.location')} active={selectedPage === 0 ? true : false} />
                            <WizardItem title={t('inspection.identify')} active={(selectedPage === 1 || selectedPage === 3) ? true : false} />
                            <WizardItem title={t('evaluation.evaluate')} active={(selectedPage === 2 || selectedPage === 4) ? true : false} />
                        </WizardMenu>
                    </Media>
                </MediaContextProvider>

                {/* Inspection has three main 'pages' and two sub 'pages'. Choose location is page 0 */}
                <ChooseLocation
                    visible={selectedPage === 0}
                    isLoading={isLoading}
                    location={location}
                    locations={locations}
                    selectedPage={selectedPage}
                    selectedLocation={selectedLocation}
                    setSelectedLocation={setSelectedLocation}
                    date={date}
                    setDate={setDate}
                    workgroup={workgroup}
                    setWorkgroup={setWorkgroup}
                    description={description}
                    setDescription={setDescription}
                />

                {/* Inspection has three main 'pages' and two sub 'pages'. Identify Risk Factors is page 1 */}
                <IdentifyRiskFactors
                    visible={selectedPage === 1}
                    selectedPage={selectedPage}
                    setSelectedPage={setSelectedPage}
                    selectedLocation={selectedLocation}
                    riskFactorCategories={riskFactorCategories}
                    riskFactors={riskFactors}
                    evaluations={evaluations}
                    addNewEvaluation={addNewEvaluation}
                    editEvaluation={editEvaluation}
                    removeEvaluation={removeEvaluation}
                    setSelectedInspection={setSelectedInspection}
                    setPreviousPage={setPreviousPage}
                />

                {/* Inspection has three main 'pages' and two sub 'pages'. List Evaluated Risk Factors is page 2 */}
                <ListEvaluatedRiskFactors
                    visible={selectedPage === 2}
                    selectedPage={selectedPage}
                    setSelectedPage={setSelectedPage}
                    isLoading={isLoading}
                    evaluations={evaluations}
                    riskFactorCategories={riskFactorCategories}
                    riskFactors={riskFactors}
                    selectedLocation={selectedLocation}
                    setSelectedInspection={setSelectedInspection}
                    setPreviousPage={setPreviousPage}
                />

                {/* Sub page: User can create location related risk factors - Create risk factor is page 3 */}
                <CreateRiskFactor
                    visible={selectedPage === 3}
                    isLoading={isLoading}
                    selectedPage={selectedPage}
                    setSelectedPage={setSelectedPage}
                    setIsLoading={setIsLoading}
                    selectedLocation={selectedLocation}
                    listRiskFactors={listRiskFactors}
                />

                {/* Sub page: User can evaluate added risk factors - Evaluate risk factor is page 4 */}
                <EvaluateRiskFactor
                    visible={selectedPage === 4}
                    isLoading={isLoading}
                    selectedInspection={selectedInspection}
                    getEvaluation={getEvaluation}
                    editEvaluation={editEvaluation}
                    selectedPage={selectedPage}
                    setSelectedPage={setSelectedPage}
                    previousPage={previousPage}
                />

                {/* Buttons are common for the main pages */}
                <Buttons
                    page={selectedPage}
                    setPage={setSelectedPage}
                    disabled={selectedLocation === 0}
                    hidden={selectedPage === 3 || selectedPage === 4}
                />

            </Form>
        </div>
    );

}

export default Inspection;