import { useContext, useState } from 'react';
import { AuthContext } from '../../Context/AuthContext';
import useRequestHandler from '../useRequestHandler';

const useReadLatestInspection = (initialValue) => {

    //const { t } = useTranslation();
    const { acquireTokenSilently, apiClient } = useContext(AuthContext);
    const [ latestInspection, setLatestInspection ] = useState(initialValue || {});
    const { errorHandler, successHandler } = useRequestHandler();
    
    /** Read latest inspection by location id
     * @param {integer} id - location id  */
    const readLatestInspection = async (id) => {
        try {

            setLatestInspection({})

            const accessToken = await acquireTokenSilently();

            if (accessToken === "login_required") throw Error("login_required");

            const result = await apiClient.read(accessToken, "locations", id + "/inspections/latest");

            const data = result.data || null;
            const inspection = data.inspection || null;

            if (inspection) setLatestInspection(inspection);
            

            return successHandler(result, "useReadLatestInspection", "read", { hide: true });

        } catch (error) {

            const status = error.status || null;
            const isHidden = status === 404 ? true : false; // Api is used to check if location exist and fetch the data. id not found, dont show error:
            const options = { hide: isHidden}

            return errorHandler(error, "useReadLatestInspection", "read", options);
        }
    }

    return { latestInspection, readLatestInspection };

}

export default useReadLatestInspection;
