import { useContext, useState } from 'react';
import { AuthContext } from '../../Context/AuthContext';
import useRequestHandler from './../useRequestHandler';

const useReadLocation = () => {

    const { acquireTokenSilently, apiClient } = useContext(AuthContext);
    const [ location, setLocation ] = useState({});
    const { errorHandler, successHandler } = useRequestHandler();
    const [ locationDoesNotExist, setLocationDoesNotExist ] = useState(false);

    /** Read location 
     * @param {integer} id - location id */
    const readLocation = async (id) => {
        try {

            const accessToken = await acquireTokenSilently();

            if (accessToken === "login_required") throw Error("login_required");

            const result = await apiClient.read(accessToken, "locations", id);

            const data = result.data || {};

            const location = data.location || {};
            if (location) setLocation(location);

            return successHandler(result, "useReadLocation", "read", { hide: true });

        } catch (error) {

            const status = error.status || null;
            const data = error.data || {};
            const errorCode = data.errorCode || 0;

            if (status === 400 && errorCode === -1) setLocationDoesNotExist(true);

            const options = {
                "hide": "accessToken" && "accessToken" === "login_required" ? true: false,
                "customLog": { "accessToken": "accessToken" }
            }

            return errorHandler(error, "useReadLocation", "read", options);

        }
    }

    return { locationDoesNotExist, setLocationDoesNotExist, location, readLocation };

}

export default useReadLocation;
