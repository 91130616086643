import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
//import { OpenButton } from './../../Reusable/Buttons';
//import { Container } from 'semantic-ui-react';
import { PageTitle } from './../../Reusable/Elements';
//import { Loading, PageTitle } from './../../Reusable/Elements';

const Info = (props) => {

    const { t } = useTranslation();

    // Scroll to the hash
    useEffect(() => {
        const hash = props.history.location.hash
        const el = hash && document.getElementById(hash.substr(1))
        if (el) el.scrollIntoView({behavior: "smooth"})
    }, [props.history.location.hash]) // Fires every time hash changes

    return (
        <div className="info">
            
            {/* <Loading active={ isLoadingXXX  } text={t('general.loading')} /> */}

            <PageTitle title={t('navigation.info')} />

            <p>Käy tutustumassa vaarojen selvittämisen ja arvioinnin perusteisiin 
                Työturvallisuuskeskuksen <a target="_blank" rel="noreferrer" href="https://ttk.fi/tyoturvallisuus/vastuut-ja-velvoitteet/tyonantajan-yleiset-velvollisuudet/vaarojen-tunnistaminen-ja-riskien-arviointi/">verkkopalvelussa.</a>
            </p>

            <h2 id="general">Käyttäjätasot ja tehtävät</h2>
            <p>
                <ul>
                    <li>Pääkäyttäjä
                        <ul>
                        <li>Luo ja hallinnoi kohteita ja käyttäjiä</li>
                        <li>Lähettää liittymispyynnön käyttäjälle</li>
                        <li>Pääsee kaikkien kohteiden arviointeihin ja raportteihin</li>
                        <li>Muokkaa Työsuojelutoiminnan tilannekuva -sivua.</li>
                        </ul>
                    </li>
                    <li>Käyttäjä
                        <ul>
                        <li>Valitsee arvioitavan kohteen</li>
                        <li>Tunnistaa kohteen vaarat ja arvioi riskit</li>
                        <li>Pääsee katsomaan omia arviointeja ja raportteja</li>
                        <li>Pääsee katsomaan Työsuojelutoiminnan tilannekuva –sivua.</li>
                        </ul>
                    </li>
                </ul>
            </p>

            <h2 id="location">Kohteiden valinta</h2>

            <p>Aivan pienimpiä yrityksiä lukuun ottamatta riskien arviointia ei voida tehdä kerralla koko yrityksen toiminnasta, vaan arviointi on 
                yleensä syytä jakaa osiin. Arvioinnin jakaminen pienempiin kohteisiin helpottaa arviointiin käytetyn ajan hallintaa ja samalla arvioinnissa 
                voidaan keskittyä kulloiseenkin arviointikohteeseen yksityiskohtaisemmin.</p>
            <p>Arviointikohteiden rajaamisessa on monta vaihtoehtoista, yrityksen koosta ja toiminnan luonteesta riippuvaa tapaa. Arviointia voidaan rajata 
                esim. työtehtävien, työpisteiden, tiimien, prosessin osien, linjojen, osastojen tai rakennusten mukaisesti. Kerralla tarkasteltavan riskien 
                arvioinnin kohteen tulee olla selkeästi rajattu, riittävän kokoinen, mutta kuitenkin helposti hallittavissa oleva toiminnan osa. 
                (Lähde: Riskien arviointi työpaikalla -työkirja)</p>

            <h2 id="risk-factor">Vaarojen tunnistaminen</h2>

            <p>Vaarojen tunnistaminen tarkoittaa kaikkien työstä, työtilasta, työajoista, muusta työympäristöstä ja työolosuhteista johtuvien työntekijöiden 
                terveydelle tai turvallisuudelle haittaa tai vaaraa aiheuttavien tekijöiden tunnistamista ja kirjaamista. Vaarojen tunnistamisessa tulee ottaa 
                huomioon aiemmin toteutuneet vaarat, sellaiset mahdolliset vaaratekijät, jotka eivät vielä ole aiheuttaneet vahinkoja, mutta joiden esiintyminen 
                on mahdollista, työntekijöiden henkilökohtaiset edellytykset ja työn kuormitustekijät.</p>
            <p>Jokaisen vaaratekijän osalta täytyy kirjata tilanne, joka aiheuttaa vaaran, esimerkkisi liukas lattia pukuhuoneessa. Vaaratilanteiden osalta tulee 
                miettiä tilanteisiin johtavia syitä ja seurauksia. Syiden huolellinen miettiminen auttaa myöhemmin löytämään parhaat toimenpiteet vaaratilanteen 
                estämiseksi. Vaaratilanteiden kuvaamiseksi tulee selvittää vaaratilanteeseen johtavat tapahtumaketjut. (Lähde: Riskien arviointi työpaikalla -työkirja)</p>
            <p>
            Vaarojen tunnistaminen Työturvallisuustutkassa
                <ul>
                    <li>Työn vaaratekijät / Työn vaarojen osa-alueet
                        <ul><li>Työn vaaratekijälistoissa on käytetty pohjana Riskien arviointi työpaikalla -työkirjan vaaratekijälomakkeita. Käyttäjä voi lisätä myös omia vaaratekijöitä.</li></ul>
                    </li>
                    <li>Vaaratilanteiden kuvaus 
                        <ul><li>Käyttäjä kuvaa konkreettisia vaaratilanteita. Esimerkki vaaratilannekuvauksesta: varaston käytävällä tavaraa, mikä aiheuttaa kompastumisvaaran.</li></ul>
                    </li>
                </ul>
            </p>

            <h2 id="evaluation">Riskin arviointi</h2>

            <p>Riski on vaaratilanteen aiheuttamien vahinkojen vakavuuden ja todennäköisyyden yhdistelmä. Riskin suuruuden määrittämisen tarkoituksena on löytää riskeille 
                niiden suuruutta kuvaava tunnusluku ja asettaa vaaratekijät riskin suuruuden mukaiseen järjestykseen. Riskin suuruus muodostuu haitallisen tapahtuman 
                todennäköisyydestä ja seurausten vakavuudesta. Seurausten vakavuus tarkoittaa haitallisen tapahtuman ihmisille aiheuttamien terveys- tai turvallisuushaittojen 
                vakavuutta. Todennäköisyys tarkoittaa, kuinka todennäköisesti haitallinen tapahtuma toteutuu.</p>
            <p>Riskien arvioinnin tavoitteena on löytää tehokkaimmat toimenpiteet työn turvallisuuden parantamiseksi. Riskien arvioinnin ideana on käyttää riskin suuruutta 
                toimenpiteiden kohdistamisperusteena. Toteutettujen turvallisuustoimenpiteiden vaikutusta työn turvallisuuteen ja terveyteen on seurattava mm. arvioimalla riski 
                uudelleen toimenpiteiden jälkeen. (Lähde: Riskien arviointi työpaikalla -työkirja)</p>
            <p>
                Riskien arviointi Työturvallisuustutkassa:
                <ul>
                    <li>Täydennä tarvittaessa vaaratilanteen kuvausta.</li>
                    <li>Arvioi todennäköisyys
                        <ul><li>Käyttäjä arvioi todennäköisyyden, kuinka usein vaaratilanne toistuu. Vaihtoehdot ovat seuraavat:
                            <ol>
                                <li>Epätodennäköinen</li>
                                <li>Kuukausittain</li>
                                <li>Viikoittain</li>
                                <li>Päivittäin</li>
                                <li>Välitön</li>
                            </ol>
                        </li></ul>
                    </li>
                    <li>Arvioi seuraukset
                        <ul><li>Käyttäjä arvioi inhimilliset seuraukset sairauspoissaolopäivinä. Vaihtoehdot ovat seuraavat:
                            <ol>
                                <li>Merkityksetön</li>
                                <li>Vähäinen</li>
                                <li>Keskitasoa</li>
                                <li>Vakava</li>
                                <li>Erittäin vakava</li>
                            </ol>
                        </li></ul>
                    </li>
                    <li>Riskiluku = todennäköisyys x seuraukset
                        <ul><li>Riskiluku voi olla 1-25.</li></ul>
                    </li>
                    <li>Toimenpiteet
                        <ul><li>Käyttäjä kirjaa toimenpiteitä, joilla riski voidaan poistaa tai sitä voidaan pienentää.</li></ul>
                    </li>
                    <li>Vastuuhenkilö ja aikataulu
                        <ul><li>Käyttäjä kirjaa toimenpiteille vastuuhenkilön ja aikataulun.</li></ul>
                    </li>
                    <li>Arvio riskistä toimenpiteiden jälkeen
                        <ul><li>Käyttäjä arvioi, mikä on todennäköisyys ja seuraus toimenpiteiden jälkeen.</li></ul>
                    </li>
                </ul>
            </p>
            
            {/* Buttons */}
            {/* button removed since info opens in new tab
            <Container textAlign="right">
                <OpenButton icon="arrow left" floated={"right"} title={t('general.frontpage')} url="/" />
            </Container>
            */}

        </div>
    );

}

export default Info;
