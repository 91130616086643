import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Form, Container } from 'semantic-ui-react';
import { DataPrivacy, Loading, PageTitle } from './../../Reusable/Elements';
import { SubmitButton } from './../../Reusable/Buttons';
import useConfirmRegistration from './../../../Hooks/Register/useConfirmRegistration';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const ConfirmRegistration = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    let query = useQuery();
    const { confirmRegistration } = useConfirmRegistration();
    const [ isLoading, setIsLoading ] = useState(false);

    const onSubmit = async () => {
        try {

            setIsLoading(true);

            const id = query.get("id");

            const result = await confirmRegistration(id);

            if (result.status === 200) return history.push("/login");

            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
        }
    }

    return (
        <Form className="register" onSubmit={onSubmit} autoComplete="off">

            <Loading active={isLoading} text={t('general.loading')} />

            {/* Title */}
            <PageTitle title={t('confirmRegistration.title')} hideInfo/>

            <p>{t('confirmRegistration.text')}</p>

             {/* Buttons */}
            <Container textAlign="right">
                <SubmitButton icon="save" color="green" title={t('general.confirm')} />
            </Container>
            <hr/>

             {/* DataPrivacy */}
            <DataPrivacy className="green" />

        </Form>
    );

}

export default ConfirmRegistration;
