/** Token handling helper functions */
import axios from 'axios';
import keyValueStorage from './keyValueStorage';
import config from './../Config/config';

/** JWT token authentication library */
const authentication = () => {

    // Use util library to store tokens (this must be outside the AuthProvider)
    const tokenStorage = keyValueStorage("inmemory");
    const refreshTokenStorage = keyValueStorage("localStorage");

    let renewalPreponeTime = 1;         // this time is extracted from the refresh interval time (refresh x minutes before expiration)
    let isRefreshing = null;            // contains promise if refreshing is in progress
    let refreshTimeOutId = null;
    let userProfile = null;

    const DEBUG = false;
    const log = console.log;
    const msg = {
        login: {
            m1: "### M1: Auth Login - Start login process",
            m2: "### M2: Auth Login - Call login endpoint",
            m3: "### M3: Auth Login - Login api call response",
            m4: "### M4: Auth Login - Login failed error"
        },
        logout: {
            m1: "### M1: Auth Logout - Start logout process",
            m2: "### M2: Auth Logout - Call logout endpoint ",
            m3: "### M3: Auth Logout - Logout Api call response ",
            m4: "### M4: Auth Logout - Logout failed error "
        },
        verify: {
            m1: "### M1: Auth Verify - Check if user is authenticated "
        },
        prepone: {
            m1: "### M1: Auth Prepone - Developer set prepone time: "
        },
        abort: {
            m1: "### M1: Auth abort - Abort refresh token timeout: "
        },
        timeout: {
            m1: "### M1: Auth timeout - Set refresh token timeout: ",
            m2: "### M1: Auth timeout - timeout value set: "
        },
        token: {
            m1: "### M1: Auth get token - Start get token process ",
            m2: "### M2: Auth get token - Wait for token refresh process to finish ",
            m3: "### M3: Auth get token - User is authenticated because token found (return token): ",
            m4: "### M4: Auth get token - Token is missing but refresh token found (try to refresh) ",
            m5: "### M5: Auth get token - Refresh succeeded (return renewed token) ",
            m6: "### M6: Auth get token - Token and refresh token are missing (return null) ",
            m7: "### M7: Auth get token - Error (log error and return null) "
        },
        refresh: {
            m1: "### M1: Auth refresh token - Start refresh token process ",
            m2: "### M2: Auth refresh token - Refresh is in progress (log isRefreshing value)? ",
            m3: "### M3: Auth refresh token - Refresh process in not in progress (return true): ",
            m4: "### M4: Auth refresh token - Refresh process is in progress (log isRefreshing value) ",
            m5: "### M5: Auth refresh token - Refresh process ended (set isRefreshing to null and return true) ",
            m6: "### M6: Auth refresh token - Error (log error and return false) "
        },
        fetchRefTok: {
            m1: "### M1: Auth fetch refresh token - Start fetch process ",
            m2: "### M2: Auth fetch refresh token - Refreshtoken not found from the memory (delete session and return false) ",
            m3: "### M3: Auth fetch refresh token - Refresh token found (Call refresh API) ",
            m4: "### M4: Auth fetch refresh token - Check isRefreshing variable value (log to console) ",
            m5: "### M5: Auth fetch refresh token - Refresh token api call result (log result) ",
            m6: "### M6: Auth fetch refresh token - Session set after succesfull refresh (return true) ",
            m7: "### M7: Auth fetch refresh token - Error (log error, delete session and return false) "
        },
        claims: {
            m1: "### M1: Auth parse claims - Parse and return claims object"
        },
        tokenClaim: {
            m1: "### M1: Auth get claim - Get token claim"
        },
        expiration: {
            m1: "### M1: Auth expiration - Get token exp time"
        },
        interval: {
            m1:  "### M1: Interval - Get renewal interval"
        },
        session: {
            m1: "### M1: Session - Start set session",
            m2: "### M2: Session - Extract data (log to console)",
            m3: "### M3: Session - Parse token (log to console)",
            m4: "### M4: Session - Calculate how many minutes until expiration",
            m5: "### M5: Session - Calculate expiration times (log to console)",
            m6: "### M6: Session - Save session tokens and user profile",
            m7: "### M7: Session - Set refresh token timeout"
        }
    }


/*********************************************************************************************
 *** Helper methods
*********************************************************************************************/

    /** Parse JWT token
     * @param {string} token - token string
     * @example
     *      const json = parseJwtClaims(token);
     * */
    const parseJwtClaims = (token) => {

        if (DEBUG) log(msg.claims.m1);
    
        if (!token) throw Error("Token is mandatory");
        if (typeof token !== "string") throw Error("Type of token is not string");

        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);

    };

    /** Return token payload parameter
     * @param {object} jsonPayload - payload object part from the parsed token (use parseJwtClaims to get payload)
     * @example - token payload - https://jwt.io
     *      const jwt = parseJwtClaims(token);
     *      const expClaim = getTokenClaim(json, "exp"); 
     * */
    const getTokenClaim = (claim, jsonPayload) => {
        if (DEBUG) log(msg.tokenClaim.m1);
        if (!jsonPayload) throw Error("jsonPayload is mandatory");
        return jsonPayload[claim];
    }

    // Calculate how many minutes until token expiration
    const calculateMinutesUntilExpiration = (tokenExpirationTimeInSeconds) => {

        // Get expiration time
        if (DEBUG) log(msg.session.m4);
        const expirationTimeInMilliseconds = tokenExpirationTimeInSeconds * 1000;
        const subtractedPreponeTimeInMilliseconds = renewalPreponeTime * 60 * 1000;

        // Get dates  
        let timeIsNow = new Date();
        let timeIsOnExpiration = new Date(expirationTimeInMilliseconds);

        // Calculate expiration times
        let expIntervalMs = timeIsOnExpiration.getTime() - timeIsNow.getTime() - subtractedPreponeTimeInMilliseconds;
        let expIntervalMin = Math.floor(expIntervalMs / (1000 * 60));
        
        if (DEBUG) log(msg.session.m5);
        if (DEBUG) console.log("### NOW ###");
        if (DEBUG) console.log("Now: " + timeIsNow.toLocaleDateString() + " " + timeIsNow.toLocaleTimeString());
        if (DEBUG) console.log("On expiration: " + timeIsOnExpiration.toLocaleDateString() + " " + timeIsOnExpiration.toLocaleTimeString());
        if (DEBUG) console.log("Expiration minutes: " + expIntervalMin);

        return expIntervalMin;

    }



/*********************************************************************************************
 *** Session
*********************************************************************************************/

    /** Set session */
    const setSession = (result) => {

        if (DEBUG) log(msg.session.m1);

        if (!result) throw Error("result is mandatory");

        // Get data from result object
        const newToken = result.data.token;
        const newRefreshToken = result.data.refreshToken;
        const newUser = result.data.user;

        if (DEBUG) log(msg.session.m2);
        if (DEBUG) log("New Token: " + newToken);
        if (DEBUG) log("New Refresh Token: " + newRefreshToken);
        if (DEBUG) log("User profile");
        if (DEBUG) log(newUser);

        // Parse tokens
        const parsedToken = parseJwtClaims(newToken);
        //const parsedRefreshToken = parseJwtClaims(newRefreshToken);
        if (DEBUG) log(msg.session.m3);
        if (DEBUG) log(parsedToken);

        // Get expiration time from the token exp claim
        const tokenExpirationTimeInSeconds = getTokenClaim("exp", parsedToken);

        // Calculate how many minutes until expiration
        const minutesUntilExpiration = calculateMinutesUntilExpiration(tokenExpirationTimeInSeconds);

        // Set session tokens and user profile
        if (DEBUG) log(msg.session.m6);
        tokenStorage.setValue("token", newToken)
        refreshTokenStorage.setValue("refreshToken", newRefreshToken);
        userProfile = newUser;

        // Set token refresh timer
        if (DEBUG) log(msg.session.m7);       
        setRefreshTokenTimeout(minutesUntilExpiration);

    }

    /** Delete logged in user session*/
    const deleteSession = () => {

        // Delete all tokens
        tokenStorage.deleteValue("token");
        refreshTokenStorage.deleteValue("refreshToken");

        // Delete user profile
        userProfile = null;

        // Abort token refreshing process
        abortRefreshTokenTimeout();

        return true;

    }


/*********************************************************************************************
 *** Token timers and refresh
*********************************************************************************************/

    /** Refresh token and update session
     * @returns {boolean} success - true or false */
    const fetchRefreshedToken = async () => {
        try {

            if (DEBUG) log(msg.fetchRefTok.m1);
            
            const refreshToken = refreshTokenStorage.getValue("refreshToken");

            // Refresh token not found, delete session and return false
            if (!refreshToken) {
                if (DEBUG) log(msg.fetchRefTok.m2);
                deleteSession();
                return false;
            }

            // Refreshtoken found, try to get refreshed token and set new session.
            const options = { "headers": { "Content-Type": "application/json" } }

            const apiVersion = config.webApi.apiVersion;
            const root = config.webApi.rootPath
            const url = new URL(apiVersion + "authentication/refresh", root);

            if (DEBUG) log(msg.fetchRefTok.m3);
            isRefreshing = axios.post(url, { "refreshToken": refreshToken }, options);

            if (DEBUG) log(msg.fetchRefTok.m4);
            if (DEBUG) log(isRefreshing);

            let result = await isRefreshing;

            if (DEBUG) log(msg.fetchRefTok.m5);
            if (DEBUG) log(result);

            setSession(result);

            if (DEBUG) log(msg.fetchRefTok.m6);
            // Authentication succeeded - return true
            return true;

        } catch (error) {

            if (DEBUG) log(msg.fetchRefTok.m7);
            if (DEBUG) log(error);

            // Authentication failed - return false
            deleteSession();
            return false;

        }
    }

    /** Refresh token timer renew token every n minutes */
     const setRefreshTokenTimeout = (minutesUntilExpiration) => {

        if (DEBUG) log(msg.timeout.m1);

        // Clear previous timeout
        abortRefreshTokenTimeout();

        const delayMs = minutesUntilExpiration * 60 * 1000;

        refreshTimeOutId = window.setTimeout(fetchRefreshedToken, delayMs);

        if (DEBUG) log(msg.timeout.m2 + " DelayMs = " + delayMs);

        return true;

     }

     /** Abort refresh token timer */
     const abortRefreshTokenTimeout = () => {
        if (DEBUG) log(msg.abort.m1);
        if (refreshTimeOutId) window.clearTimeout(refreshTimeOutId);
        return true;
    }


/*********************************************************************************************
 *** Tokens
*********************************************************************************************/

    /** Method wait until token refresh is in progress
     * @returns {boolean} success - true or false */
    const waitForTokenRefresh = async () => {
        try {

            if (DEBUG) log(msg.refresh.m1);

            if (DEBUG) log(msg.refresh.m2);
            if (DEBUG) log(isRefreshing);

            // Resolve promise if token refreshing is not in progress
            if (!isRefreshing) {
                if (DEBUG) log(msg.refresh.m3);
                return true;
            }

            if (DEBUG) log(msg.refresh.m4);
            if (DEBUG) log(isRefreshing);

            // Wait untin refresh prosess in finished
            await isRefreshing;

            if (DEBUG) log(msg.refresh.m5);

            // Set is refreshing to null
            isRefreshing = null;

            return true;

        } catch (error) {

            if (DEBUG) log(msg.refresh.m6);
            if (DEBUG) log(error);

            return false;
        }
    }

    /** Method wait if refresh is in progress and then return token
     * @returns {string} token */
    const getToken = async () => {
        try {
            
            if (DEBUG) log(msg.token.m1);
            if (DEBUG) log(msg.token.m2);

            await waitForTokenRefresh();

            let token = tokenStorage.getValue("token");
            if (DEBUG && token) log(msg.token.m3 + token);
            if (token) return token;

            if (DEBUG) log(msg.token.m4);
            let refreshed = await fetchRefreshedToken();

            if (DEBUG + refreshed) log(msg.token.m5 + refreshed);
            if (refreshed) return tokenStorage.getValue("token");

            if (DEBUG) log(msg.token.m6);

            return null;            

        } catch (error) {
            // Return null if error or token not found
            if (DEBUG) log(msg.token.m7);
            if (DEBUG) log(error);
            return null;
        }
    }


/*********************************************************************************************
 *** Authentication
*********************************************************************************************/

    /** login user
     * @param {string} username
     * @param {string} password **/
    const login = async (username, password) => {
        try {

            if (DEBUG) log(msg.login.m1);

            if (!username) throw Error("Username is not valid");
            if (!password) throw Error("Password is not valid");

            const options = { "headers": { "Content-Type": "application/json" } }
            const apiVersion = config.webApi.apiVersion;
            const root = config.webApi.rootPath
            const url = new URL(apiVersion + "authentication/login", root);

            if (DEBUG) log(msg.login.m2);

            const result = await axios.post(url, {"username": username, "password": password}, options);

            if (DEBUG) log(msg.login.m3);
            if (DEBUG) log(result);

            setSession(result);

            return result;

        } catch (error) {
            if (DEBUG) log(msg.login.m4);
            if (DEBUG) log(error);
            return error
        }
    }

    /** Logout user out */
    const logout = async () => {
        try {

            if (DEBUG) log(msg.logout.m1);

            const accessToken = tokenStorage.getValue("token");

            const options = { "headers": { "Content-Type": "application/json", "Authorization": "Bearer " + accessToken } }
            const apiVersion = config.webApi.apiVersion;
            const root = config.webApi.rootPath
            const url = new URL(apiVersion + "authentication/logout", root);

            if (DEBUG) log(msg.logout.m2);

            const result = await axios.post(url, null, options);
            
            if (DEBUG) log(msg.logout.m3);
            if (DEBUG) log(result);

            deleteSession();

            return result;

        } catch (error) {
            if (DEBUG) log(msg.logout.m4);
            if (DEBUG) log(error);
            return error;
        }
    }

    /** Check if user is authenticated */
    const verifyUserIsAuthenticated = () => {
        if (DEBUG) log(msg.verify.m1);

        //const token = tokenStorage.getValue("token");
        //const refToken = tokenStorage.getValue("refreshToken");

        //const tokenPayload = parseJwtClaims(token);
        //const tokenExp = getTokenClaim("exp", tokenPayload)
        //const tokenExpMin = calculateMinutesUntilExpiration(tokenExp);

        //const refTokenPayload = parseJwtClaims(refToken);
        //const refTokenExp = getTokenClaim("exp", refTokenPayload)
        //const refTokenExpMin = calculateMinutesUntilExpiration(refTokenExp);

        //console.log("TOKEN AND REFRESH TOKEN EXP TIME IN MINUTES");
        //console.log(tokenExpMin);
        //console.log(refTokenExpMin);

        return tokenStorage.getValue("token") ? true : false;
    }

    /** Method tries to reauthenticate using refresh token
     * @returns {boolean} authentication succeeded - True or false **/
    const reauthenticateWithRefreshToken = fetchRefreshedToken;

    /** Method return userprofile */
    const getUserProfile = () => userProfile;

    /** Set prepone time for the expired token
     * Tokens have expiration time (exp) which is f.e. 15min.
     * We can prepone the refresh time using this method.
     * Default is 1 minute. */
    const setTokenRefreshPreponeTime = (preponeTime) => {
        if (DEBUG) log(msg.prepone.m1 + preponeTime);
        if (!preponeTime) throw Error("preponeTime is mandatory!");
        if (typeof preponeTime !== "number") throw Error("preponeTime is not a integer");
        renewalPreponeTime = preponeTime;
    }


/*********************************************************************************************
 *** Public API
*********************************************************************************************/

    return {

        // Tokens
        getToken,

        // Authentication
        verifyUserIsAuthenticated,
        reauthenticateWithRefreshToken,
        login,
        logout,
        getUserProfile,
        setTokenRefreshPreponeTime

    };

}

export default authentication();