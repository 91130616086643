import { useContext, useState } from 'react';
import { AuthContext } from '../../Context/AuthContext';
import useRequestHandler from './../useRequestHandler';

const useListUsers = () => {

    const { acquireTokenSilently, apiClient } = useContext(AuthContext);
    const [ users, setUsers ] = useState([]);
    const { errorHandler, successHandler } = useRequestHandler();

    /** List users */
    const listUsers = async () => {
        try {

            const accessToken = await acquireTokenSilently("listUsers");
            
            if (accessToken === "login_required") throw Error("login_required");

            const result = await apiClient.list(accessToken, "users");

            const data = result.data || {};
            const users = data.users || null;
            if (users) setUsers(users);

            return successHandler(result, "useListUsers", "list", { hide: true });

        } catch (error) {
            return errorHandler(error, "useListUsers", "list");
        }
    }

    return { users, listUsers };

}

export default useListUsers;
