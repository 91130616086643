import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../Context/AuthContext';
import useRequestHandler from './../useRequestHandler';

const useConfirmRegistration = () => {

    const { t } = useTranslation();
    const { apiClient } = useContext(AuthContext);
    const { errorHandler, successHandler } = useRequestHandler();

    /** Confirm registration 
     * @param {string} guid - confirmation guid */
    const confirmRegistration = async (guid) => {
        try {

            const result = await apiClient.create(null, "registration/confirm", { "guid": guid });

            return successHandler(result, "useConfirmRegistration", "confirm", { message: t('success.companyRegistered') });

        } catch (error) {
            return errorHandler(error, "useConfirmRegistration", "confirm");
        }
    }

    return { confirmRegistration };

}

export default useConfirmRegistration;
